//DB Auto Complete

//Goals: 
//Be able to search a table by key
//Be able to specify which value we want in the rendered input
//Be able to send value back to the parent functional component

//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

import axios from "axios";

var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:12000 //12 seconds
};

const filter = createFilterOptions();


function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


const DBAutoComplete = (props) => {

	// Props:
	// database: flexitem, flexsku, etc
	// searchkey: Name, Sku, etc
	// itemindex: For use in controller to set a new value, probably no necessary for key-value pair pages that don't have a grid.
	// defaultsearchterm:""   Hopefully?
	// updatefunction: UpdateState?
	// limit: How many items should we see?
	// errors: We can give access to the errors if need be
	// allowadd: Give option to add a value


	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const [defaultsearchterm, setDefaultSearchTerm] = useState(props.defaultsearchterm);
	//Attempt to use inputValue
	const [inputValue, setInputValue] = useState(props.inputValue);

	//We can setup keys for when they are expected:
	const [searchtype, setSearchType] = useState(props.searchtype);
	const [searchkey, setSearchKey] = useState(props.searchkey);
	const [keyedby, setKeyedBy] = useState(props.keyedby);
	const [key1, setKey1] = useState(props.key1); //searchtype: components

	const [openoptions, openOptions] = React.useState(false);
	const [itemoptions, setItemOptions] = React.useState([]);
	const [loadingitemoptions, setLoadingItemOptions] = useState(false);

	const InitItemOptions = () => {
		if (itemoptions.length===0){
			ItemSearch(""); //Get basic options - perhaps our DB could provide commonly used values?
		}
		//setDefaultSearchTerm('this'); //This resets the form on open, do we want to be able to select text already in the input?
		openOptions(true);
	}

	const onCloseHandler = () => {
		setDefaultSearchTerm(props.defaultsearchterm);
		console.log("Test this prop:"+props.defaultsearchterm);
		openOptions(false);
	}

	const onChangeItemOption = (event, newvalue) =>{
		console.log("there here");
		props.onDBAutoCompleteChange(newvalue, props.searchtype, props.itemindex);

		

		//setDefaultSearchTerm(newvalue[props.searchkey]);

		// //We might want to clear the default input! Perhaps we're adding a component to something and we want to input cleared!
		if (searchtype==="components"){
			setDefaultSearchTerm("");
			setInputValue("");
		} else {
			//Set new default - this allows us to default back to the most recent saved item, not just the prop's original config
			setDefaultSearchTerm(newvalue[props.searchkey]);
			setInputValue(newvalue[props.searchkey]);
		}

	}

	const ItemSearch = debounce(function(searchterm){
		//setItemSearchTerm(searchvalue);
		//state.searchterm = searchvalue;
		setLoadingItemOptions(true);
		const postdata = {			
			searchtype:props.searchtype,		
			searchterm:searchterm,
			limit:props.limit,
			//Specialized - Values not necessarily needed for each endpoint, but we include them here:
			producttype:(props.producttype ? props.producttype : "")
		};
		axios.post(dbendpoint+"/dbautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setItemOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				if (props.hasOwnProperty('errors')){
					props.errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
				}
			}
			setLoadingItemOptions(false);
		}).catch( (error)=>{
			if (props.hasOwnProperty('errors')){
				props.errors.NewError({errmsg:"Request , possible route cache issue: "+error, errshow:true, errtimeout: 5, errtype:"warning"})
			}
			}
		);
	},600);





	

	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/

	
	/* ##########################  Render Function  ########################## */
	/* General rules: The /App allows us to use 100% of the width here. We can also allow each controller to set their own min width, ie: minWidth: "350px" below */
	return (
		<Autocomplete forcePopupIcon={false} style={{ width: "100%" }}
			clearOnBlur
			freeSolo={props.freeSolo}
			className={(props.grayplaceholder && !defaultsearchterm) ? classes.dbautocompletegray : classes.dbautocompleteblack}
			open={openoptions} onOpen={() => { InitItemOptions(); }} onClose={() => onCloseHandler()}
			//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
			onChange={(event, newValue) => onChangeItemOption(event, newValue)}
			onInputChange={(event) => ItemSearch(event.target.value)}
			isOptionEqualToValue={(option, value) => {
				console.log(option[keyedby]);
				console.log(value[keyedby]);
				if (option[keyedby] === value[keyedby]){
					return true;
				} }}
			getOptionLabel={(option) => option[props.searchkey]} //This may be the thing that is sent to isOptionEqualToValue....
			renderOption={(props, option) => {
				//Needs to be list item. We can do all of our customization here possibly:
				return (
					<li {...props} key={option.ID}>
						{(searchtype==="components") &&
							<span><b>{option.ProductType}</b> &rarr; <b>{option.ComponentType}</b> &rarr; {option.Name}</span>
						}
						{(searchtype!=="components") &&
							<span>{option[searchkey]}</span>
						}
					</li>
				);
			}}
			
			options={itemoptions}
			loading={loadingitemoptions}
			//Setting value or defaultValue kinda breaks this. Use placeholder instead based on a useState variable
			//Some AutoCompletes will clear their entry on selection. Add option to pass via props, grayplaceholder. Attempt to format the placeholder as well.
			//Otherwise, use defaultsearchterm which is usually a DB value sent from the controller.
			renderInput={(params) => (
				<div className={classes.hoverunit} ref={params.InputProps.ref} style={{borderBottom:"1px solid #EEE"}}>
					<input 
						type="text" 
						placeholder={(props.grayplaceholder && !defaultsearchterm) ? props.grayplaceholder : defaultsearchterm} 
						{...params.inputProps} 
					/>
				</div>
			)}

			placeholder="This"
			label="That"
			disabled={props.disabled}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);
				
				if (props.allowadd){
					const { inputValue } = params;
					// Suggest the creation of a new value
					const isExisting = options.some((option) => inputValue === option[props.searchkey]);
					if (inputValue !== '' && !isExisting) {
						filtered.push({
							inputValue,
							[props.searchkey]: `Add "${inputValue}"`,
							addnewitem: true, //Flag controller, we're creating a new item - possibly set PendingItem for pending db call
							newitemvalue: inputValue
						});
					}
				}
				
				return filtered;
			}}
		/>
    );
}

export default DBAutoComplete;