//Products Table

//Redux Features
//ProgressBar
import { useSelector, useDispatch } from 'react-redux';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
  } from '../../features/progressbar/progressbarSlice';
  import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext"
//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses'; 
import { useMediaQuery } from "@mui/material";

import React, { useState, useEffect, useContext, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import fetch from 'cross-fetch';


//Datetime formatting
import Moment from 'react-moment';
//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";


//Search Tools
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';

//Tables
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';


import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ViewListIcon from '@mui/icons-material/ViewList';

//Datetime Pickers
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//Export
import ExportCSV from '../common/ExportCSV';



/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;
var hostbase = process.env.REACT_APP_BASE;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};

//Helper Functions

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}


const ProductsTable = (props) => {
	document.title="Products";
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Products"));
	dispatch(setCurrentMenuItem("/products"));
	let history = useHistory();

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");


	const rowRefs = useRef([]);

	/* ##########################  UseState Variables  ########################## */ 
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection:true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems:[],		//Empty object
		defaultvalues:[],   //Default values: Useful for initial render.
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"asc",
		orderby:"Name",
		selectedcount:0,
		rowsperpage:100,
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		newcomponenttype:"",
		searchoptions:{
			//New! Key-Value pair array. Easier to itterate in API.
			searchpairs:{
				//Reserved for basic searchpairs. Possible injection of search parameter 'nameparameter' here.
				//Modes: like, left, right, strict, not
				searchpair1:{type:"Name", value: "", mode:"like", uuid:uuidv4()},
				searchpair2:{type:"Mfg", value: "", mode:"like", uuid:uuidv4()},
				searchpair3:{type:"TotalInventoryUnits >", value: "", mode:"greaterthan", uuid:uuidv4()},
				searchpair4:{type:"Cost >", value: "", mode:"greaterthan", uuid:uuidv4()},
				searchpair5:{type:"AverageAgeDays >", value: "", mode:"greaterthan", uuid:uuidv4()},
				searchpair6:{type:"", value: "", mode:"like", uuid:uuidv4()},
				//Reserved for AutoComplete searchpairs.
				searchpair7:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair8:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair9:{type:"", value: "", mode:"strict", uuid:uuidv4()},
				searchpair10:{type:"", value: "", mode:"strict", uuid:uuidv4()},
			},
			nestedrelationships:{
				
			},
			itemlist:""
		}
	});

	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}


	/* ##########################  Top Button Refs and Functions  ########################## */
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnEditSelected = useRef();
	const btnDeleteSelected = useRef();
	const btnAddProduct = useRef();
	const btnEditMode = useRef();
	
	

	//Disable Buttons
	const DisableButtons = () => {
		btnSave.current.setAttribute("disabled", true);
		btnPendingSave.current.setAttribute("disabled", true);
		btnAddProduct.current.setAttribute("disabled", true);
		btnDeleteSelected.current.setAttribute("disabled", true);
		btnEditSelected.current.setAttribute("disabled", true);
		btnEditMode.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		btnSave.current.removeAttribute("disabled");
		btnPendingSave.current.removeAttribute("disabled");
		btnAddProduct.current.removeAttribute("disabled");
		btnDeleteSelected.current.removeAttribute("disabled");
		btnEditSelected.current.removeAttribute("disabled");
		btnEditMode.current.removeAttribute("disabled");
	}




	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected
	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length===0){
			errors.NewError({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'});
		} else {
			//If all tests pass, use callback

			//Provision to disable Add Component if sku sku.product.ProductType are not alike.
			var producttype = localstate.griditems[localstate.selectedindexes[0]].ProductType;
			//Allow unless test proves otherwise
			localstate.allowcomponentupdate = true;
			//Set producttype even if next test fails
			localstate.newcomponenttype = producttype;
			if (localstate.selectedindexes.length>1){
				for (var k=1; k<localstate.selectedindexes.length; k++){
					if (localstate.griditems[localstate.selectedindexes[k]].ProductType!==producttype){
						//If even one sku has a different ProductType, don't allow component update.
						localstate.allowcomponentupdate = false;
					}
				}
			}
			
			UpdateState(localstate);
			fnCallback(value);
		}
	}

	//Edit Mode
	const [editmode, setEditMode] = useState(false);
	const EditMode = () => {
		setEditMode(!editmode);
	}


	/* ##########################  Column States - ColStates  ########################## */	 
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		created_at:false,
		Name:true,
		Nav:true,
		Mfg:false,
		Cost:true,
		ShippingCost:false,
		ProductType:false,
		ProductID:false,
		PartsLocation:true,
		Components:true,
		RunDailyStats:false,
		//Stats View? 
		TotalInventoryCost:true,
		TotalInventoryUnits:true,
		OneYrReturnRate:false,
		OneYrReturnUnits:false,
		OneYrSalesTotal:false,
		OneYrSalesUnits:false,
		AverageAgeDays:false,
		AverageCostMostRecent:false,
		AverageCostActual:true,
		PercentOfInventoryUnits:false,
		PercentOfInventoryCost:false,
		StockOutDays30:true,
		Grades:true,
		GradeAPct: false,
		GradeBPct:false,
		GradeCPct:false,
		GradeRepairPct:false,
		GradeScrapPct:false,
		stats_updated_at:false
	});

	const UpdateColState = (colstate) =>{
		setColState(colstate);
	}

	

	/* ##########################  Menus  ########################## */

	/* Column Menu */
	const [showcolumnmenu, setColumnMenu] = useState(null);

	const ShowColumnMenu = (event) => {
		setColumnMenu(event.currentTarget);
	}
	const CloseColumnMenu = () => {
		setColumnMenu(null);
	}
	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}

	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}
	const showPricingAndComponents = () =>{
		//Applies a few as set to true.
		let newcolstate = colstate;
		newcolstate['created_at'] = false;
		newcolstate['Name'] = true;
		newcolstate['Mfg'] = false;
		newcolstate['Cost'] = true;
		newcolstate['ShippingCost'] = true;
		newcolstate['ProductType'] = false;
		newcolstate['ProductID'] = false;
		newcolstate['PartsLocation'] = false;
		newcolstate['Components'] = true;
		newcolstate['TotalInventoryCost'] = false;
		newcolstate['TotalInventoryUnits'] = false;
		newcolstate['Grades'] = false;
		newcolstate['GradeAPct'] = false;
		newcolstate['GradeBPct'] = false;
		newcolstate['GradeCPct'] = false;
		newcolstate['GradeRepairPct'] = false;
		newcolstate['GradeScrapPct'] = false;
		newcolstate['OneYrSalesTotal'] = false;
		newcolstate['OneYrSalesUnits'] = false;
		newcolstate['AverageAgeDays'] = false;
		newcolstate['AverageCostMostRecent'] = false;
		newcolstate['AverageCostActual'] = false;
		newcolstate['PercentOfInventoryUnits'] = false;
		newcolstate['PercentOfInventoryCost'] = false;
		newcolstate['StockOutDays30'] = false;
		newcolstate['RunDailyStats'] = false;
		UpdateColState(newcolstate);
		CloseColumnMenu();
	}

	const showStats = () =>{
		//Applies a few as set to true.
		let newcolstate = colstate;
		newcolstate['created_at'] = false;
		newcolstate['Name'] = true;
		newcolstate['Mfg'] = false;
		newcolstate['Cost'] = false;
		newcolstate['ShippingCost'] = false;
		newcolstate['ProductType'] = false;
		newcolstate['ProductID'] = false;
		newcolstate['PartsLocation'] = false;
		newcolstate['Components'] = false;
		newcolstate['TotalInventoryCost'] = true;
		newcolstate['TotalInventoryUnits'] = true;
		newcolstate['Grades'] = true;
		newcolstate['GradeAPct'] = true;
		newcolstate['GradeBPct'] = true;
		newcolstate['GradeCPct'] = true;
		newcolstate['GradeRepairPct'] = true;
		newcolstate['GradeScrapPct'] = true;
		newcolstate['OneYrSalesTotal'] = true;
		newcolstate['OneYrSalesUnits'] = true;
		newcolstate['AverageAgeDays'] = true;
		newcolstate['AverageCostMostRecent'] = true;
		newcolstate['AverageCostActual'] = true;
		newcolstate['PercentOfInventoryUnits'] = true;
		newcolstate['PercentOfInventoryCost'] = true;
		newcolstate['StockOutDays30'] = true;
		newcolstate['RunDailyStats'] = false;
		UpdateColState(newcolstate);
		CloseColumnMenu();
	}

	const showTurnoverStats = () =>{
		//Applies a few as set to true.
		let newcolstate = colstate;
		newcolstate['created_at'] = false;
		newcolstate['Name'] = true;
		newcolstate['Mfg'] = false;
		newcolstate['Cost'] = true;
		newcolstate['ShippingCost'] = false;
		newcolstate['ProductType'] = false;
		newcolstate['ProductID'] = false;
		newcolstate['PartsLocation'] = false;
		newcolstate['Components'] = false;
		newcolstate['TotalInventoryCost'] = true;
		newcolstate['TotalInventoryUnits'] = true;
		newcolstate['Grades'] = true;
		newcolstate['GradeAPct'] = false;
		newcolstate['GradeBPct'] = false;
		newcolstate['GradeCPct'] = false;
		newcolstate['GradeRepairPct'] = false;
		newcolstate['GradeScrapPct'] = false;
		newcolstate['OneYrSalesTotal'] = false;
		newcolstate['OneYrSalesUnits'] = false;
		newcolstate['AverageAgeDays'] = true;
		newcolstate['AverageCostMostRecent'] = true;
		newcolstate['AverageCostActual'] = true;
		newcolstate['PercentOfInventoryUnits'] = false;
		newcolstate['PercentOfInventoryCost'] = false;
		newcolstate['StockOutDays30'] = true;
		newcolstate['RunDailyStats'] = false;
		UpdateColState(newcolstate);
		CloseColumnMenu();
	}

	const showNewStockStats = () =>{
		//Applies a few as set to true.
		

		let newcolstate = colstate;
		newcolstate['created_at'] = false;
		newcolstate['Name'] = true;
		newcolstate['Mfg'] = false;
		newcolstate['Cost'] = true;
		newcolstate['ShippingCost'] = false;
		newcolstate['ProductType'] = false;
		newcolstate['ProductID'] = false;
		newcolstate['PartsLocation'] = false;
		newcolstate['Components'] = false;
		newcolstate['TotalInventoryCost'] = true;
		newcolstate['TotalInventoryUnits'] = true;
		newcolstate['Grades'] = true;
		newcolstate['GradeAPct'] = false;
		newcolstate['GradeBPct'] = false;
		newcolstate['GradeCPct'] = false;
		newcolstate['GradeRepairPct'] = false;
		newcolstate['GradeScrapPct'] = false;
		newcolstate['OneYrSalesTotal'] = false;
		newcolstate['OneYrSalesUnits'] = false;
		newcolstate['AverageAgeDays'] = true;
		newcolstate['AverageCostMostRecent'] = true;
		newcolstate['AverageCostActual'] = true;
		newcolstate['PercentOfInventoryUnits'] = false;
		newcolstate['PercentOfInventoryCost'] = false;
		newcolstate['StockOutDays30'] = true;
		newcolstate['RunDailyStats'] = false;
		
		UpdateColState(newcolstate);
		CloseColumnMenu();

		localstate.searchoptions.searchpairs.searchpair3.type="TotalInventoryUnits >";
		localstate.searchoptions.searchpairs.searchpair3.mode="greaterthan";
		localstate.searchoptions.searchpairs.searchpair3.value=19;
		localstate.searchoptions.searchpairs.searchpair3.uuid = uuidv4(); //UUID required to update view

		localstate.searchoptions.searchpairs.searchpair4.type="AverageAgeDays <";
		localstate.searchoptions.searchpairs.searchpair4.mode="lessthan";
		localstate.searchoptions.searchpairs.searchpair4.value=90;
		localstate.searchoptions.searchpairs.searchpair4.uuid = uuidv4(); //UUID required to update view
		localstate.dbreload = true;
		UpdateState(localstate);
	}



	/* ##########################  Selected Rows  ########################## */
	const SelectRow = (index) => {
		//alert(index);
		if (localstate.selectedindexes.indexOf(index)===-1){
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Requires Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length){
				UpdateState(localstate);			
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex,1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length+1)){
				UpdateState(localstate);			
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length===0){
			setShowExportConfirmation(false);
		}
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i=0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length===0){
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};

	//Pre-set Key
	//Deprecate? Move to AutoComplete?
	const onChangeSearch = debounce(function(searchtype, searchvalue, itembool, searchpair) {
		//If an item is selected, then deselected, itembool is still true, but the searchvalue becomes null.
		//If item selected from list (itembool), grab it's key's value instead 'name'
		if (itembool) {
			if (searchvalue !== null){
				searchvalue = searchvalue.name;
			} else {
				searchvalue="";
			}
		}
		//New API:
		console.log(searchtype);
		console.log(searchvalue);
		switch (searchpair) {
			case "searchpair1":
				localstate.searchoptions.searchpairs.searchpair1.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair1.value = searchvalue;
				break;
			case "searchpair2":
				localstate.searchoptions.searchpairs.searchpair2.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair2.value = searchvalue;
				break;
			case "searchpair3":
				localstate.searchoptions.searchpairs.searchpair3.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair3.value = searchvalue;
				break;
			case "searchpair4":
				localstate.searchoptions.searchpairs.searchpair4.type = searchtype;
				localstate.searchoptions.searchpairs.searchpair4.value = searchvalue;
				break;
			default:
				break;
		}
		localstate.dbreload=true;
		UpdateState(localstate);
	}, 800);


	//API Build-out: 
	//	Old: Types [searchtype, sub1type, sub2type] , Search [search, sub1search, sub2search]
	//	New: Types [searchtype1, searchtype2, searchtype3], Search [search1, search2, search3]
	//Set Search Key:
	var mode="";
	const onChangeSearchType = (searchtype, searchnumber) => {
		//Blank the value:
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = "";

		//Search Mode: Each search type may have a different search mode
		//left, right, like, strict, not
		//Default Mode: LIKE
		mode = "like";
		//Provision for automatically switching search mode
		if (searchtype === "PartsLocation") {
			mode = "strict";
		}

		//Not Conditionals: Convert NotName to Name in BasicTableController
		if (searchtype === "NotName") {
			mode = "not";
		}

		//Date Searches - Simple string input and validation
		if (searchtype === "DateAfter") {
			mode = "dateafter";
		}
		if (searchtype === "DateBefore") {
			mode = "datebefore";
		}

		//DateTime Searches
		if (searchtype === "DateTimeAfter") {
			mode = "datetimeafter";
		}
		if (searchtype === "DateTimeBefore") {
			mode = "datetimebefore";
		}

		//Init date time:
		if (searchtype === "DateTimeBefore" || searchtype === "DateTimeAfter") {
			localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = new Date;
		}


		if (searchtype === "Cost >" || 
			searchtype === "TotalInventoryUnits >" || 
			searchtype === "AverageAgeDays >" ||
			searchtype === "StockOutDays30 >" ||
			searchtype === "TotalInventoryCost >"
			) {
			mode = "greaterthan";
		}
		if (searchtype === "Cost <" || 
			searchtype === "TotalInventoryUnits <" || 
			searchtype === "AverageAgeDays <" ||
			searchtype === "StockOutDays30 <" ||
			searchtype === "TotalInventoryCost <"
			) {
			mode = "lessthan";
		}

		localstate.searchoptions.searchpairs["searchpair" + searchnumber].type = searchtype;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].mode = mode;
		//Provision to add columns if selected for search.
		if (searchtype === "Name") {
			colstate["Name"] = true;
			setColumnMenu(null);
		}
		UpdateState(localstate);
	}


	const [datetimevalue, setDateTimeValue] = useState(new Date);
	//Set Search Value:
	const onChangeSearchValue = debounce(function(searchvalue, searchnumber) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		

		//Provision to warn user about a bad datetime format:
		//On blank datetime, proceed to load data

		if ((localstate.searchoptions.searchpairs["searchpair"+searchnumber].type==="DateAfter" || localstate.searchoptions.searchpairs["searchpair"+searchnumber].type==="DateBefore") && searchvalue!==""){
			var count = (searchvalue.match(/-/g) || []).length;
			if (searchvalue.length===10 && count===2){
				let isValidDate = Date.parse(searchvalue);
				if (isNaN(isValidDate)) {
					errors.NewError({ errmsg: "Invalid date.", errshow: true, errtimeout: 5, errtype: "warning" });
				} else {
					localstate.page=0;
					localstate.dbreload = true;
					UpdateState(localstate);
				}
			} else {
				errors.NewError({ errmsg: "Use YYYY-MM-DD format for dates.", errshow: true, errtimeout: 5, errtype: "ok" });
			}
		} 
		
		localstate.searchoptions.searchpairs["searchpair"+searchnumber].value = searchvalue;
		localstate.page=0;
		localstate.dbreload = true;
		UpdateState(localstate);
	},1200);

	//Key-Value Inputs
	const [searchinputs, setSearchInputs] = useState({
		show1:true,
		show2:true,
		show3:true,
		show4:true,
		show5:false,
		show6:false,
		lastsearch:4
	});


	const AddSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch+1)]=true;
		newsearchinputs.lastsearch++;
		setSearchInputs(newsearchinputs);
	}

	const RemoveSearch = () => {
		let newsearchinputs = Object.assign({}, searchinputs);
		newsearchinputs["show"+(newsearchinputs.lastsearch)]=false;
		newsearchinputs.lastsearch--;
		setSearchInputs(newsearchinputs);
	}

	/* ##########################  Auto Complete  ########################## */
	// Similar to regular searchpairs, but loads results from DB - needs stateful.

	//Helpers - Blank icons for pre-set keys
	const BlankIcon = () => (<ArrowDropDownIcon style={{display:"none"}} />);

	const [autocompletesearchtype1, setKey1] = useState("Name");
	const [openoptions1, openOptions1] = React.useState(false);
	const [options1, setOptions1] = React.useState([]);
	const [loadingoptions1, setLoadingOptions1] = useState(false);
	const InitOptions1 = () => {
		if (options1.length===0){
			//Load (up to limit) options
			AutoCompleteSearch1("");
		}
		openOptions1(true);
	}
  
	const onChangeOption1 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair7.type=autocompletesearchtype1;
		localstate.searchoptions.searchpairs.searchpair7.value=newvalue[autocompletesearchtype1];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch1 = debounce(function(searchvalue){
		setLoadingOptions1(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype1,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions1(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions1(false);
		});
	},600);


	const [autocompletesearchtype2, setKey2] = useState("Name");
	const [openoptions2, openOptions2] = React.useState(false);
	const [options2, setOptions2] = React.useState([]);
	const [loadingoptions2, setLoadingOptions2] = useState(false);
	const InitOptions2 = () => {
		if (options2.length===0){
			//Load (up to limit) options
			AutoCompleteSearch2("");
		}
		openOptions2(true);
	}

	const onChangeOption2 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair8.type=autocompletesearchtype2;
		localstate.searchoptions.searchpairs.searchpair8.value=newvalue[autocompletesearchtype2];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch2 = debounce(function(searchvalue){
		setLoadingOptions2(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype2,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions2(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions2(false);
		});
	},600);


	const [autocompletesearchtype3, setKey3] = useState("Name");
	const [openoptions3, openOptions3] = React.useState(false);
	const [options3, setOptions3] = React.useState([]);
	const [loadingoptions3, setLoadingOptions3] = useState(false);
	const InitOptions3 = () => {
		if (options3.length===0){
			//Load (up to limit) options
			AutoCompleteSearch3("");
		}
		openOptions3(true);
	}

	const onChangeOption3 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair9.type=autocompletesearchtype3;
		localstate.searchoptions.searchpairs.searchpair9.value=newvalue[autocompletesearchtype3];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch3 = debounce(function(searchvalue){
		setLoadingOptions3(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype3,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions3(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions3(false);
		});
	},600);


	const [autocompletesearchtype4, setKey4] = useState("Name");
	const [openoptions4, openOptions4] = React.useState(false);
	const [options4, setOptions4] = React.useState([]);
	const [loadingoptions4, setLoadingOptions4] = useState(false);
	const InitOptions4 = () => {
		if (options4.length===0){
			//Load (up to limit) options
			AutoCompleteSearch4("");
		}
		openOptions4(true);
	}

	const onChangeOption4 = (event, newvalue) =>{
		localstate.searchoptions.searchpairs.searchpair10.type=autocompletesearchtype4;
		localstate.searchoptions.searchpairs.searchpair10.value=newvalue[autocompletesearchtype4];
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	const AutoCompleteSearch4 = debounce(function(searchvalue){
		setLoadingOptions4(true);
		console.log(searchvalue);
		const postdata = {					
			key:autocompletesearchtype4,
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions4(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions4(false);
		});
	},600);


	/* ##########################  Auto Complete - Preset Keys, No usage of searchpairs from here on out. ########################## */
	const [openoptions5, openOptions5] = React.useState(false);
	const [options5, setOptions5] = React.useState([]);
	const [loadingoptions5, setLoadingOptions5] = useState(false);
	const InitOptions5 = () => {
		if (options5.length===0){
			//Load (up to limit) options
			AutoCompleteSearch5("");
		}
		openOptions5(true);
	}
	const onChangeOption5 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch5 = debounce(function(searchvalue){
		setLoadingOptions5(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions5(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingOptions5(false);
		});
	},600);


	const [openoptions6, openOptions6] = React.useState(false);
	const [options6, setOptions6] = React.useState([]);
	const [loadingoptions6, setLoadingOptions6] = useState(false);
	const InitOptions6 = () => {
		if (options6.length===0){
			//Load (up to limit) options
			AutoCompleteSearch6("");
		}
		openOptions6(true);
	}
	const onChangeOption6 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch6 = debounce(function(searchvalue){
		setLoadingOptions6(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions6(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 6, errtype:"warning"})
			}
			setLoadingOptions6(false);
		});
	},600);


	const [openoptions7, openOptions7] = React.useState(false);
	const [options7, setOptions7] = React.useState([]);
	const [loadingoptions7, setLoadingOptions7] = useState(false);
	const InitOptions7 = () => {
		if (options7.length===0){
			//Load (up to limit) options
			AutoCompleteSearch7("");
		}
		openOptions7(true);
	}
	const onChangeOption7 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch7 = debounce(function(searchvalue){
		setLoadingOptions7(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions7(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 7, errtype:"warning"})
			}
			setLoadingOptions7(false);
		});
	},600);


	const [openoptions8, openOptions8] = React.useState(false);
	const [options8, setOptions8] = React.useState([]);
	const [loadingoptions8, setLoadingOptions8] = useState(false);
	const InitOptions8 = () => {
		if (options8.length===0){
			//Load (up to limit) options
			AutoCompleteSearch8("");
		}
		openOptions8(true);
	}
	const onChangeOption8 = (event, newvalue) =>{
		//Do needful app work here, axios calls, etc
		
	}
	const AutoCompleteSearch8 = debounce(function(searchvalue){
		setLoadingOptions8(true);
		const postdata = {					
			key:"Name",
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setOptions8(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 8, errtype:"warning"})
			}
			setLoadingOptions8(false);
		});
	},600);



	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";		
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){  
		if (localstate.clearselection){
			localstate.selectedindexes = [];
			localstate.selectedcount=0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection=true;
		}
		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:localstate.searchoptions.searchpairs,
				nestedrelationships:localstate.searchoptions.nestedrelationships
			}
		};

		axios.post(dbendpoint+"/products/get?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;
					for (var i =0; i<resultdata.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey=i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)){
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						// Sort priority order by ComponentType
						// Any new additions to this priority, check SkuIntegrationsTable, SkusTable, OrdersView, ProductsTable....
						const priorityTypes = ['CPU', 'RAM', 'Hard Drive', 'Optical', 'Resolution', 'Webcam', 'COA', 'Graphics', 'Wifi', 'Grade', 'Body Grade', 'LCD Grade'];
						if (typeof resultdata[i].productcomponents !== undefined){
							resultdata[i].productcomponents.sort((a, b) => { 
								// Determine priority of ComponentType
								const aPriority = priorityTypes.indexOf(a.ComponentType);
								const bPriority = priorityTypes.indexOf(b.ComponentType);
	
								// Prioritize items based on the defined priorityTypes
								if (aPriority !== -1 && bPriority === -1) return -1; // a is in priorityTypes, b is not
								if (bPriority !== -1 && aPriority === -1) return 1;  // b is in priorityTypes, a is not
								if (aPriority !== -1 && bPriority !== -1) return aPriority - bPriority; // Both in priorityTypes, sort by priority
	
								// If neither is in priorityTypes, sort by ComponentType alphabetically
								if (a.ComponentType < b.ComponentType) return -1;
								if (a.ComponentType > b.ComponentType) return 1;
	
								// If ComponentType is the same, sort alphabetically by Name
								if (a.Name < b.Name) return -1;
								if (a.Name > b.Name) return 1;
	
								return 0;
							});
						}

						
					}
					localstate.griditems = resultdata;
					//Hold items for default values
					localstate.defaultvalues = Object.assign({}, resultdata);
					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					localstate.totalitems = 0;
					UpdateState(localstate);
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
		});
	}


	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;
	
		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};
	
		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};
	
		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		const cols = thead.querySelectorAll('td');
		// Loop over them
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement('div');
			resizer.classList.add([classes.resizer]);
			// Set the height
			resizer.style.height = `${table.offsetHeight-2}px`;
			// Add a resizer element to the column
			col.appendChild(resizer);
			createResizableColumn(col, resizer);
		});
	}

	/* ############### END OF RESIZER ####################*/


	useEffect(() => {
		document.title="Products";
		if (state.dbreload){
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			InitColumnResizers();
		}
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	},);


	/* ##########################  CRUD  ########################## */

	//New Row adds property 'PendingItem' for use in the API to add such rows.
	//UNUSED. We'll setup new products outside of this view.
	/*
	const AddRow = () => {
		localstate.griditems.unshift({
			PendingItem:true,
			ID:uuidv4(),
			Name:"",
			Mfg:"",
			Cost:0,
			ShippingCost:0,
			ProductType: "", //We need to setup 
		});
		//All selected indexes move up by 1.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			localstate.selectedindexes[i] += 1;
		}
		UpdateState(localstate);
	}
	*/

	//Add Product
	//Method creates a new product in the DB and returns the ID so we can then load it in item view
	const AddNewProduct = () => {
		axios.get(dbendpoint + "/products/addnewproduct", defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {
					window.open('/product/'+res.data.ProductID);
				}
				if (res.data.Status === 'Failure') {
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
			}
		});
	}


	//A Ref stays consistent throughout async function
	const requestcount = useRef(0);

	const SaveChanges = () => {
		//Clean up current errors:
		errors.HideError(errors);
		
		//Configuration
		requestcount.current = 0;
		var timeout = 4000; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
		var requestinterval = 500; //Milliseconds

		var updatearray = [];
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				updatearray.push(localstate.griditems[i]);
			}
		}

		if (updatearray.length>0){
			DisableButtons();
			dispatch(newProgress({
				msg: 'Saving products...',
				show: true,
				settimeout:true, //This option allows for a timeout after the final item is pass/failed
				timeout: timeout, //Initiated on final item 
				type: 'items', //Labels your save types such as items, products, skus, parts
				finished:0,
				percent:"0%",
				total: updatearray.length,
				pass: 0,
				fail: 0,
				faillist:[], //Can take simple values,
				faillinks:[], //Can take links to things like items or products.
				timeoutid:false,
				errors:[] //Can take strings
			}));
			i=0;
			var limit = updatearray.length-1;
			/* INTERVAL */
			var updateitems = setInterval(function(){
				var item = updatearray[i];
				//Do work here, API call.
				const postdata = {					
					item:item
				};
				axios.post(dbendpoint+"/products/update", postdata, defaultpostoptions).then(res => {
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back 'login' error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we sent new rows, we'll need to reference the old ID.
							var itemindex=0;
							if (res.data.OldID){
								itemindex = localstate.griditems.map(function(o) { return o.ProductID; }).indexOf(res.data.OldID);
								localstate.griditems[itemindex].unsaved = false;
								rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.errorhighlight);
								//Set New ID
								localstate.griditems[itemindex].ProductID = res.data.item.ProductID;
							} else {
								itemindex = localstate.griditems.map(function(o) { return o.ProductID; }).indexOf(res.data.item.ProductID);
								localstate.griditems[itemindex].unsaved = false;
								//Refs allow us to update the grid live!
								rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.unsavedhighlight);
								rowRefs.current[itemindex+'SaveStatus'].classList.remove(classes.errorhighlight);
							}
							dispatch(incrementPass());
						}
						if (res.data.Status==="Failure"){
							//Failure error
							dispatch(incrementFail());
						}
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
					} else {
						//Non-200 message from server.
						requestcount.current++;
						FinalizeRequest(requestcount.current, limit, timeout);
						dispatch(incrementFail());
					}
				}).catch( err=> {
					//Non-200, 500 Error, timeout?
					requestcount.current++;
					FinalizeRequest(requestcount.current, limit, timeout);
					dispatch(incrementFail());
				});
				//If we have completed all items, clear this interval and update state.
				if (i===limit){
					clearInterval(updateitems);
				}
				i++;
			}, requestinterval);
		} else {
			errors.NewError({errmsg:"Nothing to save.", errshow:true, errtimeout: 5, errtype:"neutral"})
			ResetPendingSaves();
		}
	}


	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i-1)===limit){
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display="";
			btnPendingSave.current.style.display="none";
		}
	} 


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display="";
		btnPendingSave.current.style.display="none";
	}




	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */}
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () =>{
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');



	//Try to replace all this?
	//In render: {(changetype==='text')}, etc. NO. We need changetype for when we go back to the DB for the update.


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [changeisbool, setChangeIsBool] = React.useState(false);


	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);

	const [changeisaddcomponent, setChangeIsAddComponent] = React.useState(false);
	const [changeisremovecomponent, setChangeIsRemoveComponent] = React.useState(false);
	const [componentselection, setComponentSelection] = React.useState(false);
	



	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current="";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);

		//Examples
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);

		setChangeIsAddComponent(false);
		setChangeIsRemoveComponent(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (event.target.value==="Add Component"){
			setChangeIsAddComponent(true);
		}

		if (event.target.value==="Remove Component"){
			setChangeIsRemoveComponent(true);
		}

		if (
			//Text values here. Currently Unused
			event.target.value==="Name"
		) {
			setChangeIsText(true);
		}

		if (event.target.value==="Cost" || event.target.value==="ShippingCost"){
			setChangeIsFloat(true);
		}

		if (event.target.value==="RunDailyStats"){
			setChangeIsBool(true);
		}

	};

	const handleChangeBulkValue = (event) => {
		console.log(event);
		//Currency
		if (changetype==="Cost" || changetype==="ShippingCost"){
			var newvalue = RestrictInputNumber(event.target.value, changevalue.current, event, "float");
			console.log(newvalue);
			if (newvalue){
				changevalue.current = newvalue;
				changevaluefloat.current.value = newvalue;
			} else {
				if (event.key === "Backspace") {
					//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value by ignoring the ref.
					if ((changevalue.current.indexOf(".") > -1)) {
						changevalue.current = parseFloat(event.target.value).toFixed(2);
					}
				}
			}
		//Integers	
		} else if (changetype==="LotID" || changetype==="SkidNumber") {
			changevalue.current = RestrictInputNumber(event.target.value, changevalue.current, event, "positiveinteger");
			//Updates view:
			changevalueint.current = changevalue.current;
		
		} else if (changetype==="NewCheckbox"){
			// placeholder
			changevalue.current = event.target.value;
		} else {
			//Raw Values
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype==="Location" && event.target.value===""){
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			errors.NewError({errmsg:"Value cannot be blank.", errshow:true, errtimeout: 3, errtype:"neutral"});

		//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}


	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeComponent = (event, component) => {
		setComponentSelection(component);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(['Mui-disabled']);
		btnApplyBulkEdit.current.classList.add(['MuiButton-containedPrimary']);
	}


	//Search for DB Item (autocomplete) - Attempt to make this reusable! Setup endpoints as (env db dbendpoint/table/getautocomplete), see also ApplyBulkEdit
	const [dbitemkey, setDBItemKey] = useState("Name");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions.length===0){
			//Load (up to limit) options
			DBItemSearch("");
		}
		openDBItemOptions(true);
	}

	const DBItemSearch = debounce(function(searchvalue){
		setLoadingDBItemOptions(true);
		const postdata = {					
			key:dbitemkey,
			search:searchvalue,
			limit:20
		};
		//Provisions to reuse this search:
		var table = "";
		/*
		if (changetype==="Product"){
			table="products";
		}
		*/
		if (changetype==="Add Component" || changetype==="Remove Component"){
			table="components";
			postdata.producttype=localstate.newcomponenttype;
		}

		axios.post(dbendpoint+"/"+table+"/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					//console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingDBItemOptions(false);
		});
	},600);


	const ApplyBulkEdit = () => {
		var items = [];
		for (var i=0; i<localstate.selectedindexes.length; i++){
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype==="Name" ||
			changetype==="ShippingCost" ||
			changetype==="Cost" ||
			changetype==="SomeSelectable" ||
			changetype==="Date" ||
			changetype==="RunDailyStats"
		){
			for (i=0; i<items.length; i++){
				items[i][changetype] = changevalue.current;
			}
		}

		//Create fake key for AddComponent - Bulk edit has method to update these.
		if (changetype==="Add Component"){
			//Place your specific column values here:
			for (i=0; i<items.length; i++){
				items[i]["AddComponentID"] = componentselection.ID;
			}
		}

		//Create fake key for RemoveComponent - Bulk edit has method to update these.
		if (changetype==="Remove Component"){
			//Place your specific column values here:
			for (i=0; i<items.length; i++){
				items[i]["RemoveComponentID"] = componentselection.ID;
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype==="DBItem"){
			//Place your specific column values here:
			for (i=0; i<items.length; i++){
				items[i]["Name"] = dbitemselection.Name;
				//Example: items[i]["ProductID"] = dbitemselection.ProductID;
			}
		}

		//Reassign other item values if needed before pushing them to postdata.items.
		//Example provision to change Margin if price or cost change
		if (changetype==="Price"){
			for (i=0; i<items.length; i++){
				//items[i].Price = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype==="Cost"){
			for (i=0; i<items.length; i++){
				//items[i].Cost = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		var postdata={
			items:items
		}

		

		axios.post(dbendpoint+"/products/bulkeditproducts", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status==='Success'){
					//console.log(res.data);
					if (res.data['SuccessCount']>0){
						errors.NewError({
							errmsg:"Items Changed: "+res.data['SuccessCount']+", Failures: "+res.data['FailCount'],
							errshow:true,
							errtimeout: 5,
							errtype:'ok'})
						}
					}
					
				if (res.data.Status==='Failure'){
					//Failure error
					errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'})
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:'warning'})
			}
		});
	}

		//Add Component
		const AddComponent = (index) => {
			//Deselect all, select only current row.
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (var i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			localstate.selectedindexes.push(index);
			localstate.addcomponentmode = true;
			setChangeIsAddComponent(true);
			setChangeType("Add Component");
			UpdateState(localstate);
			//Checks for unsave items, mismatch producttypes, and sets producttype to allow or disallow component changes.
			RejectIfInvalidSelected(true, setShowBulkEditDrawer);
		}
	
	
		//Remove Component
		const RemoveComponent = (rowindex, id) =>{
			axios.post(dbendpoint+"/products/removeproductcomponent", {id:id}, defaultpostoptions).then(res => {
				if (res.status===200){
					//If ValidateUser() fails to verify user, it sends back 'login' error. 
					if (res.data.Status==='login'){
						//Not logged in. Reload page causes redirect to /login
						window.location.reload(false);
					}
					if (res.data.Status==='Success'){
						errors.NewError({
							errmsg:"Component removed!",
							errshow:true,
							errtimeout: 5,
							errtype:'ok'})
						}
						//To Do: Remove Component from view:
						var componentindex = localstate.griditems[rowindex].productcomponents.map(function(o) { return o.ProductID; }).indexOf(id);
						localstate.griditems[rowindex].productcomponents.splice(componentindex, 1);
						UpdateState(localstate);
					if (res.data.Status==='Failure'){
						//Failure error
						errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 10, errtype:'neutral'})
					}
				} else {
					//Non-200 message from server.
					errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 10, errtype:'warning'})
				}
			});
		}
	







	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<state.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[state.selectedindexes[i]]);
		}
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/products/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.ProductID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
								//Remove selected index as well?
							} else {
								errors.NewError({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:"warning"})
							}
						}
						if (res.data.Status==="Failure"){
							//Failure error
							errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"});
							//Need to clear selections as any removed grid items will mess with selectedindexes.
							//Setting selected indexes to a blank array will stall the upper for-loop
							localstate.selectedindexes = [];
							localstate.selectedcount = 0;
							for (var j=0; j<localstate.griditems.length; j++){
								localstate.griditems[j].isSelected = false;
								localstate.griditems[j].GridKey++;
							}
							UpdateState(localstate);
						}
					} else {
						//Non-200 message from server.
						errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}



	/* ##########################  Cell Interaction  ########################## */


	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = [
		"Name",
		"Mfg",
		"Cost",
		"ShippingCost",
		"PartsLocation"
	];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown"){
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp"){
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length-1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
	}


	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value===""){
			rowRefs.current[index+column].value="0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Catch-All Method.
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		console.log("Event value:"+event.target.value);
		console.log("Raw:"+newvalue);
		if (event.key !== "Tab" && 
			event.key!=="ArrowDown" && 
			event.key!=="ArrowUp" && 
			event.key!=="ArrowLeft" && 
			event.key!=="ArrowRight" && 
			event.key!=="ShiftLeft" && 
			event.key!=="ShiftRight"
			){
			//Conditionals for Types:
			if (column==="Cost" || column==="ShippingCost"){
				if (!oldvalue){
					console.log("No old value.");
					oldvalue="0";
				}
				//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
				if (newvalue) {
					rowRefs.current[index+column].value = newvalue;
					localstate.griditems[index][column] = newvalue;
					//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
					const inputElement = rowRefs.current[index+column];
					inputElement.type = 'text';
					inputElement.focus();
					inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
					inputElement.type = 'number';
				} else {
					console.log("No newvalue returned, set value in localstate, but leave input untouched.");
					console.log(parseFloat(event.target.value).toFixed(2));
					localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
				}
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column === "SomeBoolean") {
				if (event.target.checked) {
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}

			}
			if (column === "RunDailyStats") { //MaterialUI Checkbox
				if (event.target.checked) {
					localstate.griditems[index][column] = 1;
				} else {
					localstate.griditems[index][column] = 0;
				}
			}
			//All others
			if (column === "SomeSelectable") {
				localstate.griditems[index][column] = newvalue;
			}

			rowRefs.current[index + "SaveStatus"].classList.add(classes.unsavedhighlight);
			localstate.griditems[index].unsaved = true;
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
		}
	}



	/* ##########################  Button Functions  ########################## */

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		UpdateState(localstate);
	}

	const ResetSearches = () => {
		//Reset all:
		for (var i=1; i<7; i++){
			localstate.searchoptions.searchpairs["searchpair"+i].type = "";
			localstate.searchoptions.searchpairs["searchpair"+i].value = "";
			localstate.searchoptions.searchpairs["searchpair"+i].mode = "like";
			localstate.searchoptions.searchpairs["searchpair"+i].uuid = uuidv4();
		}
		//Set Defaults:
		localstate.searchoptions.searchpairs.searchpair1.type = "Name";
		localstate.dbreload = true;
		UpdateState(localstate);
	}

	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "ProductsCSV";
	
	const InitExport = (exporttype) => {
		if (exporttype==="selected" && localstate.selectedindexes.length===0){
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype==="selected"){
				setExportMessage("");
			}
			if (exporttype==="searchresults"){
				setExportMessage("Exports of search results will be limited to "+exportlimit+" rows.");
			}
		}
	}

	const PrepareExport = (mode) =>{
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata=[];
		if (exporttype==="selected"){
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i=0; i<localstate.selectedindexes.length; i++){
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype==="searchresults"){
			const postdata = {
				searchoptions:{
					//Set Max Limit Here
					limit:exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs:localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint+"/products?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
				if (res.status===200){
					if (res.data.Status==="login"){
						window.location.reload(false);
					}
					if (res.data.Status==="Success"){
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status==="Failure"){
						errors.NewError({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"})
					}
				} else {
					errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i=0; i<exportdata.length; i++){
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode==="simple"){
			for (var i=0; i<exportdata.length; i++){
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode==="expanded"){
			for (var i=0; i<exportdata.length; i++){
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode==="exhaustive"){
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}


	/* ########################## Lower Screen Demo Data/Functions ###### */


	//Basic Select
	const [basicselectvalue, setBasicSelectValue] = React.useState('');

	const handleChange = (event) => {
		setBasicSelectValue(event.target.value);
	};


	//Autocomplete Simple
	//Example: Products
	const [openproductoptions, openProductOptions] = React.useState(false);
	const [productoptions, setProductOptions] = React.useState([]);
	const [loadingproductoptions, setLoadingProductOptions] = useState(false);
	const [productsearchterm, setProductSearchTerm] = useState("Default Value");
	//For loading single items that do have a value, use LoadItem function in conjunction with: setProductSearchTerm(res.data.item.product.Name);
	const InitProductOptions = () => {
		if (productoptions.length===0){
			ProductSearch("");
		}
		openProductOptions(true);
	}

	const onChangeProductOption = (event, newvalue) =>{
		//Set value for current search
			// Example:
			// localstate.searchoptions.searchpairs.searchpairX.type=autocompletesearchtypeX;
			// localstate.searchoptions.searchpairs.searchpairX.value=newvalue[autocompletesearchtypeX];
			// localstate.dbreload = true;
			// UpdateState(localstate);

		//Or set value for your item:
			//localstate.itemdata['ProductID'] = newvalue['ProductID'];
			// localstate.pendingsaves = true;
			// btnSave.current.disabled = false;
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";

		//Or just yell at the user, the ProductID they just set
			//alert(newvalue['ProductID']);

		
			
	}

	const ProductSearch = debounce(function(searchvalue){
		setProductSearchTerm(searchvalue);
		setLoadingProductOptions(true);
		const postdata = {					
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/items/searchproducts", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setProductOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingProductOptions(false);
		});
	},600);

	const [basicselectdbvalue, setBasicSelectDBValue] = useState("Default basic select value");

	const GoToItem = (id) => {
		history.push('/product/'+id)
	}

	const GoToProductTypes = () => {
		history.push('/producttypes');
	}

	

	/* ##########################  TABLE COLUMN HEADERS  ########################## */
	/* ##########################  Column Configuration  ########################## */
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "created_at", numeric: false, label: "Created", align:"left", allowsort:true, style:{}},
		{ id: "Name", numeric: false, label: "Name", align:"left", allowsort:true, style:{}},
		{ id: "Nav", numeric: false, label: "Nav", align:"center", allowsort:false, style:{width:"30px"}},
		{ id: "Mfg", numeric: false, label: "Mfg", align:"left", allowsort:true, style:{}},
		{ id: "Cost", numeric: true, label: "Cost", align:"right", allowsort:true, style:{}},
		{ id: "ShippingCost", numeric: true, label: "Shipping Cost", align:"right", allowsort:true, style:{}},
		{ id: "ProductType", numeric: false, label: "Type", align:"left", allowsort:true, style:{}},
		{ id: "ProductID", numeric: true, label: "ID", align:"center", allowsort:true, style:{}},
		{ id: "PartsLocation", numeric: false, label: "Parts Location", align:"left", allowsort:true, style:{}},
		{ id: "Components", numeric:false, label: "Components", align:"left", allowsort:false, style:{}},
		{ id: "RunDailyStats", numeric: false, label: "Run Stats", align:"center", allowsort:true, style:{}},
		{ id: "TotalInventoryCost", numeric: true, label: "T-Cost", align:"right", allowsort:true, style:{}},
		{ id: "TotalInventoryUnits", numeric: true, label: "T-Units", align:"right", allowsort:true, style:{}},
		{ id: "OneYrReturnRate", numeric: true, label: "Return %", align:"right", allowsort:true, style:{}},
		{ id: "OneYrReturnUnits", numeric: true, label: "Return Units", align:"right", allowsort:true, style:{}},
		{ id: "OneYrSalesTotal", numeric: true, label: "Sales Total", align:"right", allowsort:true, style:{}},
		{ id: "OneYrSalesUnits", numeric: true, label: "Sales Units", align:"right", allowsort:true, style:{}},
		{ id: "AverageAgeDays", numeric: true, label: "Age Avg", align:"right", allowsort:true, style:{}},
		{ id: "AverageCostMostRecent", numeric: true, label: "Avg Cost Recent", align:"right", allowsort:true, style:{}},
		{ id: "AverageCostActual", numeric: true, label: "Avg Cost Actual", align:"right", allowsort:true, style:{}},
		{ id: "PercentOfInventoryUnits", numeric: true, label: "Pct. Inv Units", align:"right", allowsort:true, style:{}},
		{ id: "PercentOfInventoryCost", numeric: true, label: "Pct. Inv Cost", align:"right", allowsort:true, style:{}},
		{ id: "StockOutDays30", numeric: false, label: "StockOutDays", align:"right", allowsort:true, style:{}},
		{ id: "Grades", numeric: false, label: "Grades", align:"center", allowsort:false, style:{}},
		{ id: "GradeAPct", numeric: false, label: "A %", align:"center", allowsort:true, style:{}},
		{ id: "GradeBPct", numeric: false, label: "B %", align:"center", allowsort:true, style:{}},
		{ id: "GradeCPct", numeric: false, label: "C %", align:"center", allowsort:true, style:{}},
		{ id: "GradeRepairPct", numeric: false, label: "Repair %", align:"center", allowsort:true, style:{}},
		{ id: "GradeScrapPct", numeric: false, label: "Scrap %", align:"center", allowsort:true, style:{}},
		{ id: "stats_updated_at", numeric: false, label: "Stats Update Date", align:"right", allowsort:true, style:{}},
		
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:"1px solid #CCC",
							backgroundColor:"#DDD"}}>
					<td style={{width: "14px", padding:"none", display:"table-cell", padding:"2px 4px 2px 5px"}}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] && (
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Name") &&
											<div style={{ width: "30px", display: "inline-block" }}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						)
					)}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/

	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<div style={{padding:"8px", minWidth:"400px"}}>

				{/* Bulk Edit Drawer */}
				<Drawer open={showbulkeditdrawer} style={{ width: "600px" }}>
					<div style={{height:"47px"}}></div>
					<Typography variant="h4" gutterBottom align="center">
						Edit Product{(localstate.selectedindexes.length>1) ? "s" : ""}
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							Changes apply to all checkbox selected items in the list view. You may perform multiple changes before returning to the list. Select change type to get started.<br></br>
						</Typography>

						{/* You may want access to errors here - create parent container to hold position for you. */}
						<div style={{height:"25px"}}>
							{(errors.currenterror.errshow) &&
								<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
									<ErrorMessage />
								</div>
							}
						</div>
						

						<FormControl className={classes.formControl} style={{ width: "300px" }} variant="standard">
							<InputLabel id="Change type">Change Type</InputLabel>
							<Select
								labelId="change-type"
								id="change-type"
								value={changetype}
								onChange={handleChangeEditType}
							>
								{(localstate.allowcomponentupdate) &&
									<MenuItem value={'Add Component'}>Add Component</MenuItem>
								}
								{(localstate.allowcomponentupdate) &&
									<MenuItem value={'Remove Component'}>Remove Component</MenuItem>
								}
								<MenuItem value={'Cost'}>Cost</MenuItem>
								<MenuItem value={'ShippingCost'}>Shipping Cost</MenuItem>
								<MenuItem value={'RunDailyStats'}>Run Daily Stats</MenuItem>
							</Select>
						</FormControl>

						{/* To do: Set change values. Most are just text, so we'll start with that: */}
						{(changeistext) &&
							<div>
							<TextField variant="standard" style={{ width: "300px" }} required id="standard-required" defaultValue="" onChange={handleChangeBulkValue} />
							</div>
						}

						{(changeisstatus) &&
							<FormControl className={classes.formControl} style={{width:"300px"}} variant="standard">
							<InputLabel id="demo-simple-select-label">Select Type</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								onChange={handleChangeBulkValue}
							>
								<MenuItem value={"Checked In"}>Checked In</MenuItem>
								<MenuItem value={"Sold"}>Sold</MenuItem>
								<MenuItem value={"Scrap"}>Scrap</MenuItem>
								<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
								<MenuItem value={"Undetermined or Missing"}>Undetermined or Missing</MenuItem>
							</Select>
							</FormControl>
						}

						{/* Float: Cost, ShippingCost*/}
						{/* Special note: onKeyDown won't have new value. onChange won't send full event with key. onKeyDown may be the only thing */}
						{(changeisfloat) &&
							<div>
								<TextField variant="standard" type="number" step="0.01"  
								inputRef={el => changevaluefloat.current = el} 
								style={{ width: "300px" }} required id="standard-required" 
								defaultValue="Value" onKeyUp={(event)=>{handleChangeBulkValue(event)}} />
							</div>
						}

						{/* Add\Remove Component (Autocomplete) */}
						{(changeisaddcomponent || changeisremovecomponent) &&
							<div>
								<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding:"0px" }}
									open={opendbitemoptions} onOpen={() => { InitDBItemOptions (); }} onClose={() => { openDBItemOptions(false); }}
									//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
									//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
									onChange={(event, newValue) => handleChangeComponent (event, newValue)}
									onInputChange={(event) => DBItemSearch(event.target.value)}
									isOptionEqualToValue={(option, value) => option[dbitemkey] === value}
									getOptionLabel={(option) => option[dbitemkey]}
									options={dbitemoptions}
									loading={loadingdbitemoptions}
									renderOption={(props, option) => {
										return (
										<li {...props} key={option.ID} style={{width:"300px"}}>
											<span style={{display:"inline-block"}}><b>{option.ComponentType}</b> &rarr; {option.Name}</span>
										</li>
										);
									}}
									renderInput={(params) => (
										<TextField variant="standard"
											className={classes.autocompleteinput}
											style={{padding:"0px"}}
											{...params}
											InputProps={{
												disableUnderline: false,
												...params.InputProps,
												autoComplete: "new-password",
												endAdornment: (
													<React.Fragment>
														{loadingdbitemoptions ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</div>
						}

						{/* EXAMPLE: Integer: LotID or SkidNumber */}
						{(changeisint) &&
							<div>
								<TextField variant="standard" type="number" step="1"  
								inputRef={el => changevalue.current = el}
								style={{ width: "300px" }}
								required 
								id="standard-required"
								defaultValue=""
								onChange={handleChangeBulkValue} />
							</div>
						}


						{/* Change is Some Selectable SomeSelectable */}
						{(changeissomeselectable) &&
							<div>
								<FormControl variant="standard" className={classes.formControl}>
									{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
									{/* Need: Set default value if row.SomeSelectable is null. */}
									{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

									{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
									{/* Override select prop class with new padding specs */}

									<Select
										defaultValue={""}
										onChange={(event) => handleChangeSomeSelectable(event)}
										classes={{
											//icon: classes.nodisplay,
											//select: classes.selectpadding
										}}
										style={{ width: "300px" }}
									>
										<MenuItem value="">
											<em>None (blank string)</em>
										</MenuItem>
										<MenuItem value={10}>Ten (integer)</MenuItem>
										<MenuItem value={"A"}>
											<Chip size="small" label="A" clickable className={classes.gradea} />
										</MenuItem>
										<MenuItem value={"B"}>
											<Chip size="small" label="B" clickable className={classes.gradeb} />
										</MenuItem>
										<MenuItem value={"C"}>
											<Chip size="small" label="C" clickable className={classes.gradec} />
										</MenuItem>
										<MenuItem value={"Bad"}>
											<Chip size="small" label="Bad" clickable className={classes.gradebad} />
										</MenuItem>
										<MenuItem value={"Repair"}>
											<Chip size="small" label="Repair" clickable className={classes.graderepair} />
										</MenuItem>
										<MenuItem value={"Scrap"}>
											<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						}


						{/* DB Item (Autocomplete) */}
						{(changeisdbitem) &&
							<div>
								<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding:"0px" }}
									open={opendbitemoptions} onOpen={() => { InitDBItemOptions (); }} onClose={() => { openDBItemOptions(false); }}
									//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
									//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
									onChange={(event, newValue) => handleChangeDBItem (event, newValue)}
									onInputChange={(event) => DBItemSearch(event.target.value)}
									isOptionEqualToValue={(option, value) => option[dbitemkey] === value}
									getOptionLabel={(option) => option[dbitemkey]}
									/* Work-around for when there are no options and you get errors for getOptionLabel:
										getOptionLabel={
											(option) => {
												if (option){
													return option["Name"];
												} else {
													return "";
												}
											}
										}
									*/
									options={dbitemoptions}
									loading={loadingdbitemoptions}
									renderInput={(params) => (
										<TextField variant="standard"
											className={classes.autocompleteinput}
											style={{padding:"0px"}}
											{...params}
											InputProps={{
												disableUnderline: false,
												...params.InputProps,
												autoComplete: "new-password",
												endAdornment: (
													<React.Fragment>
														{loadingdbitemoptions ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</div>
						}

						{/* Date */}
						{(changeisdate) &&
							<FormControl className={classes.formControl} style={{width:"300px"}}>
							<TextField variant="standard"
								id="datetime-local"
								type="datetime-local"
								defaultValue=""
								onChange={handleChangeBulkValue}
								sx={{ width: 300 }}
								InputLabelProps={{
								shrink: true,
								}}
							/>
							</FormControl>
						}


						{/* Change Is Bool */}
						{(changeisbool) &&
							<div style={{marginTop:"20px"}}>
								<FormControl>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										defaultValue="0"
										name="radio-buttons-group"
										onChange={handleChangeBulkValue}
									>
										<FormControlLabel value="0" control={<Radio />} label="Disable" />
										<FormControlLabel value="1" control={<Radio />} label="Enable" />
									</RadioGroup>
								</FormControl>
							</div>
						}

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => ApplyBulkEdit()}
							ref={el => btnApplyBulkEdit.current = el}>
							Apply Change
						</Button>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseBulkEditDrawer()}>
							Close
						</Button>
						
					</div>
				</Drawer>

				{/* Standard Page Header with right floated error message space */}
				<div style={{ height: "50px", paddingTop:"5px" }}>
					<div style={{ textAlign: "center" }}>
						<h2>Products</h2>
					</div>
					{(errors.currenterror.errshow) &&
						<div style={{ position: "relative", float: "right", bottom: "26px", height: "0px", fontSize: "12px" }}>
							<ErrorMessage />
						</div>
					}
					<div style={{ position: "relative", float: "right", bottom: "30px", height: "0px" }}>
						<ProgressBar />
					</div>
				</div>

				{/* /* ##########################  Search Inputs  ########################## */}
				{/* Search Tools should: Fall in-line, stack, have padding-right of 15px, 300px wide.*/}
				{/* CHOOSE between AutoCompletes OR Key-Value searches. Helps keep interface looking CLEAN. */}

				{(!isPrintView) &&
					<div>

						{/* Key-Value Searches */}

						{/* Search Pair 1 */}
						{(searchinputs.show1) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									{/* Value must match one of the MenuItem values, SerialNumber != Serial Number */}
									<Select
										key={localstate.searchoptions.searchpairs.searchpair1.uuid}
										value={localstate.searchoptions.searchpairs.searchpair1.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 1)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Mfg"}>Mfg</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"TotalInventoryUnits >"}>Inventory &#62;</MenuItem>
										<MenuItem value={"TotalInventoryUnits <"}>Inventory &#60;</MenuItem>
										<MenuItem value={"Cost >"}>Cost &#62;</MenuItem>
										<MenuItem value={"Cost <"}>Cost &#60;</MenuItem>
										<MenuItem value={"TotalInventoryCost >"}>Total Cost &#62;</MenuItem>
										<MenuItem value={"TotalInventoryCost <"}>Total Cost &#60;</MenuItem>
										<MenuItem value={"AverageAgeDays >"}>Age &#62;</MenuItem>
										<MenuItem value={"AverageAgeDays <"}>Age &#60;</MenuItem>
										<MenuItem value={"StockOutDays30 >"}>Stock Out Days &#62;</MenuItem>
										<MenuItem value={"StockOutDays30 <"}>Stock Out Days &#60;</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair1.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair1.type!=="DateTimeAfter") &&
									<TextField id="search1" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair1.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair1.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 1)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair1.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair1.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair1.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 1)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: "tt.mm.jjjj"
											}}
										 />}
									/>
								}
								{/* The 30px wide spacer for a close button can be optionally removed. The space might better be used for view vs the need for close buttons */}
								<div style={{ width: "30px" }}>&nbsp;</div>
							</div>
						}

						{/* Search Pair 2 */}
						{(searchinputs.show2) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair2.uuid}
										value={localstate.searchoptions.searchpairs.searchpair2.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 2)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Mfg"}>Mfg</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"TotalInventoryUnits >"}>Inventory &#62;</MenuItem>
										<MenuItem value={"TotalInventoryUnits <"}>Inventory &#60;</MenuItem>
										<MenuItem value={"Cost >"}>Cost &#62;</MenuItem>
										<MenuItem value={"Cost <"}>Cost &#60;</MenuItem>
										<MenuItem value={"TotalInventoryCost >"}>Total Cost &#62;</MenuItem>
										<MenuItem value={"TotalInventoryCost <"}>Total Cost &#60;</MenuItem>
										<MenuItem value={"AverageAgeDays >"}>Age &#62;</MenuItem>
										<MenuItem value={"AverageAgeDays <"}>Age &#60;</MenuItem>
										<MenuItem value={"StockOutDays30 >"}>Stock Out Days &#62;</MenuItem>
										<MenuItem value={"StockOutDays30 <"}>Stock Out Days &#60;</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair2.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair2.type!=="DateTimeAfter") &&
									<TextField id="search2" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair2.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair2.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 2)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair2.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair2.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair2.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 2)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: ""
											}}
										 />}
									/>
								}
								<div style={{ width: "30px", float: "right" }}>
									{(searchinputs.show2 && !searchinputs.show3) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* Search Pair 3 */}
						{(searchinputs.show3) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair3.uuid}
										value={localstate.searchoptions.searchpairs.searchpair3.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 3)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Mfg"}>Mfg</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"TotalInventoryUnits >"}>Inventory &#62;</MenuItem>
										<MenuItem value={"TotalInventoryUnits <"}>Inventory &#60;</MenuItem>
										<MenuItem value={"Cost >"}>Cost &#62;</MenuItem>
										<MenuItem value={"Cost <"}>Cost &#60;</MenuItem>
										<MenuItem value={"TotalInventoryCost >"}>Total Cost &#62;</MenuItem>
										<MenuItem value={"TotalInventoryCost <"}>Total Cost &#60;</MenuItem>
										<MenuItem value={"AverageAgeDays >"}>Age &#62;</MenuItem>
										<MenuItem value={"AverageAgeDays <"}>Age &#60;</MenuItem>
										<MenuItem value={"StockOutDays30 >"}>Stock Out Days &#62;</MenuItem>
										<MenuItem value={"StockOutDays30 <"}>Stock Out Days &#60;</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair3.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair3.type!=="DateTimeAfter") &&
									<TextField id="search3" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair3.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair3.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 3)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair3.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair3.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair3.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 3)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: ""
											}}
										 />}
									/>
								}
								<div style={{ width: "30px" }}>
									{(searchinputs.show3 && !searchinputs.show4) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* Search Pair 4 */}
						{(searchinputs.show4) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair4.uuid}
										value={localstate.searchoptions.searchpairs.searchpair4.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 4)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Mfg"}>Mfg</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"TotalInventoryUnits >"}>Inventory &#62;</MenuItem>
										<MenuItem value={"TotalInventoryUnits <"}>Inventory &#60;</MenuItem>
										<MenuItem value={"Cost >"}>Cost &#62;</MenuItem>
										<MenuItem value={"Cost <"}>Cost &#60;</MenuItem>
										<MenuItem value={"TotalInventoryCost >"}>Total Cost &#62;</MenuItem>
										<MenuItem value={"TotalInventoryCost <"}>Total Cost &#60;</MenuItem>
										<MenuItem value={"AverageAgeDays >"}>Age &#62;</MenuItem>
										<MenuItem value={"AverageAgeDays <"}>Age &#60;</MenuItem>
										<MenuItem value={"StockOutDays30 >"}>Stock Out Days &#62;</MenuItem>
										<MenuItem value={"StockOutDays30 <"}>Stock Out Days &#60;</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair4.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair4.type!=="DateTimeAfter") &&
									<TextField id="search4" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair4.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair4.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 4)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair4.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair4.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair4.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 4)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: ""
											}}
										 />}
									/>
								}
								<div style={{ width: "30px" }}>
									{(searchinputs.show4 && !searchinputs.show5) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}


						{/* Search Pair 5 */}
						{(searchinputs.show5) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair5.uuid}
										value={localstate.searchoptions.searchpairs.searchpair5.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 5)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Mfg"}>Mfg</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"TotalInventoryUnits >"}>Inventory &#62;</MenuItem>
										<MenuItem value={"TotalInventoryUnits <"}>Inventory &#60;</MenuItem>
										<MenuItem value={"Cost >"}>Cost &#62;</MenuItem>
										<MenuItem value={"Cost <"}>Cost &#60;</MenuItem>
										<MenuItem value={"TotalInventoryCost >"}>Total Cost &#62;</MenuItem>
										<MenuItem value={"TotalInventoryCost <"}>Total Cost &#60;</MenuItem>
										<MenuItem value={"AverageAgeDays >"}>Age &#62;</MenuItem>
										<MenuItem value={"AverageAgeDays <"}>Age &#60;</MenuItem>
										<MenuItem value={"StockOutDays30 >"}>Stock Out Days &#62;</MenuItem>
										<MenuItem value={"StockOutDays30 <"}>Stock Out Days &#60;</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair5.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair5.type!=="DateTimeAfter") &&
									<TextField id="search5" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair5.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair5.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 5)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair5.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair5.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair5.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 5)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: ""
											}}
										 />}
									/>
								}
								<div style={{ width: "30px" }}>
									{(searchinputs.show5 && !searchinputs.show6) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* Search Pair 6 */}
						{(searchinputs.show6) &&
							<div className={classes.searchinputs} style={{width:"300px"}}>
								<FormControl className={classes.searchtypeinput} style={{ minWidth: "120px" }} variant="standard">
									<Select
										key={localstate.searchoptions.searchpairs.searchpair6.uuid}
										value={localstate.searchoptions.searchpairs.searchpair6.type} disableUnderline
										onChange={(event) => onChangeSearchType(event.target.value, 6)}
										MenuProps={{
											style: {zIndex: 2001}
										}}
									>
										<MenuItem value={"Name"}>Name</MenuItem>
										<MenuItem value={"Mfg"}>Mfg</MenuItem>
										<MenuItem value={"ProductType"}>Product Type</MenuItem>
										<MenuItem value={"TotalInventoryUnits >"}>Inventory &#62;</MenuItem>
										<MenuItem value={"TotalInventoryUnits <"}>Inventory &#60;</MenuItem>
										<MenuItem value={"Cost >"}>Cost &#62;</MenuItem>
										<MenuItem value={"Cost <"}>Cost &#60;</MenuItem>
										<MenuItem value={"TotalInventoryCost >"}>Total Cost &#62;</MenuItem>
										<MenuItem value={"TotalInventoryCost <"}>Total Cost &#60;</MenuItem>
										<MenuItem value={"AverageAgeDays >"}>Age &#62;</MenuItem>
										<MenuItem value={"AverageAgeDays <"}>Age &#60;</MenuItem>
										<MenuItem value={"StockOutDays30 >"}>Stock Out Days &#62;</MenuItem>
										<MenuItem value={"StockOutDays30 <"}>Stock Out Days &#60;</MenuItem>
									</Select>
								</FormControl>
								{(localstate.searchoptions.searchpairs.searchpair6.type!=="DateTimeBefore" && localstate.searchoptions.searchpairs.searchpair6.type!=="DateTimeAfter") &&
									<TextField id="search6" variant="standard"
										key={localstate.searchoptions.searchpairs.searchpair6.uuid + 1}
										defaultValue={localstate.searchoptions.searchpairs.searchpair6.value}
										className={classes.searchinput} InputProps={{ disableUnderline: true }}
										onChange={(event) => onChangeSearchValue(event.target.value, 6)} 
									/>
								}
								{(localstate.searchoptions.searchpairs.searchpair6.type==="DateTimeBefore" || localstate.searchoptions.searchpairs.searchpair6.type==="DateTimeAfter") &&
									<DateTimePicker
										value={localstate.searchoptions.searchpairs.searchpair6.value}
										onChange={(newvalue) => onChangeSearchValue(newvalue, 6)}
										renderInput={(params) => <TextField 
											{...params} variant="standard" className={classes.searchinput} 
											inputProps={{
												...params.inputProps,
												placeholder: ""
											}}
										 />}
									/>
								}
								<div style={{ width: "30px" }}>
									{(searchinputs.show6 && !searchinputs.show6) &&
										<IconButton className={classes.transparenticon} size="small" aria-label="delete" onClick={() => RemoveSearch()}>
											<HighlightOffIcon color="primary" fontSize="large" style={{ padding: "5px" }}></HighlightOffIcon>
										</IconButton>
									}
								</div>
							</div>
						}

						{/* On last search input, don't show! */}
						{(!searchinputs.show6) &&
							<div style={{ display: "inline-block", paddingLeft: "15px" }}>
								<IconButton size="small" aria-label="Add Search" onClick={() => AddSearch()}>
									<AddIcon color="primary" fontSize="large" style={{ padding: "5px" }}></AddIcon>
								</IconButton>
							</div>
						}
					</div>
				}

				{/* End of Search Inputs */}


				{/* Top Buttons & Pagination */}
				{(!isPrintView) &&
					<React.Fragment>
						<div style={{ height: "5px" }}>&nbsp;</div>
						<div>
							{(!showdeleteconfirmation && !showexportconfirmation) &&
								<React.Fragment>

									
										<Button
											className={(userPerms.updateProduct === 1) ? classes.bluebtn : classes.hidden }
											color="primary" variant="contained"
											onClick={() => SaveChanges()}
											ref={el => btnSave.current = el}>
											<SaveIcon sx={{color:"lightgray"}}></SaveIcon>&nbsp;Save Changes
										</Button>
									

										<Button
											className={(userPerms.updateProduct === 1) ? classes.bluebtn : classes.hidden }
											color="primary" variant="contained"
											style={{display:"none"}}
											onClick={() => SaveChanges()}
											ref={el => btnPendingSave.current = el}>
											<PendingIcon sx={{color:"orange"}}></PendingIcon>&nbsp;Save Changes
										</Button>
									

										<Button
											className={(userPerms.updateProduct === 1) ? classes.bluebtn : classes.hidden }
											color="primary" variant="contained"
											onClick={() => RejectIfInvalidSelected(true, setShowBulkEditDrawer)}
											ref={el => btnEditSelected.current = el}>
											Edit Selected
										</Button>
									


										<Button
											className={(userPerms.deleteProduct === 1) ? classes.bluebtn : classes.hidden }
											color="primary" variant="contained"
											onClick={() => RejectIfInvalidSelected("",DeleteSelectedInit)}
											ref={el => btnDeleteSelected.current = el}>
											Delete Selected
										</Button>
									

										<Button
											className={(userPerms.createProduct === 1) ? classes.bluebtn : classes.hidden }
											color="primary" variant="contained"
											onClick={() => AddNewProduct()}
											ref={el => btnAddProduct.current = el}>
											Add Product
										</Button>
									


									{/* ##########################  Column Toggles  ########################## */}
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowColumnMenu}>
										Columns
									</Button>

									<Menu
										className={classes.bluebtn}
										color="primary"
										id="simple-menu"
										anchorEl={showcolumnmenu}
										keepMounted
										open={Boolean(showcolumnmenu)}
										onClose={CloseColumnMenu}
									>
										<MenuItem disableRipple className={classes.columnmenu}>
											<div style={{verticalAlign:"top"}}> {/* Optional container for 2 column menu! */}
												<div style={{display:"inline-block", maxHeight:"600px", overflow:"auto", verticalAlign:"top"}}>
													<FormGroup>
														<FlexColumnOption value="created_at" label="Creation Date"/>
														<FlexColumnOption value="Name" label="Name"/>
														<FlexColumnOption value="Mfg" label="Manufacturer"/>
														<FlexColumnOption value="Cost" label="Cost"/>
														<FlexColumnOption value="ShippingCost" label="Shipping Cost"/>
														<FlexColumnOption value="ProductType" label="Product Type"/>
														<FlexColumnOption value="ProductID" label="Product ID"/>
														<FlexColumnOption value="PartsLocation" label="Parts Location"/>
														<FlexColumnOption value="Components" label="Components"/>
														<FlexColumnOption value="TotalInventoryCost" label="Total Inv Cost"/>
														<FlexColumnOption value="TotalInventoryUnits" label="Total Inv Units"/>
														<FlexColumnOption value="Grades" label="Grades"/>
														<FlexColumnOption value="GradeAPct" label="Grade A &#37;"/>
														<FlexColumnOption value="GradeBPct" label="Grade B &#37;"/>
														<FlexColumnOption value="GradeCPct" label="Grade C &#37;"/>
														<FlexColumnOption value="GradeRepairPct" label="Grade Repair &#37;"/>
														<FlexColumnOption value="GradeScrapPct" label="Grade Scrap &#37;"/>
														<FlexColumnOption value="OneYrSalesTotal" label="1Yr Sales Total"/>
														<FlexColumnOption value="OneYrSalesUnits" label="1Yr Sales Units"/>
														<FlexColumnOption value="AverageAgeDays" label="Average Age Days"/>
														<FlexColumnOption value="AverageCostMostRecent" label="Average Cost Recent"/>
														<FlexColumnOption value="AverageCostActual" label="Average Cost Actual"/>
														<FlexColumnOption value="PercentOfInventoryUnits" label="Percent Of Inventory Units"/>
														<FlexColumnOption value="PercentOfInventoryCost" label="Percent Of Inventory Cost"/>
														<FlexColumnOption value="StockOutDays30" label="Days Before Stock Out"/>
														<FlexColumnOption value="RunDailyStats" label="Run Stats Status"/>
														<FlexColumnOption value="stats_updated_at" label="Stats Update Date"/>
													</FormGroup>
												</div>
												<div style={{display:"inline-block", padding:"0px 15px", verticalAlign:"top"}}>
													{/* Unfortunately any custom view will need to close the menu. Our checkboxes are user input only! */}
													<Button className={classes.bluebtn}
															color="primary" 
															variant="contained"
															onClick={()=>showPricingAndComponents()}>
																Pricing and Components
													</Button><br></br>
													<Button className={classes.bluebtn}
															color="primary" 
															variant="contained"
															onClick={()=>showStats()}>
																Grades and Statistics
													</Button><br></br>
													<Button className={classes.bluebtn}
															color="primary" 
															variant="contained"
															onClick={()=>showTurnoverStats()}>
																Turnover Shortlist
													</Button><br></br>
													<Button className={classes.bluebtn}
															color="primary" 
															variant="contained"
															onClick={()=>showNewStockStats()}>
																New Stock Shortlist
													</Button>
												</div>
											</div>
										</MenuItem>
									</Menu>


									<Button
										className={(userPerms.updateProduct === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										{...(editmode ? {
											style: {
												textDecoration: "underline"
											}
										} :
											{
												style: {
													textDecoration: "none"
												},
												disabled: (localstate.rowsperpage > 100 ? true : false)
											})}
										onClick={() => EditMode()}
										ref={el => btnEditMode.current = el}>
										Edit Mode
									</Button>

									<Button
										className={(userPerms.readProductType === 1) ? classes.bluebtn : classes.hidden}
										color="primary" variant="contained" onClick={() => GoToProductTypes()}>
										View\Edit Product Types
									</Button>
									
								</React.Fragment>
							}

							{/* Delete Items Confirmation */}
							{(showdeleteconfirmation) &&
								<div>
									<b>Are you sure you want to delete these items?</b>
									<div style={{ padding: "10px 0px" }}>
										{deleteitems.map((row, index) => {
											if (deleteitems.length === index + 1) {
												return (<span key={index}>{row.Name}</span>)
											} else {
												return (<span key={index}>{row.Name}, </span>)
											}
										})}
									</div>
									<Button className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
									<Button className={classes.bluebtn}
										color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
								</div>
							}

					

							{(localstate.totalitems > 0) &&
								<TablePagination className={classes.paginationalign}
									SelectProps={
										editmode ? { disabled: true } : { disabled: false }
									}
									style={{ display: "inline-flex", float: "right" }}
									component="div"
									count={localstate.totalitems}
									page={localstate.page}
									onPageChange={handleChangePage}
									rowsPerPage={localstate.rowsperpage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									rowsPerPageOptions={[100, 200, 500]}
								/>
							}
						</div>
					</React.Fragment>
				}
				{/* End of Top Buttons & Pagination */}


				{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
				<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
						<EnhancedTableHead
							numSelected={localstate.selectedcount} 
							classes={classes}
							order={localstate.order}
							orderBy={localstate.orderby}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={state.griditems.length}
						/>
						{/* /* ##########################  Row Design  ########################## */	 }
						{/* If DB reload, don't allow view of table. */}
						{(!localstate.dbreload) &&
							<tbody style={{ display: "table-row-group" }}>
								{(localstate.griditems.length > 0) &&
									localstate.griditems.map((row, index) => {
										//Create all-new refs on each render. Helps avoid issues with grid states.
										//rowRefs.current[index + "Checkbox"] = React.createRef();
										rowRefs.current[index + "SaveStatus"] = React.createRef();
										rowRefs.current[index + "Name"] = React.createRef();
										rowRefs.current[index + "Mfg"] = React.createRef();
										rowRefs.current[index + "Cost"] = React.createRef();
										rowRefs.current[index + "ShippingCost"] = React.createRef();
										rowRefs.current[index + "PartsLocation"] = React.createRef();
										
										return (
											<React.Fragment key={row.ProductID}>
												<tr	className={classes.flexgridrow}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															{/* Don't show checkbox if this is a PendingItem */}
														{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}

														</div>
													</td>


													{(colstate.created_at) &&
														<td className={classes.flexgridstaticcontainer}>
															<Moment element='span' format="MMMM D, YYYY [at] h:mma">{row.created_at}</Moment>
														</td>
													}



													{/* Name - Optional Expand toggle! Change flexgridinput-30 to flexgridinput to remove icon spacer. */}
													{(colstate.Name) &&
														<React.Fragment>
															{(editmode && colstate.Name) &&
																<td className={classes.flexgridinputcontainer}>
																	<input
																		ref={el => rowRefs.current[index + 'Name'] = el}
																		className={classes.flexgridinput30}
																		style={{ minWidth: '50px', textAlign: "left" }}
																		onKeyDown={(event) => HandleKeyDown(event, index, 'Name')}
																		onKeyUp={(event) => onChangeValue(event, index, 'Name')}
																		defaultValue={localstate.defaultvalues[index].Name} />
																</td>
															}

															{(!editmode && colstate.Name) &&
																<td className={classes.flexgridstaticcontainer}>
																	{row.Name}
																</td>
															}
														</React.Fragment>
													}

													{/* Nav */}
													<td>
														<a href={hostbase + "/product/" + row.ProductID} target="_blank">
															<OpenInNewIcon className={classes.transparenticon} color="primary" fontSize="medium">
															</OpenInNewIcon>
														</a>
														<a href={hostbase + "/inventorymanager?ProductID=" + encodeURIComponent(row.ProductID)+"&ProductName="+encodeURIComponent(row.Name)} target="_blank"> 
															<ViewListIcon className={classes.transparenticon} color="primary" fontSize="medium">
															</ViewListIcon>
														</a>
													</td>


													{/* Mfg */}
													{(editmode && colstate.Mfg) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Mfg'] = el}
																className={classes.flexgridinput30}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Mfg')}
																onKeyUp={(event) => onChangeValue(event, index, 'Mfg')}
																defaultValue={localstate.defaultvalues[index].Mfg} 
															/>
														</td>
													}

													{(!editmode && colstate.Mfg) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Mfg}
														</td>
													}


													{/* Cost */}
													{(editmode && colstate.Cost) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'Cost'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Cost')}
																onKeyUp={(event) => onChangeValue(event, index, 'Cost')}
																onBlur={(event) => DetectBlankNumber(event, index, "Cost")}
																defaultValue={localstate.defaultvalues[index].Cost} />
														</td>
													}

													{(!editmode && colstate.Cost) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.Cost}
														</td>
													}


													{/* Shipping Cost */}
													{(editmode && colstate.ShippingCost) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'ShippingCost'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'ShippingCost')}
																onKeyUp={(event) => onChangeValue(event, index, 'ShippingCost')}
																onBlur={(event) => DetectBlankNumber(event, index, "ShippingCost")}
																defaultValue={localstate.defaultvalues[index].ShippingCost} />
														</td>
													}

													{(!editmode && colstate.ShippingCost) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.ShippingCost}
														</td>
													}



													{/* ProductType */}
													{(colstate.ProductType) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.ProductType}
														</td>
													}



													{/* ProductID */}
													{(colstate.ProductID) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"center"}}>
															{row.ProductID}
														</td>
													}



													{/* PartsLocation */}
													{(editmode && colstate.PartsLocation) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'PartsLocation'] = el}
																className={classes.flexgridinput30}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'PartsLocation')}
																onKeyUp={(event) => onChangeValue(event, index, 'PartsLocation')}
																defaultValue={localstate.defaultvalues[index].PartsLocation} 
															/>
														</td>
													}
													{(!editmode && colstate.PartsLocation) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.PartsLocation}
														</td>
													}


													{/* Components */}
													{(editmode && colstate.Components) &&
														<td className={classes.flexgridstaticcontainer}>
															<React.Fragment>
																{row.productcomponents.map((component, componentindex) => {
																	return (
																		<div key={componentindex} className={classes.productcomponent} title={component.details.Cost}>
																			{component.details.Name} <CloseIcon className={classes.productcomponentdeleteicon} onClick={(event) => { RemoveComponent(index, component.ID) }} />
																		</div>
																	)
																})}
																<AddIcon style={{ verticalAlign: "bottom" }} className={classes.hoverunit} onClick={(event) => AddComponent(index)} />
															</React.Fragment>
														</td>
													}
													{(!editmode && colstate.Components) &&
														<td className={classes.flexgridstaticcontainer}>
															<div style={{ paddingTop: "3px" }}>
																{row.productcomponents.map((component, componentindex) => {
																	return (
																		<div key={componentindex} className={classes.productcomponent} title={component.details.Cost}>
																			{component.details.Name}
																		</div>
																	)
																})}
															</div>
														</td>
													}




													{/* RunDailyStats */}
													{(colstate.RunDailyStats) &&
														<React.Fragment>
															{(editmode) &&
																<td>													
																	<div style={{ padding: "4px 4px 1px 4px", textAlign:"center" }}>
																		<Checkbox
																			className={classes.gridcheckbox}
																			disableRipple
																			color="default"
																			defaultChecked={row.RunDailyStats===1 ? true : false}
																			checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																			icon={<span className={classes.icon} />}
																			onChange={(event) => onChangeValue(event, index, "RunDailyStats")}
																		/>
																	</div>
																</td>
															}

															{(!editmode) &&
																<td>
																	<div style={{ padding: "4px 4px 1px 4px", textAlign: "center" }}>
																		<Checkbox
																			className={classes.gridcheckbox}
																			disabled
																			disableRipple
																			color="default"
																			defaultChecked={row.RunDailyStats === 1 ? true : false}
																			checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																			icon={<span className={classes.icon} />}
																			onChange={(event) => onChangeValue(event, index, "RunDailyStats")}
																		/>
																	</div>
																</td>
															}
														</React.Fragment>
													}



													{/* TotalInventoryCost */}
													{(colstate.TotalInventoryCost) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.TotalInventoryCost}
														</td>
													}



													{/* TotalInventoryUnits */}
													{(colstate.TotalInventoryUnits) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.TotalInventoryUnits}
														</td>
													}



													{/* OneYrReturnRate */}
													{(colstate.OneYrReturnRate) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.OneYrReturnRate}
														</td>
													}



													{/* OneYrReturnUnits */}
													{(colstate.OneYrReturnUnits) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.OneYrReturnUnits}
														</td>
													}



													{/* OneYrSalesTotal */}
													{(colstate.OneYrSalesTotal) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.OneYrSalesTotal}
														</td>
													}



													{/* OneYrSalesUnits */}
													{(colstate.OneYrSalesUnits) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.OneYrSalesUnits}
														</td>
													}



													{/* AverageAgeDays */}
													{(colstate.AverageAgeDays) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.AverageAgeDays}
														</td>
													}



													{/* AverageCostMostRecent */}
													{(colstate.AverageCostMostRecent) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.AverageCostMostRecent}
														</td>
													}



													{/* AverageCostActual */}
													{(colstate.AverageCostActual) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.AverageCostActual}
														</td>
													}



													{/* PercentOfInventoryUnits */}
													{(colstate.PercentOfInventoryUnits) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.PercentOfInventoryUnits}
														</td>
													}



													{/* PercentOfInventoryCost */}
													{(colstate.PercentOfInventoryCost) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.PercentOfInventoryCost}
														</td>
													}



													{/* StockOutDays30 */}
													{(colstate.StockOutDays30) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.StockOutDays30}
														</td>
													}


													{/* Grades - Something something */}
													{(colstate.Grades) &&
														<td className={classes.flexgridstaticcontainer}>
															{/*
															Grade A: {row.GradeACount}<br></br>
															Grade B: {row.GradeBCount}<br></br>
															Grade C: {row.GradeCCount}<br></br>
															Grade Repair: {row.GradeRepairCount}<br></br>
															Grade Scrap: {row.GradeScrapCount}<br></br>
															Grade Unknown: {row.GradeUnknownCount}<br></br>

															Grade A Pct: {row.GradeAPct}<br></br>
															Grade B Pct: {row.GradeBPct}<br></br>
															Grade C Pct: {row.GradeCPct}<br></br>
															Grade Repair Pct: {row.GradeRepairPct}<br></br>
															Grade Scrap Pct: {row.GradeScrapPct}<br></br>
															Grade Unknown Pct: {row.GradeUnknownPct}<br></br>
													*/}
															
															<div style={{width:"100px"}}>
																<div style={{width:(row.GradeAPct+"%"), backgroundColor:"#249a00", height:"15px", display:"inline-block"}}></div>
																<div style={{width:(row.GradeBPct+"%"), backgroundColor:"#ccff00", height:"15px", display:"inline-block"}}></div>
																<div style={{width:(row.GradeCPct+"%"), backgroundColor:"#fff600", height:"15px", display:"inline-block"}}></div>
																<div style={{width:(row.GradeRepairPct+"%"), backgroundColor:"#ffd200", height:"15px", display:"inline-block"}}></div>
																<div style={{width:(row.GradeScrapPct+"%"), backgroundColor:"#ff0000", height:"15px", display:"inline-block"}}></div>
																<div style={{width:(row.GradeUnknownPct+"%"), backgroundColor:"#c6c6c6", height:"15px", display:"inline-block"}}></div>
															</div>
														</td>
													}


													{/* Grade A % */}
													{(colstate.GradeAPct) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.GradeAPct}
														</td>
													}

													{/* Grade B % */}
													{(colstate.GradeBPct) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.GradeBPct}
														</td>
													}

													{/* Grade C % */}
													{(colstate.GradeCPct) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.GradeCPct}
														</td>
													}

													{/* Grade Repair % */}
													{(colstate.GradeRepairPct) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.GradeRepairPct}
														</td>
													}

													{/* Grade Scrap % */}
													{(colstate.GradeScrapPct) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.GradeScrapPct}
														</td>
													}

													{/* Stats Update Date */}
													{(colstate.stats_updated_at) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.stats_updated_at}
														</td>
													}

												</tr>
											</React.Fragment>

										)
									}
									)
								}
								{(localstate.griditems.length === 0) &&
									<tr className="flexgridrow"><td colSpan="100%"
										style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
								}
							</tbody>
						}
						{(localstate.dbreload) &&
							<tbody>
								<tr>
									<td colSpan="100%">
										<div style={{padding:"20px", textAlign:"center", margin:"auto"}}>
											<CircularProgress />
										</div>
									</td>
								</tr>
							</tbody>
						}
					</table> 
				</div>
				
				
				{(localstate.totalitems>localstate.rowsperpage) &&
					<TablePagination className={classes.paginationalign}
						SelectProps={
							editmode ? { disabled: true } : { disabled: false }
						}
						component="div"
						count={localstate.totalitems}
						page={localstate.page}
						onPageChange={handleChangePage}
						rowsPerPage={localstate.rowsperpage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						rowsPerPageOptions={[100,200,500]}
					/>
				}
				
			</div>
		</LocalizationProvider>
    );
}

export default ProductsTable;
