//Product View


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext"
//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//Datetime formatting
import Moment from 'react-moment';
//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

//Printing
import { useReactToPrint } from 'react-to-print';

import theme from '../../ui/theme';


//Product Details
import ProductDetails from './ProductDetails';
import ProductStatistics from './ProductStatistics';
import ProductHistory from './ProductHistory';

//FlexDocument
import { FlexDocument } from '../Documents/FlexDocument';



/* ##########################  Configuration  ########################## */

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Axios Long Post
const longpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 20000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1, 1, 2, 3, 3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function (acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	}, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};



const ProductView = (props) => {
	let history = useHistory();
	//URL Param for ID
	let { id } = useParams();
	console.log(id);

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();
	const btnPendingSave = useRef();

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Need to initialize a blank item
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		itemloaded: false, //POSSIBLY MOST IMPORTANT! Using defaultValue on an input causes issues if you try to render before item is loaded.
		pendingupdate: false,
		itemdata: {
			ProductID: id
		},
		SomeBooleanDefault: true,
		RunDailyStatsDefault:false
	});
	
	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	const itemRefs = useRef({
		CostEl: "",
		ShippingCostEl: ""
	})


	//Ref used for printing
	const printRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});


	//Load Item
	function LoadItem() {
		const postdata = {
			ProductID: localstate.itemdata.ProductID
		};
		axios.post(dbendpoint + "/products/getproduct", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					localstate.itemdata = res.data.item;

					//RECORD HISTORY DROP IN
					localstate.itemdata.RecordHistory = JSON.parse(localstate.itemdata.RecordHistory);

					//PieChat Helper
					//Create data for piechart!
					localstate.itemdata.piechartdata = [];
					if (localstate.itemdata.GradeACount>0){
						localstate.itemdata.piechartdata.push({
							id:"A",
							label: "Grade A",
							value:localstate.itemdata.GradeACount
						})
					}

					if (localstate.itemdata.GradeBCount>0){
						localstate.itemdata.piechartdata.push({
							id:"B",
							label: "Grade B",
							value:localstate.itemdata.GradeBCount
						})
					}

					if (localstate.itemdata.GradeCCount>0){
						localstate.itemdata.piechartdata.push({
							id:"C",
							label: "Grade C",
							value:localstate.itemdata.GradeCCount
						})
					}

					if (localstate.itemdata.GradeRepairCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Repair",
							label: "Grade Repair",
							value:localstate.itemdata.GradeRepairCount
						})
					}

					if (localstate.itemdata.GradeScrapCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Scrap",
							label: "Grade Scrap",
							value:localstate.itemdata.GradeScrapCount
						})
					}

					if (localstate.itemdata.GradeUnknownCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Unknown",
							label: "Grade Unknown",
							value:localstate.itemdata.GradeUnknownCount
						})
					}

					//Cost is always nice to have pre-formatted
					localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);
					localstate.dbreload = false;
					//Set Defaults on first load:
					if (!localstate.itemloaded){
						localstate.itemdatadefaults = localstate.itemdata;
						localstate.itemloaded = true; //Now allow render
						document.title = localstate.itemdata.Name;
					}
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}



	useEffect(() => {
		if (state.dbreload) {
			document.title = "Product"; //To be changed after item is loaded.
			//Avoid duplicate loads.
			localstate.dbreload = false;
			//Diagnostic:
			console.log("Localstate ID: " + localstate.itemdata.ID);
			LoadItem();
		}
	}, [state]);


	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};


	// //Restrict Number (too many places past the decimal)
	// const RestrictNumber = (newvalue, oldvalue, event) => {
	// 	var len = newvalue.length;
	// 	var index = newvalue.indexOf('.');
	// 	if (event.key === "." || event.key === "Period" || event.key === "NumpadDecimal") {
	// 		console.log("Decimal key detected in Restrict Number");
	// 		return false;
	// 	}
	// 	if (event.key === "Backspace") {
	// 		console.log("Backspace key detected!");
	// 		//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value
	// 		if ((oldvalue.indexOf(".") > -1) && (index === -1)) {
	// 			return false;
	// 		}
	// 	}
	// 	if (index > -1) {
	// 		if ((len - 1) > (index + 2)) {
	// 			//We are out past 2 decimals!
	// 			return oldvalue;
	// 		}
	// 	}
	// 	return newvalue;
	// }


	// const SaveChanges = () => {
	// 	//Clean up current errors:
	// 	errors.HideError(errors);
		
	// 	let postdata = {
	// 		item: localstate.itemdata
	// 	}
	// 	axios.post(dbendpoint + "/products/update", postdata, defaultpostoptions).then(res => {
	// 		//API should be setup to send 200 response with status. Merge paginated requests.
	// 		if (res.status === 200) {
	// 			//If ValidateUser() fails to verify user, it sends back 'login' error. 
	// 			if (res.data.Status === "login") {
	// 				//Not logged in. Reload page causes redirect to /login
	// 				window.location.reload(false);
	// 			}
	// 			//All new API calls should return a status.
	// 			if (res.data.Status === "Success") {
	// 				btnSave.current.style.display="";
	// 				btnPendingSave.current.style.display="none";
	// 				//Attempt to replace: btnSave.current.disabled = true;
	// 				localstate.pendingsaves = false;
	// 				localstate.dbreload = true;
	// 				UpdateState(localstate);
	// 			}
	// 			if (res.data.Status === "Failure") {
	// 				//Failure error
	// 				errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 15, errtype: "warning" })
	// 			}
	// 		} else {
	// 			//Non-200 message from server.
	// 			errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 15, errtype: "warning" })
	// 		}
	// 	});
	// }



	// //Changes to item
	// const onChangeValue = (event, itemkey) => {
	// 	var oldvalue = localstate.itemdata[itemkey];
	// 	var newvalue = event.target.value;
	// 	if (event.key !== "Tab" &&
	// 		event.key !== "ArrowDown" &&
	// 		event.key !== "ArrowUp" &&
	// 		event.key !== "ShiftLeft" &&
	// 		event.key !== "ShiftRight"
	// 	) {

	// 		if (itemkey === "Cost" || itemkey === "ShippingCost") {
	// 			if (!oldvalue){
	// 				oldvalue="0";
	// 			}
	// 			//A new value from RestrictInputNumber means it will override the input. We'll attempt to place the cursor back where it was. Otherwise, take in new value and do not adjust the input.
	// 			newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float"); //All arguments required. New universal function for all pages. However handle result below.
	// 			if (newvalue) {
	// 				itemRefs.current[itemkey+"El"].value = newvalue;
	// 				localstate.itemdata[itemkey] = newvalue;
	// 				//Attempt to refocus and place cursor at the end of the input - This will require we switch the input to text, then back to number:
	// 				const inputElement = itemRefs.current[itemkey+"El"];
	// 				inputElement.type = 'text';
	// 				inputElement.focus();
	// 				inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length);
	// 				inputElement.type = 'number';
	// 			} else {
	// 				console.log("No newvalue returned, set value in localstate, but leave input untouched.");
	// 				console.log(parseFloat(event.target.value).toFixed(2));
	// 				localstate.itemdata[itemkey] = parseFloat(event.target.value).toFixed(2);
	// 			}
	// 		}

	// 		if (itemkey === "RunDailyStats") {
	// 			if (event.target.checked) {
	// 				localstate.itemdata.RunDailyStats = 1;
	// 			} else {
	// 				localstate.itemdata.RunDailyStats = 0;
	// 			}
	// 		}

	// 		if (itemkey === "SomeBoolean") {
	// 			if (event.target.checked) {
	// 				localstate.itemdata.SomeBoolean = 1;
	// 			} else {
	// 				localstate.itemdata.SomeBoolean = 0;
	// 			}
	// 		}


		
	// 		//All others
	// 		if (itemkey === "Name" || itemkey === "LastName" || itemkey==="Date" || itemkey==="Mfg" || itemkey==="PartsLocation") {
	// 			localstate.itemdata[itemkey] = newvalue;
	// 		}
	// 		localstate.pendingsaves = true;
	// 		btnSave.current.style.display="none";
	// 		btnPendingSave.current.style.display="";
	// 		UpdateState(localstate);
	// 	}

	// }



	// //Try new method to target what is sent from a datetime picker:
	// const onChangeDatetimeValue = (newvalue, itemkey) => {
	// 	localstate.itemdata[itemkey]=newvalue;
	// 	localstate.pendingsaves = true;
	// 	btnSave.current.style.color = "white";
	// 	btnSave.current.style.backgroundColor = "#01579B";
	// 	UpdateState(localstate);

	// }

	//Product types Autocomplete
	//Autocomplete Simple
	//Example: Products
	const [openproducttypes, openProductTypes] = React.useState(false);
	const [producttypes, setProductTypes] = React.useState([]);
	const [loadingproducttypes, setLoadingProductTypes] = useState(false);
	const [producttypesearchterm, setProductTypeSearchTerm] = useState("Default Value");
	//For loading single items that do have a value, use LoadItem function in conjunction with: setProductSearchTerm(res.data.item.product.Name);
	const InitProductTypes = () => {
		if (producttypes.length===0){
			ProductTypeSearch("");
		}
		openProductTypes(true);
	}

	const onChangeProductType = (event, newvalue) =>{
		localstate.itemdata.ProductType = newvalue.Name;
		localstate.pendingsaves = true;
		btnSave.current.style.color = "white";
		btnSave.current.style.backgroundColor = "#01579B";
		UpdateState(localstate);
	}

	const ProductTypeSearch = debounce(function(searchvalue){
		setProductTypeSearchTerm(searchvalue);
		setLoadingProductTypes(true);
		const postdata = {					
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/products/getproducttypes", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
					setProductTypes(res.data);
			} else {
				//Non-200 message from server.
				errors.NewError({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"})
			}
			setLoadingProductTypes(false);
		});
	},600);

	const GoToProductsTable = () => {
		history.push('/products');
	}

	



	/* ##########################  Render Function  ########################## */
	return (
		<>
			<div style={{ padding: "8px", minWidth: "750px", maxWidth: "1400px", textAlign: "center", margin: "auto", marginBottom: "100px" }} ref={printRef}>
			<Typography variant="h6">
				<ProductDetails handlePrint={handlePrint}/>
				<ProductStatistics />
				<ProductHistory />
				{((userPerms.readProductMarketingDoc === 1 || userPerms.readProductTechDoc === 1) && !localstate.dbreload) &&
					<>
						<div className={classes.itemtableheader}>Documents</div>
						<Grid container spacing={1}>
							{/* Grid for Notes (sales and tech) */}
							{(userPerms.readProductMarketingDoc === 1) &&
								<Grid item sm={12} md={12} lg={(userPerms.readProductMarketingDoc === 0 || userPerms.readProductTechDoc === 0 ? 12 : 6)}>
									<div style={{ backgroundColor: "#FFF" }}>
										{/* This will determine the max width of the document itself!? */}
										<div style={{ textAlign: "center", maxWidth: "1200px", margin: "10px auto", border: "1px solid #EEE", borderRadius: "5px" }}>
											<FlexDocument
												itemid={localstate.itemdata.ProductID}
												documentid={localstate.itemdata.MarketingDocID}
												title={"Product Marketing: "+localstate.itemdata.Name} //This sends a usable title to be saved in the document system
												documenttype={"product"} //Allows traversal to parent /product/{itemid}
												allownew={true}
												documentendpoint={'/products'}
												dbkey={"MarketingDocID"}
												userPerms={{
													create: userPerms.createProductMarketingDoc,
													read: userPerms.readProductMarketingDoc,
													update: userPerms.updateProductMarketingDoc,
													delete: userPerms.deleteProductMarketingDoc
												}}
												readOnly={true} //This true, breaks dragdrop
												tools={{
													header: true,
													list: true,
													image: true,
													table: true,
													checklist: true,
													dragdrop: true, //This breaks if you start off with readOnly=true from props
													alert: true,
													toggle: true,
													code: true,
													color: false, //Disabled until plugin 2.0.4 is updated to something working.
													marker: false, //Disabled until plugin 2.0.4 is updated to something working.
													raw:true
												}}
												addbtntext={"Add Marketing Notes"}
												editbtntext={"Edit Marketing Notes"} //Document, Notes, etc
												savebtntext={"Save Notes"}
												header={"Marketing Notes"}
												introduction={"Marketing notes can facilitate sales goals, strategies, pricing, and remind others of potential pitfalls."}
												showupdateinfo={true}
											/>
										</div>
									</div>
								</Grid>
							}

							{(userPerms.readProductTechDoc === 1) &&
								<Grid item sm={12} md={12} lg={(userPerms.readProductMarketingDoc === 0 || userPerms.readProductTechDoc === 0 ? 12 : 6)}>
									<div style={{ backgroundColor: "#FFF" }}>
										{/* This will determine the max width of the document itself!? */}
										<div style={{ textAlign: "center", maxWidth: "1200px", margin: "10px auto", border: "1px solid #EEE", borderRadius: "5px" }}>
											<FlexDocument
												itemid={localstate.itemdata.ProductID}
												documentid={localstate.itemdata.TechDocID}
												title={"Product Tech: "+localstate.itemdata.Name} //This sends a usable title to be saved in the document system
												documenttype={"product"} //Allows traversal to parent /product/{itemid}
												allownew={true}
												documentendpoint={'/products'}
												dbkey={"TechDocID"}
												userPerms={{
													create: userPerms.createProductTechDoc,
													read: userPerms.readProductTechDoc,
													update: userPerms.updateProductTechDoc,
													delete: userPerms.deleteProductTechDoc
												}}
												readOnly={true} //This true, breaks dragdrop
												tools={{
													header: true,
													list: true,
													image: true,
													table: true,
													checklist: true,
													dragdrop: true, //This breaks if you start off with readOnly=true from props
													alert: true,
													toggle: true,
													code: true,
													color: false, //Disabled until plugin 2.0.4 is updated to something working.
													marker: false, //Disabled until plugin 2.0.4 is updated to something working.
													raw:true
												}}
												addbtntext={"Add Tech Notes"}
												editbtntext={"Edit Tech Notes"} //Document, Notes, etc
												savebtntext={"Save Notes"}
												header={"Tech Notes"}
												introduction={"Helpful notes on disassembly, repairs, and  details concerning parts."}
												showupdateinfo={true}
											/>
										</div>
									</div>
								</Grid>
							}
							
							
						</Grid>
					</>
				
				}
				</Typography>
			</div>
		</>
	)
}

export default ProductView;
