// Record History
// To Do: Rewrite this will new grid format....

//Give all sorts of options....

import React from 'react';

//Datetime formatting
import Moment from 'react-moment';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

const RecordHistory = (props) => {
	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	let recordhistory = props.recordhistory;
	let columns = props.columns;
	let historytitle = props.historytitle;

	return (
		<React.Fragment>
			{(recordhistory) &&
				<>
					<div className={classes.itemtableheader}>{historytitle}</div>
					<div style={{ padding: "0px", width: "100%" }}>
						<table className={classes.simpletable} style={{ textAlign: "left" }}>
							<thead>
								<tr>
									{columns.map((column, columnindex) => (
										<td key={"column" + columnindex} style={column.style}>
											{column.columnname}
											</td>
									))}
								</tr>
							</thead>
							<tbody>
								{recordhistory.map((historyitem, itemindex) => (
									<tr key={"rowindex"+itemindex}>
										{columns.map((column, columnvalueindex) => (
											<td key={"columnvalues" + columnvalueindex} style={column.style}>
												{(column.type === "friendlydate") &&
													<Moment element='span' format="MMMM D, YYYY [at] h:mma">{historyitem[column.dbkey]}</Moment>
												}
												{(column.type === "text") &&
													<>
														{historyitem[column.dbkey]}
													</>
												}
											</td>
										))}
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</>
			}


		</React.Fragment>

	)
}


export default RecordHistory;