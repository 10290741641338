//Restrict Number Function
//Passes newvalue, oldvalue, event from user input, and type of number we'd like to restrict
const RestrictInputNumber = (newvalue, oldvalue, event, type) => {

	function hasLeadingZeros(input) {
		return /^0\d+/.test(input);
	  }


	//In some cases, backspace makes newvalue blank. Can we assume 0?
	if (newvalue.length===0){
		newvalue="0";
	}
	//console.log(event);
	console.log("New Value: "+event.target.value+"        Old Value: "+oldvalue);
	console.log("Simple Test: "+(parseFloat(newvalue)===parseFloat(oldvalue)));
	console.log("Event Value: "+event.target.value);
	console.log("Event Key: "+event.key);
	
	if (type==="float"){
		var len = newvalue.length;
		var index = newvalue.indexOf('.');

		//Check for blank input:
		if (event.target.value===""){
			console.log("Blank value, returning 0.");
			return "0"; //This currently fumbles the input... I believe entering a 0 and putting the cursor at the front of the element.
		}


		//Attempt to ignore decimal
		if (event.key === "." || event.key === "Period" || event.key === "NumpadDecimal") {
			console.log("Decimal key detected in Restrict Number");
			console.log("Returning false.");
			return false;
		}


		//If a backspace was pressed and the values are the same, return false
		if (event.key === "Backspace" && parseFloat(event.target.value) === parseFloat(oldvalue)) {
			console.log("Backspace key detected! Values appear to be the same!");
			return false;
		}


		//Check if we're past 2 decimal places.
		if (index > -1) {
			if ((len - 1) > (index + 2)) {
				//We are out past 2 decimals!
				console.log("Past 2 decimal places, returning oldvalue: "+oldvalue);
				return oldvalue;
			}
		}

		//In some cases a new value can't be parsed as a number and so event.target.value evaluates to ""  Example: 15-15.25 (see the minus in there?)
		if (event.target.value===""){
			return "0";
		}

		//Format the number if it's greater than 1, but has leading zeros..
		if (parseFloat(event.target.value)>=1 && hasLeadingZeros(event.target.value)){
			if (Number.isInteger(parseFloat(event.target.value))){
				return parseInt(event.target.value);
			} else {
				return parseFloat(event.target.value).toFixed(2);
			}
		} else {
			//Everything else looks OK? Return false.
			return false;
		}

		console.log("Returning newvalue:"+ newvalue);
		//This should remove leading zeros.
		
		
	}

	//In Progress:
	if (type==="positiveinteger"){
		//Check for negative or blank input:
		if (newvalue <0 || event.target.value===""){
			console.log("Negative or no value at all. Returning 0");
			return "0"; //This currently fumbles the input... I believe entering a 0 and putting the cursor at the front of the element.
		}

		//If a decimal is pressed, let's see if we can kind of ignore it.
		if (event.key === "." || event.key === "Period" || event.key === "NumpadDecimal") {
			console.log("Decimal key detected in Restrict Number");
			//Decimals not allowed in integers.
			//In some cases, parseInt will evaluate as 0 which will be falsy! Try sending a string "0".
			const returnvalue = parseInt(event.target.value);
			if (!returnvalue){
				return "0";
			} else {
				return returnvalue;
			}
		}

		//If a backspace was pressed and the values are the same, return false
		if (event.key === "Backspace" && parseFloat(event.target.value) === parseFloat(oldvalue)){
			console.log(parseFloat(event.target.value));
			console.log(parseFloat(oldvalue));

			console.log("Backspace! And no change in value!");
			return false; //All OK, no flag to change value.
		}

		//For integers, we should never have a leading zero under any circumstances.
		if (hasLeadingZeros(event.target.value)){
			return parseInt(event.target.value);
		}

		//Default False, no change needed to value because none of the rules above short-circuited to a new value to return
		return false;
	}
	
}


export default RestrictInputNumber;