//Boilerplate Table

/* ##########################  Configuration Sections  ########################## */	
//## Configuration Section
//## UseState Variables
//## Column States
//## Column Configuration
//## Column Toggles
//## Row Design
//## Search Inputs
//## Button Functions


/* ##########################  Notes on Configuration  ########################## */	
// Edit Mode: Controls if we render inputs on the grid (renders take longer). Edit mode button disabled if more than 100 (configurable on button) items.

//Warnings like "Each child in a list should have a unique key prop": Check that your table row IDs match: row.ID might need to be row.InternalID or row.InternalOrderID, etc.


//			"Text nodes cannot appear as a child of <tbody>." This can happen for conditional rows via the map function. The internet recommends a ternary with a null for false.
// {(localstate.orders.length>0) ?
// 	localstate.orders.map((order, orderindex) => {
// 		return (
// 			order.orderitems.map((orderitem, itemindex) => {
// 				return (
// 					<tr>
// 						<td>marketplaceholder</td>
// 						<td>{orderitem.Code}</td>
// 						<td>{orderitem.UnitPrice}</td>
// 					</tr>
// 				)
// 			})
// 		)
// 	})
// : null}


/* ##########################  Notes on Errors  ########################## */	

//Error like this: Attempted import error: 'DBAutoComplete' is not exported from '../common/DBAutoComplete' (imported as 'DBAutoComplete').      
//Solution: Import without curly braces?


//Fun Tricks:
//In JSX, you can use questionsmarks like: 
		// {(localstate.itemdata.addedminorconditions?.length > 0) &&
		// }
//The questionmark isn't a typo, I think this can check for properties instead of triggering an error when it's not given by DB
//Sorta is/replaced hasOwnProperty?
//This also magically works with the map function - needs further verification and inspection:
		// {localstate.itemdata.addedminorconditions?.map((condition) => (
		// ));

//You can use ternary for things like class= and onClick= like this:
		// className={(someexpression) ? classes.thing : classes.thong}
		// onClick={() => (localstate.itemdata.NextID) ? LoadFromHistory(localstate.itemdata.NextID):null}

//You can use length of items to calculate height for rows! (this one checks if it's an expanded row, then multiplies the items!)
		//style={{height:(row.ExpandRow ? (row.orderitems.length)*37+"px" : "37px")}}>


//React & Friends
import React, { useState, useEffect, useContext, useRef } from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";
import PropTypes from 'prop-types';
//import cloneDeep from 'lodash/cloneDeep';

//Redux Features
import { useSelector, useDispatch } from 'react-redux';
//ProgressBar
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import { ProgressBar } from '../../features/progressbar/ProgressBar';
import {
	newProgress,
	incrementPass,
	incrementFail,
	setProgressTimeout
  } from '../../features/progressbar/progressbarSlice';
//Main Menu
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';
//New Error Message
import { NewErrorMessage } from '../../features/error/NewErrorMessage';
import {
	newErrorMessage,
	setErrorTimeout
} from '../../features/error/errormessageSlice';


//Contexts
//AuthContext
import { AppContext } from "../Auth/contexts/AppContext"
//Error Context - warning, danger, ok, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';
import { useMediaQuery } from "@mui/material";

//Datetime formatting
import Moment from 'react-moment';
import 'moment-timezone';
import dayjs from 'dayjs'; //Used with new datetimepickers
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime

//Common Utilities
import SearchInput from "../common/SearchInput"; //Don't forget localization adapter and switch to DayJS!
import RestrictInputNumber from "../common/RestrictInputNumber";
import DBAutoComplete from '../common/DBAutoComplete';

//MaterialUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//Tables
//import TablePagination from '@mui/material/TablePagination';
import FlexTablePagination from '../common/FlexTablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

//Icons
import Chip from '@mui/material/Chip';
import PreviewIcon from '@mui/icons-material/Preview';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AddIcon from '@mui/icons-material/Add';

//Datetime Pickers
//Try new moment adapter:
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

//Export
import ExportCSV from '../common/ExportCSV';


/* ##########################  Configuration  ########################## */

//DB
//Old: var dbendpoint = process.env.REACT_APP_DB_HOSTNAME;
var dbendpoint = process.env.REACT_APP_DB_API4;
var hostbase = process.env.REACT_APP_BASE;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials:true,
	withXSRFToken: true,
	crossDomain:true,
	mode:"no-cors",
	timeout:11800,
};

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
//Often used in list text inputs (skus, itemids, serials)
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function(acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	  }, []);
	return results;
}
//Example:
const input = [1,1,2,3,3];
const dupearray = GetDupeArray(input);
//console.log("Duplicates: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}

//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};


const PurchasingTable = (props) => {
	document.title="Purchasing";
	let history = useHistory();
	const dispatch = useDispatch();
	dispatch(setCurrentMenuSection("Inventory"));
	dispatch(setCurrentMenuItem("/purchasing"));

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);
	const isPrintView = useMediaQuery("print");

	//Refs
	const rowRefs = useRef([]);

	/* ##########################  Top Button Refs and Functions  ########################## */	 
	//Buttons must exist in order to disable or enable them. Hide buttons with hidden class and ternary.
	const btnSave = useRef();
	const btnPendingSave = useRef();
	const btnAddRow = useRef();
	const btnAddItem = useRef();
	const btnDeleteSelected = useRef();
	const btnExpandAll = useRef();
	const btnResetSearches = useRef();
	const btnEditSelected = useRef();
	const btnExport = useRef();
	const btnUtilityDrawer = useRef();
	const btnItemList = useRef();
	const btnPrintLabels = useRef();
	const btnEditMode = useRef();

	//Disable Buttons
	const DisableButtons = () => {
		// btnSave.current.setAttribute("disabled", true);
		// btnPendingSave.current.setAttribute("disabled", true);
		btnAddRow.current.setAttribute("disabled", true);
		btnAddItem.current.setAttribute("disabled", true);
		btnDeleteSelected.current.setAttribute("disabled", true);
		btnExpandAll.current.setAttribute("disabled", true);
		btnResetSearches.current.setAttribute("disabled", true);
		btnEditSelected.current.setAttribute("disabled", true);
		btnExport.current.setAttribute("disabled", true);
		btnUtilityDrawer.current.setAttribute("disabled", true);
		btnItemList.current.setAttribute("disabled", true);
		btnPrintLabels.current.setAttribute("disabled", true);
		btnEditMode.current.setAttribute("disabled", true);
	}

	//Enable Buttons
	const EnableButtons = () => {
		// btnSave.current.removeAttribute("disabled");
		// btnPendingSave.current.removeAttribute("disabled");
		btnAddRow.current.removeAttribute("disabled");
		btnAddItem.current.removeAttribute("disabled");
		btnDeleteSelected.current.removeAttribute("disabled");
		btnExpandAll.current.removeAttribute("disabled");
		btnResetSearches.current.removeAttribute("disabled");
		btnEditSelected.current.removeAttribute("disabled");
		btnExport.current.removeAttribute("disabled");
		btnUtilityDrawer.current.removeAttribute("disabled");
		btnItemList.current.removeAttribute("disabled");
		btnPrintLabels.current.removeAttribute("disabled");
		btnEditMode.current.removeAttribute("disabled");
	}

	//Reusable test to be used with useState bool variables
	//Invalid for none selected or pending changes
	//Bulk Edit Selected
	//Export Selected
	//Print Selected
	const RejectIfInvalidSelected = (value, fnCallback) => {
		if (localstate.selectedindexes.length===0){
			dispatch(newErrorMessage({errmsg:"No items selected.", errshow:true, errtimeout: 5, errtype:'neutral'}));
			dispatch(setErrorTimeout(5));
		} else {
			//If all tests pass, use callback
			fnCallback(value);
		}
	}

	//Edit Mode
	const [editmode, setEditMode] = useState(false); //Default: false
	const EditMode = () => {
		setEditMode(!editmode);
		CloseViewOptionsMenu();
	}

	/* ##########################  Menus  ########################## */

	const ToggleColumn = (key) => {
		let newcolstate = colstate;
		newcolstate[key] = ! newcolstate[key];
		UpdateColState(newcolstate);
	}
	const FlexColumnOption = (props) => {
		let columnvalue = props.value;
		return (
			<React.Fragment>
				{(colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox defaultChecked onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover} />
				}
				{(!colstate[columnvalue]) &&
					<FormControlLabel control={<Checkbox onClick={()=>{ToggleColumn(columnvalue)}} />} label={props.label} className={classes.columnselecthover}/>
				}
			</React.Fragment>
		)
	}

	/* View Options Menu */
	const [showViewOptionsMenu, setViewOptionsMenu] = useState(null);
	const ShowViewOptionsMenu = (event) => {
		setViewOptionsMenu(event.currentTarget); 
	}
	const CloseViewOptionsMenu = () => {
		setViewOptionsMenu(null);
	}

	/* Edit Menu */
	const [showViewEditMenu, setViewEditMenu] = useState(null);
	const ShowViewEditMenu = (event) => {
		setViewEditMenu(event.currentTarget); 
	}
	const CloseViewEditMenu = () => {
		setViewEditMenu(null);
	}
	

	/* Export Menu */
	const [showexportmenu, setExportMenu] = useState(null);
	const ShowExportMenu = (event) => {
		setExportMenu(event.currentTarget);
	}
	const CloseExportMenu = () => {
		setExportMenu(null);
	}


	/* ##########################  Selected Rows  ########################## */
	//Explanation: Since we're using <Checkbox> from materialui in an uncontrolled manner (avoiding state, avoiding rerenders...)
	//we are assuming the only way to show a "checked" value of the checkbox is by a user clicking the actual checkbox.

	//Simply using rowRefs.current[index+"Checkbox"].checked can be set to "true", but the view WILL NOT REFLECT THE CHANGE.
	//So for manual clicks on <Checkbox> we register in localstate - to be used later and maintained by UpdateState(localstate).
	//For SelectAll, we have to run through all items and set isSelected, then rerender.
	//Non-direct interaction with checkbox components will result in difficulty trying to access it through some kind
	//of ref/DOM manipulation. Therefore we pass changes to these components through state changes, mutating localstate.

	const SelectRow = (index) => {
		console.log("Selected index: "+index);
		if (localstate.selectedindexes.indexOf(index)===-1){
			localstate.selectedindexes.push(index);
			//Check for condition that would check Select All Checkbox - Requires Rerender
			if (localstate.griditems.length === localstate.selectedindexes.length){
				UpdateState(localstate);			
			}
		} else {
			var spliceindex = localstate.selectedindexes.indexOf(index);
			localstate.selectedindexes.splice(spliceindex,1);
			//Check for condition that would un-check Select All Checkbox, just 1 less will do - Rerender
			if (localstate.griditems.length === (localstate.selectedindexes.length+1)){
				UpdateState(localstate);			
			}
		}

		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length===0){
			setShowExportConfirmation(false);
		}
		console.log("End of SelectRow()");
		console.log(localstate.selectedindexes);
		UpdateState(localstate);
	}

	const handleSelectAllClick = (event) => {
		//Material UI Checkbox Component won't rerender unless we force it. Set a changed GridKey so that shallow comparison fails.
		var i=0;
		if (event.target.checked) {
			localstate.selectedindexes = [];
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = true;
				localstate.selectedindexes.push(i);
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		} else {
			localstate.selectedindexes = [];
			localstate.selectedcount = 0;
			for (i=0; i<localstate.griditems.length; i++){
				localstate.griditems[i].isSelected = false;
				localstate.griditems[i].GridKey++;
			}
			UpdateState(localstate);
		}
		//Provision to close export if nothing is selected
		if (localstate.selectedindexes.length===0){
			setShowExportConfirmation(false);
		}
	};



	/* ##########################  Search Options  ########################## */


	// Returns a function, that, as long as it continues to be invoked, will not
	// be triggered. The function will be called after it stops being called for
	// N milliseconds. If `immediate` is passed, trigger the function on the
	// leading edge, instead of the trailing.
	function debounce(func, wait, immediate) {
		var timeout;
		return function () {
			var context = this, args = arguments;
			var later = function () {
				timeout = null;
				if (!immediate) func.apply(context, args);
			};
			var callNow = immediate && !timeout;
			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	};



	/* ##########################  CONFIGURATION SECTION  ###################################################################################################################################################### */ 
	/* ######################################################################################################################################################################################################### */ 
	/* Try to keep all the configurable options below and the static, typically unchanged stuff above. */ 

	// const params = new URLSearchParams(useLocation().search);
	// var nameparameter = params.get('Name');
	// if (!nameparameter){nameparameter=""};

	//Container and Key Sizes
	const containersizes="260px";
	const keysizes="130px";

	//Search Configuration:
	const activesearchescount = 4;

	//Search Select Inputs
	const defaultselectinputs = [
		{value:"LotName", text:"Lot Name"},
		{value:"vendors", text:"Vendor"}, //Autocomplete
		{value:"Description", text:"Description"},
		{value:"Complete", text:"Complete"},
		{value:"ReceivedAfter", text:"Received After"},
		{value:"ReceivedBefore", text:"Received Before"},
		{value:"PaidAfter", text:"Paid After"},
		{value:"PaidBefore", text:"Paid Before"},
	];

	
	//Set Input Defaults here so we can used them for useState and ResetSearches:
	const inputdefaults = {
		LotName:{
			type:"LotName",
			value: "",
			mode:"like",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		//Vendors AutoComplete
		vendors:{
			type:"vendors",
			keyedby:"VendorID", //We'll need a key so that Autocomplete can highlight our value in the drop down after selection
			searchkey:"VendorName", //This is the column we are searching from the table
			value: [], 
			multiple:true,
			//inputValue:"", //Text Search! This *CAN* be used... but seems nicer without it!
			mode:"autocomplete", 		
			defaultsearchterm:"",
			limit:10, //Database return limit, keep below ~100
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		Description:{
			type:"Description",
			value: "",
			mode:"like",
			uuid:uuidv4(),			
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},

		Complete:{
			type:"Complete",
			value: "",
			mode:"bool",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		//Standard DateTimeAfter

// UPDATE flexlots
// SET DateTimeReceived = DateReceived
// WHERE DateReceived NOT LIKE '%Z';

// 		UPDATE flexlots
// SET DateTimeReceived = CONVERT_TZ(
//     REPLACE(SUBSTRING_INDEX(DateReceived, '.', 1), 'T', ' '), 
//     'UTC', 'America/New_York'
// )
// WHERE DateReceived LIKE '%Z';


ReceivedAfter:{
			type:"ReceivedAfter",
			value: (() => {
				return dayjs().subtract(10,'year');

			})(),
			mode:"datetimeafter",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		//Standard DateTimeBefore
		ReceivedBefore:{
			type:"ReceivedBefore",
			value:dayjs(), //new Date, 
			mode:"datetimebefore",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		//Standard DateTimeAfter
		PaidAfter:{
			type:"PaidAfter",
			value: (() => {
				return dayjs().subtract(10,'year');

			})(),
			mode:"datetimeafter",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
		//Standard DateTimeBefore
		PaidBefore:{
			type:"PaidBefore",
			value:dayjs(), //new Date, 
			mode:"datetimebefore",
			uuid:uuidv4(),
			container:containersizes,
			keysize: keysizes,
			grow:1,
		},
	}



	/* ##########################  UseState Variables  ######################################################################################################################################################### */ 
	/* ######################################################################################################################################################################################################### */ 
	const [state, setState] = useState({
		dbreload:true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		clearselection:true,//Default clear selection everytime we reload DB. Continuous bulk edits may set this to false between updates.
		griditems:[],		//Empty object
		defaultvalues:[],   //Default values: Useful for initial render.
		totalitems:0,
		page:0, //Assume page 0, or else pagination throws an error.
		order:"desc",
		orderby:"DateTimeReceived",
		selectedcount:0,
		rowsperpage:50,
		maxrowseditmode:10,
		rowsperpageoptions:[50,100,250],
		selectedindexes:[],
		pendingsaves:false, //Used for parent view - Warnings about unsaved items!
		expandsearch:false, //Used to control optional add/remove search button that shows within input.
		searchoptions:{
			// SEARCH PAIRS: Works with common get() functions on db endpoints. Works with SearchTools to create queries.
			// Also integrates with React <SearchInput /> functional component which can also take parameters for AutoComplete.
			//Modes: like, left, right, strict, not, bool, datetimeafter, datetimebefore, select
			//Remember to setup a <SearchInput /> container for each of these.
			searchpairs: {
				//Be sure to adjust activesearchescount
				searchpair1: inputdefaults.LotName,
				searchpair2: inputdefaults.vendors,
				searchpair3: inputdefaults.Description,
				searchpair4: inputdefaults.Complete,
				searchpair5: inputdefaults.ReceivedAfter,
				searchpair6: inputdefaults.ReceivedBefore,
				searchpair7: inputdefaults.PaidAfter,
				searchpair8: inputdefaults.PaidBefore
			},
			//Optional
			nestedrelationships:{
				
			},
			itemlist:""
		}
	});
	//Clone state into localstate for in-between render storage.
	//Shallow copy
	let localstate = Object.assign({}, state); 


	//Deep Copy:
	//I don't think this mattered for some issue and in the end, I don't want to change my other controllers. If all goes well, get rid of this area.
	//const localstate = cloneDeep(state);

	function UpdateState(stateobject) { 
		setState(stateobject);
	}

	/* ##########################  Column States - ColStates  ################################################################################################################################################## */	 
	/* ######################################################################################################################################################################################################### */ 
	//Used for hiding/showing columns. Can access using bracket notation later on! colstate[headCell.id]
	const [colstate, setColState] = useState({
		Status:true,
		LotName:true,
		Complete:true,
		Description:true,
		TotalSkids:true,
		Vendor:true,
		created_at:true,
		DateTimeReceived:true,
		DatePaymentPosted:false,
		InvoiceNumber:false,
		InvoiceTotal:false,
		PaymentStatus:false,
		PaymentMethod:false,
		DamageTotal:false,
		CreditRequestTotal:false,
		CreditReceivedTotal:false
	});
	const UpdateColState = (colstate) =>{
		setColState(colstate);
	}

	//Each SearchInput will need a key that is refreshed to force a re-render.
	//Required to drive the control of the SearchInput from the parent.
	const [key1, setKey1] = useState(uuidv4());
	const [key2, setKey2] = useState(uuidv4());
	const [key3, setKey3] = useState(uuidv4());
	const [key4, setKey4] = useState(uuidv4());
	const [key5, setKey5] = useState(uuidv4());
	const [key6, setKey6] = useState(uuidv4());
	const [key7, setKey7] = useState(uuidv4());
	const [key8, setKey8] = useState(uuidv4());


	//Used for <SearchInput />
	const onChangeSearchType = (searchpair, searchnumber) => {
		//Only rerender table if searchvalue wasn't blank!
		console.log("Typeof: "+ typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value);
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'object'){
			console.log("Object");
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value.length>0){
				ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'string'){
			console.log("String");
			if (localstate.searchoptions.searchpairs["searchpair" + searchnumber].value!==""){
				ResetPendingSaves();
				localstate.page = 0;
				localstate.dbreload = true;
			}
		}
		if (typeof localstate.searchoptions.searchpairs["searchpair" + searchnumber].value === 'number'){
			//Doesn't matter, 0 or 1, we still need to allow this bool to clear
			console.log("number");
			ResetPendingSaves();
			localstate.page = 0;
			localstate.dbreload = true;
		}
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
		//Ensures the input is rerendered and will work correctly:
		ResetKeys([searchnumber], false);
	};

	const onChangeSearchValue = debounce(function (searchvalue, searchpair, searchnumber) {
		//Clears out changes because table is about to be reloaded:
		ResetPendingSaves();
		//Possible search validation here such as proper date format or int/float...
		//For AutoComplete, you will likely need to pull some kind of ID or name before setting the searchvalue!		
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		localstate.searchoptions.searchpairs["searchpair" + searchnumber].value = searchvalue;
		localstate.page = 0;
		localstate.dbreload = true;
		UpdateState(localstate);
	}, 1200);

	//AutoComplete Still needs to send an update so that our inputValue can remain between multiple selections:
	const onChangeAutoCompleteInput = (searchpair, searchnumber) => {
		localstate.searchoptions.searchpairs["searchpair" + searchnumber] = searchpair;
		UpdateState(localstate);
	};

	const ResetSearches = () => {


		history.push('/purchasing');


		// //Reset all:
		// localstate.searchoptions.searchpairs.searchpair1 = inputdefaults.LotName;
		// localstate.searchoptions.searchpairs.searchpair2 = inputdefaults.vendors;
		// localstate.searchoptions.searchpairs.searchpair3 = inputdefaults.ReceivedAfter;
		// localstate.searchoptions.searchpairs.searchpair4 = inputdefaults.ReceivedBefore;
		// localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.LotName;
		// localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.LotName;
		// localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.LotName;
		// localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.LotName;
	
		// //Set Defaults:
		// setItemListSearch('');
		// localstate.dbreload = true;
		// UpdateState(localstate);

		// //Forcefully rerender search inputs!
		// ResetKeys([], true);
	}

	const ResetKeys = (keyarray, resetallbool) =>{
		if (resetallbool){
			setKey1(uuidv4());
			setKey2(uuidv4());
			setKey3(uuidv4());
			setKey4(uuidv4());
			setKey5(uuidv4());
			setKey6(uuidv4());
			setKey7(uuidv4());
			setKey8(uuidv4());
		} else {
			for (var i=0; i<keyarray.length; i++){
				if (keyarray[i]===1){
					setKey1(uuidv4());
				}
				if (keyarray[i]===2){
					setKey2(uuidv4());
				}
				if (keyarray[i]===3){
					setKey3(uuidv4());
				}
				if (keyarray[i]===4){
					setKey4(uuidv4());
				}
				if (keyarray[i]===5){
					setKey5(uuidv4());
				}
				if (keyarray[i]===6){
					setKey6(uuidv4());
				}
				if (keyarray[i]===7){
					setKey7(uuidv4());
				}
				if (keyarray[i]===8){
					setKey8(uuidv4());
				}
			}
		}
	}


	//Expand Search
	const ToggleExpandSearch = () => {
		localstate.expandsearch = !localstate.expandsearch;

		if (!localstate.expandsearch){
			for (var i=5; i<=activesearchescount; i++){
				//Only rerender if one of the searches wasn't blank!
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'object'){
					if (localstate.searchoptions.searchpairs["searchpair" + i].value.length>0){
						ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
				if (typeof localstate.searchoptions.searchpairs["searchpair" + i].value === 'string'){
					console.log(localstate.searchoptions.searchpairs["searchpair" + i].value);
					if (localstate.searchoptions.searchpairs["searchpair" + i].value!==""){
						ResetPendingSaves();
						localstate.page = 0;
						localstate.dbreload = true;
					}
				}
			}
			//Proceed to Reset Partial Searches 5-8:
			localstate.searchoptions.searchpairs.searchpair5 = inputdefaults.ReceivedAfter;
			localstate.searchoptions.searchpairs.searchpair6 = inputdefaults.ReceivedBefore;
			localstate.searchoptions.searchpairs.searchpair7 = inputdefaults.PaidAfter;
			localstate.searchoptions.searchpairs.searchpair8 = inputdefaults.PaidBefore;
		}
		CloseViewOptionsMenu();
		UpdateState(localstate);
	}

	//Add Item
	//Method creates a new item in the DB and returns the ID so we can then load it in item view
	const AddItem = () => {
		axios.post(dbendpoint + "/purchasing/addpurchase", defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === 'login') {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status === 'Success') {
					window.open('/purchasing/' + res.data.item.LotID);
				}
				if (res.data.Status === 'Failure') {
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: 'neutral' })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: 'warning' })
			}
		});
	}

	// //Customize AddRow - To Disable
	// const AddRow = () => {
	// 	console.log(localstate.defaultvalues);
	// 	localstate.griditems.unshift({
	// 		PendingItem:true, //New Row adds property 'PendingItem' for use in the API to add such rows.
	// 		ID:uuidv4(),
	// 		Name:"New Item", // Set this value as something that can't be taken into SaveChanges
	// 		Cost:0.00,
	// 		Price:0.00,
	// 		Margin:0.00,
	// 		SomePositiveInteger:0,
	// 		SomeBoolean:0,
	// 		SomeSelectable:"",
	// 		NewCheckbox:false,
	// 		Date:new Date().toISOString() //IS UTC String
	// 	});
	// 	//All selected indexes move up by 1.
	// 	for (var i=0; i<localstate.selectedindexes.length; i++){
	// 		localstate.selectedindexes[i] += 1;
	// 	}
	// 	UpdateState(localstate);
	// }




	/* ##########################  Configuration: Custom Views & Functions  ########################## */	 
	
	const SetCustomView1 = () =>{
		//Applies a few as set to true.
		let newcolstate = colstate;
		newcolstate['Name'] = true;
		newcolstate['Cost'] = true;
		newcolstate['Margin'] = true;
		UpdateColState(newcolstate);
		CloseViewOptionsMenu();
	}



	/* ##########################  END OF CONFIGURATION SECTION  ########################## */ 
	/* Continue setting up app with the configuration variable set */ 




	/* ##########################  Loading and Page Changes  ########################## */
	const handleRequestSort = (event, property) => {
		const isAsc = localstate.orderby === property && localstate.order === "asc";		
		localstate.order = (isAsc ? "desc" : "asc");
		localstate.orderby=property;
		localstate.dbreload=true;
		UpdateState(localstate);
	};

	const handleChangePage = (event, newPage) => {
		localstate.dbreload = true;
		localstate.page = newPage;
		UpdateState(localstate);		
		//
	};

	const handleChangeRowsPerPage = (event) => {
		localstate.dbreload = true;
		localstate.rowsperpage = parseInt(event.target.value, 10);
		localstate.page=0;
		UpdateState(localstate);
	};

	//Error Context
	const errors = useContext(ErrorContext);

	//Load Items
	function LoadItems(){
		if (localstate.clearselection){
			localstate.selectedindexes = [];
			localstate.selectedcount=0;
		} else {
			//Reset to clear selections on subsequent requests
			localstate.clearselection=true;
		}

		//Prepare Searchpairs - You may need to follow this pattern in PrepareExport()
		//Need to DEEP clone:
		var clonedsearchpairs = JSON.parse(JSON.stringify(localstate.searchoptions.searchpairs));
		//Create new set which will only have up to activesearchescount searchpairs added.
		var newsearchpairs ={};

		//AUTOCOMPLETE AND SELECT Handler
		//The SearchTools controller will treat any search with multiple=true as an ormode with merged array values. 
		for (var i = 1; i < (activesearchescount + 1); i++) {
			newsearchpairs["searchpair" + i] = clonedsearchpairs["searchpair" + i];
			//Products Autocomplete
			if (newsearchpairs["searchpair" + i].mode === "autocomplete" && newsearchpairs["searchpair" + i].type === "products") {
				newsearchpairs["searchpair" + i].type = "ProductID";
				//Note: You may change the "mode" and possibly rely on SearchTools to handle an array (if detected!)
				//We don't normally change the mode, instead allowing our TableController to handle special auto-completed values, normally relationship based values.
				//To simplify same-layer table values such as "ProductID", we might leverage our already working ormode code to handle an array value!
				//Test a new non-short circuited ProductID search using mode="like" in SkusTable.js
			}
			//.... More Rules
			//Some select mode items may be converted to "like"

			if (newsearchpairs["searchpair" + i].type === "PaidAfter" || newsearchpairs["searchpair" + i].type === "PaidBefore") {
				newsearchpairs["searchpair" + i].type = "DatePaymentPosted";
			}

			if (newsearchpairs["searchpair" + i].type === "ReceivedAfter" || newsearchpairs["searchpair" + i].type === "ReceivedBefore") {
				newsearchpairs["searchpair" + i].type = "DateTimeReceived";
			}
		}

		



		const postdata = {
			searchoptions:{
				limit:localstate.rowsperpage,
				currentsort: localstate.orderby,
				currentsortdir: localstate.order,
				searchpairs:newsearchpairs,
				nestedrelationships:localstate.searchoptions.nestedrelationships
			}
		};

		if (itemlistsearch.length>0){
			//Serials exist in serial list.
			postdata.searchoptions.itemlist = itemlistsearch;
		}

		axios.post(dbendpoint+"/purchasing/getpurchases?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==="login"){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status==="Success"){
					//We should now have a non-0 result from the API
					//Add variables for use with table
					var resultdata = res.data.pagedata.data;

					//ResetArray is to allow AutoComplete Static to take in new values from LoadItems
					var resetarray = [];
					
					for (var i =0; i<resultdata.length; i++){
						//Try: GridKey - Apply GridKey to components: key={row.GridKey}
						//Try: Increment GridKey between rerenders; ie: UpdateState(localstate);
						resultdata[i].GridKey=i;
						resultdata[i].unsaved = false;
						resultdata[i].ExpandRow = false;
						if (localstate.selectedindexes.includes(i)){
							resultdata[i].isSelected = true;
						} else {
							resultdata[i].isSelected = false;
						}

						// //Set Defaults for selectables:
						// resultdata[i].SomeSelectableDefault = resultdata[i].SomeSelectable;

						//If some value, you can reset a searchinput by adding an index and sending it to ResetKeys(keyarray)
						//resetarray.push(i);
					}
					localstate.griditems = resultdata;
					//Hold items for default values
					localstate.defaultvalues = resultdata;

					localstate.totalitems = res.data.pagedata.total;
					//Data freshly loaded, head off any new requests with this state change. Handle in useEffect?
					//localstate.dbreload = false;
					UpdateState(localstate);
					//ResetKeys(resetarray);
				}
				if (res.data.Status==="Failure"){
					//Failure error
					localstate.griditems=[];
					localstate.totalitems = 0;
					UpdateState(localstate);
					dispatch(newErrorMessage({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"}));
					dispatch(setErrorTimeout(5));
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"}));
				dispatch(setErrorTimeout(5));
			}
		});
	}


	/* ############### RESIZER ####################*/
	const createResizableColumn = function (col, resizer) {
		// Track the current position of mouse
		let x = 0;
		let w = 0;
	
		const mouseDownHandler = function (e) {
			// Get the current mouse position
			x = e.clientX;
			// Calculate the current width of column
			const styles = window.getComputedStyle(col);
			w = parseInt(styles.width, 10);
			// Attach listeners for document's events
			document.addEventListener('mousemove', mouseMoveHandler);
			document.addEventListener('mouseup', mouseUpHandler);
		};
	
		const mouseMoveHandler = function (e) {
			// Determine how far the mouse has been moved
			const dx = e.clientX - x;
			// Update the width of column
			col.style.width = `${w + dx}px`;
		};
	
		// When user releases the mouse, remove the existing event listeners
		const mouseUpHandler = function () {
			document.removeEventListener('mousemove', mouseMoveHandler);
			document.removeEventListener('mouseup', mouseUpHandler);
		};
		resizer.addEventListener('mousedown', mouseDownHandler);
	};

	const InitColumnResizers = () => {
		//Runs after rerender
		// Query the table
		const table = document.getElementById('resizeMe');
		// Query thead:
		const thead = document.querySelector('thead');
		const cols = thead.querySelectorAll('td');
		// Loop over them
		[].forEach.call(cols, function (col) {
			// Create a resizer element
			const resizer = document.createElement('div');
			resizer.classList.add([classes.resizer]);
			// Set the height
			resizer.style.height = `${table.offsetHeight-2}px`;
			// Add a resizer element to the column
			col.appendChild(resizer);
			createResizableColumn(col, resizer);
		});
	}

	/* ############### END OF RESIZER ####################*/




	/* ##########################  App Init  ########################## */
	//Run once, don't clean up until controller dismount (unchanging init variable)
	const [appinit] = useState(true);
	useEffect(() => {
		document.title = "Purchasing";
		//Cleanup
		return function cleanup() {
			dispatch(setProgressTimeout(0));
		}
	}, [appinit]);


	//DB Reloads
	useEffect(() => {
		if (state.dbreload){
			//Avoid duplicate loads.
			localstate.dbreload = false;
			LoadItems();
		} else {
			//console.log("Ignore DB Reload.");
			//Boostrap a resizer for the columns:
			InitColumnResizers();
		}
	},);



	/* ##########################  CRUD  ########################## */

	//A Ref stays consistent throughout async function
	//SaveChanges disabled for now.
	// const requestcount = useRef(0);

	// const SaveChanges = () => {
	// 	//Clean up current errors:
	// 	errors.HideError(errors);

	// 	//Configuration
	// 	requestcount.current = 0;
	// 	var timeout = 300; //Seconds before closing final ProgressBar results - To Do: Add option to NOT timeout at all
	// 	var requestinterval = 100; //Milliseconds

	// 	var updatearray = [];
	// 	//Data Validation and Update Array setup
	// 	for (var i = 0; i < localstate.griditems.length; i++) {
	// 		//Halt on bad Name
	// 		if (localstate.griditems[i].Name===""){
	// 			dispatch(newErrorMessage({ errmsg: "One or more of your rows has a blank Name value.", errshow: true, errtimeout: 5, errtype: "warning" }));
	// 			dispatch(setErrorTimeout(10));
	// 			return;
	// 		}
	// 		if (localstate.griditems[i].unsaved) {
	// 			updatearray.push(localstate.griditems[i]);
	// 		}
	// 	}

	// 	if (updatearray.length > 0) {
	// 		DisableButtons();
	// 		dispatch(newProgress({
	// 			msg: 'Saving items...',
	// 			show: true,
	// 			settimeout: true, //This option allows for a timeout after the final item is pass/failed
	// 			timeout: timeout, //Initiated on final item 
	// 			type: 'items', //Labels your save types such as items, products, skus, parts
	// 			finished: 0,
	// 			percent: "0%",
	// 			total: updatearray.length,
	// 			pass: 0,
	// 			fail: 0,
	// 			faillist: [], //Can take simple values,
	// 			faillinks: [], //Can take links to things like items or products.
	// 			timeoutid: false,
	// 			errors:[] //Can take strings
	// 		}));
	// 		i = 0;
	// 		var limit = updatearray.length - 1;
	// 		/* INTERVAL */
	// 		var updateitems = setInterval(function () {
	// 			var item = updatearray[i];
	// 			const postdata = {
	// 				item: item
	// 			};
	// 			axios.post(dbendpoint + "/basictable/update", postdata, defaultpostoptions).then(res => {
	// 				//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
	// 				if (res.status === 200) {
	// 					//If ValidateUser() fails to verify user, it sends back 'login' error. 
	// 					if (res.data.Status === "login") {
	// 						//Not logged in. Reload page causes redirect to /login
	// 						window.location.reload(false);
	// 					}
	// 					if (res.data.Status === "Success") {
	// 						//Success response also includes the item!
	// 						//Try: 10-20-2023 - Should we access read-only DOM elements such CreatedBy? When we do an update, we're avoiding a reload of DB and rerender already..... This would be the icing on the cake even just for static items.
	// 						//If we sent new rows, we'll need to reference the old ID.
	// 						var itemindex = 0;
	// 						if (res.data.OldID) {
	// 							itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.OldID);
	// 							localstate.griditems[itemindex].unsaved = false;
	// 							localstate.griditems[itemindex].PendingItem = false;
	// 							rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
	// 							rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
	// 							//Set New ID
	// 							localstate.griditems[itemindex].ID = res.data.item.ID;
	// 						} else {
	// 							itemindex = localstate.griditems.map(function (o) { return o.ID; }).indexOf(res.data.item.ID);
	// 							localstate.griditems[itemindex].unsaved = false;
	// 							//Refs allow us to update the grid live!
	// 							rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.unsavedhighlight);
	// 							rowRefs.current[itemindex + 'SaveStatus'].classList.remove(classes.errorhighlight);
	// 						}
	// 						dispatch(incrementPass());
	// 					}
	// 					if (res.data.Status === "Failure") {
	// 						//Failure error - To Do: Dispatch failure messages to the ProgressBar
	// 						dispatch(incrementFail(res.data.message));
	// 					}
	// 					requestcount.current++;
	// 					FinalizeRequest(requestcount.current, limit, timeout);

	// 				} else {
	// 					//Non-200 message from server.
	// 					requestcount.current++;
	// 					FinalizeRequest(requestcount.current, limit, timeout);
	// 					dispatch(incrementFail("Non-200 response."));
	// 				}
	// 			}).catch(err => {
	// 				//Non-200, 500 Error, timeout?
	// 				requestcount.current++;
	// 				FinalizeRequest(requestcount.current, limit, timeout);
	// 				dispatch(incrementFail("500 response."));
	// 			});
	// 			//If we have looped through all items, clear this interval.
	// 			if (i === limit) {
	// 				clearInterval(updateitems);
	// 			}
	// 			i++;
	// 		}, requestinterval);
	// 	} else {
	// 		dispatch(newErrorMessage({ errmsg: "Nothing to save.", errshow: true, errtimeout: 5, errtype: "neutral" }));
	// 		dispatch(setErrorTimeout(5));
	// 		ResetPendingSaves();
	// 	}
	// }

	//Clean up View
	const FinalizeRequest = (i, limit, timeout) => {
		//The itterator after responses are incremented to n+1. Reversed below:
		if ((i-1)===limit){
			EnableButtons();
			dispatch(setProgressTimeout(timeout));
			MarkErrorItems();
			btnSave.current.style.display="";
			btnPendingSave.current.style.display="none";
		}
	} 


	//Mark Remaining Items as Warning Items
	const MarkErrorItems = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.unsavedhighlight);
				rowRefs.current[i+'SaveStatus'].classList.add(classes.errorhighlight);
			} else {
				//Resolve previously unresolved
				rowRefs.current[i+'SaveStatus'].classList.remove(classes.errorhighlight);
			}
		}
	}


	const ResetPendingSaves = () =>{
		for (var i=0; i<localstate.griditems.length; i++){
			if (localstate.griditems[i].unsaved){
				localstate.griditems[i].unsaved = false;
			}
		}
		EnableButtons();
		btnSave.current.style.display="";
		btnPendingSave.current.style.display="none";
	}


	{/* BULK EDIT DRAWER STATE AND FUNCTIONS */}
	const [showbulkeditdrawer, setShowBulkEditDrawer] = useState(false);
	const btnApplyBulkEdit = useRef();

	const CloseBulkEditDrawer = () =>{
		setShowBulkEditDrawer(false);
		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}

	//Bulk edits may incur many different types of changes.
	//Using state variables, we can allow the view to change between them.
	const [changetype, setChangeType] = React.useState('');


	//// CHANGE TYPES - Controls change type and input viewables
	const [changeistext, setChangeIsText] = React.useState(false);
	const [changeisfloat, setChangeIsFloat] = React.useState(false);
	const [changeissomeselectable, setChangeIsSomeSelectable] = React.useState(false);
	const [changeisdbitem, setChangeIsDBItem] = React.useState(false);
	const [changeisdate, setChangeIsDate] = React.useState(false);
	const [changeisbool, setChangeIsBool] = React.useState(false);
	const [changeisint, setChangeIsInt] = React.useState(false);
	const [changeisstatus, setChangeIsStatus] = React.useState(false);
	

	//// VALUES - Controls input values and value validation
	const changevalue = React.createRef();
	//These 2 refs are used to update a number input (RestrictNumber() will limit what can be put into input box)
	const changevaluefloat = React.createRef();
	const changevalueint = React.createRef();
	const changevalueselectable = React.createRef();
	const [dbitemselection, setDBItemSelection] = React.useState();


	const handleChangeEditType = (event) => {
		//Reset change value
		changevalue.current="";
		//Reset Inputs:
		setChangeIsText(false);
		setChangeIsFloat(false);
		setChangeIsSomeSelectable(false);
		setChangeIsDBItem(false);
		setChangeIsBool(false);

		//Examples
		setChangeIsInt(false);
		setChangeIsStatus(false);
		setChangeIsDate(false);

		//Reset Btn
		btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
		btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);

		setChangeType(event.target.value);

		if (
			//Text values here.
			event.target.value==="Name"
		) {
			setChangeIsText(true);
		}

		if (event.target.value==="Cost" || event.target.value==="Price"){
			setChangeIsFloat(true);
		}

		if (event.target.value==="SomeSelectable"){
			setChangeIsSomeSelectable(true);
		}

		if (event.target.value==="NewCheckbox"){
			setChangeIsBool(true);
		}

		if (event.target.value==="DBItem"){
			setChangeIsDBItem(true);
		}

		if (event.target.value==="Date"){
			setChangeIsDate(true);
		}
		

		//Unused Examples:
		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

		if (event.target.value==="Status"){
			setChangeIsStatus(true);
		}		

		if (event.target.value==="LotID" || event.target.value==="SkidNumber"){
			setChangeIsInt(true);
		}

	};

	const handleChangeBulkValue = (event) => {
		//Currency
		if (changetype==="Cost" || changetype==="Price"){
			var newvalue = RestrictInputNumber(event.target.value, changevalue.current, event, "float");
			if (newvalue){
				changevalue.current = newvalue;
				changevaluefloat.current.value = newvalue;
			} else {
				if (event.key === "Backspace") {
					//If the newvalue doesn't have a decimal, but the old one did, we try to keep the decimal value by ignoring the ref.
					if ((changevalue.current.indexOf(".") > -1)) {
						changevalue.current = parseFloat(event.target.value).toFixed(2);
					}
				}
			}
		//Integers	
		} else if (changetype==="LotID" || changetype==="SkidNumber") {
			changevalue.current = RestrictInputNumber(event.target.value, changevalue.current, event, "positiveinteger");
			//Updates view:
			changevalueint.current.value = changevalue.current;
		
		} else if (changetype==="NewCheckbox"){
			// placeholder
			changevalue.current = event.target.value;
		} else {
			//Raw Values
			changevalue.current = event.target.value;
		}


		//Verify changes can be saved in current form.
		//Avoid certain items being saved as a blank string:
		if (changetype==="Location" && event.target.value===""){
			btnApplyBulkEdit.current.classList.remove(["MuiButton-containedPrimary"]);
			btnApplyBulkEdit.current.classList.add(["Mui-disabled"]);
			dispatch(newErrorMessage({errmsg:"Value cannot be blank.", errshow:true, errtimeout: 3, errtype:"neutral"}));
			dispatch(setErrorTimeout(5));

		//Allow bulk change to be saved
		} else {
			btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
			btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	const handleChangeDBItem = (event, item) => {
		setDBItemSelection(item);
		//Allow bulk change to be saved
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}

	const handleChangeSomeSelectable = (event) => {
		changevalue.current = event.target.value;
		btnApplyBulkEdit.current.classList.remove(["Mui-disabled"]);
		btnApplyBulkEdit.current.classList.add(["MuiButton-containedPrimary"]);
	}


	//Search for DB Item (autocomplete) - Attempt to make this reusable! Setup endpoints as (env db dbendpoint/table/getautocomplete), see also ApplyBulkEdit
	const [dbitemkey, setDBItemKey] = useState("Name");
	const [opendbitemoptions, openDBItemOptions] = React.useState(false);
	const [dbitemoptions, setDBItemOptions] = React.useState([""]);
	const [loadingdbitemoptions, setLoadingDBItemOptions] = useState(false);
	const InitDBItemOptions = () => {
		if (dbitemoptions.length===0){
			//Load (up to limit) options
			DBItemSearch("");
		}
		openDBItemOptions(true);
	}

	const DBItemSearch = debounce(function(searchvalue){
		setLoadingDBItemOptions(true);
		const postdata = {					
			key:dbitemkey,
			search:searchvalue,
			limit:20
		};
		//Provisions to reuse this search:
		var table = "";
		if (changetype==="Product"){
			table="products";
		}
		if (changetype==="DBItem"){
			table="basictable";
		}
		//Error such as "TypeError: Cannot read property 'filter' of undefined" means we aren't handling the response correctly or the response is malformed.
		axios.post(dbendpoint+"/"+table+"/getautocomplete", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				} else {
					setDBItemOptions(res.data.items);
					console.log(res.data);
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"}));
				dispatch(setErrorTimeout(5));
			}
			setLoadingDBItemOptions(false);
		});
	},600);


	const ApplyBulkEdit = () => {
		var items = [];
		for (var i=0; i<localstate.selectedindexes.length; i++){
			items.push(localstate.griditems[localstate.selectedindexes[i]]);
		}

		//Simple key-value pairs:
		if (changetype==="Name" ||
			changetype==="Price" ||
			changetype==="Cost" ||
			changetype==="SomeSelectable" ||
			changetype==="Date" ||
			changetype==="NewCheckbox"
		){
			for (i=0; i<items.length; i++){
				items[i][changetype] = changevalue.current;
			}
		}

		//Database Item Selection (potential multiple values to change)
		if (changetype==="DBItem"){
			//Place your specific column values here:
			for (i=0; i<items.length; i++){
				items[i]["Name"] = dbitemselection.Name;
				//Example: items[i]["ProductID"] = dbitemselection.ProductID;
			}
		}

		//Reassign other item values if needed before pushing them to postdata.items.
		//Example provision to change Margin if price or cost change
		if (changetype==="Price"){
			for (i=0; i<items.length; i++){
				//items[i].Price = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype==="Cost"){
			for (i=0; i<items.length; i++){
				//items[i].Cost = changevalue.current;
				items[i].Margin = items[i].Price - items[i].Cost;
			}
		}

		if (changetype==="Date"){
			//Verify if Date is valid:
			var count = (changevalue.current.match(/-/g) || []).length;
			if (changevalue.current.length === 10 && count === 2) {
				let isValidDate = Date.parse(changevalue.current);
				if (isNaN(isValidDate)) {
					dispatch(newErrorMessage({ errmsg: "Not a valid date for audit. Use YYYY-MM-DD.", errshow: true, errtimeout: 5, errtype: "warning" }));
					dispatch(setErrorTimeout(10));
					return;
				} else {
					//Function Continues to post
				}
			} else {
				dispatch(newErrorMessage({ errmsg: "Not a valid date for audit. Use YYYY-MM-DD.", errshow: true, errtimeout: 5, errtype: "warning" }));
				dispatch(setErrorTimeout(10));
				return;
			}
			for (i=0; i<items.length; i++){
				if (changeisdate){
					items[i]['BulkEditDate'] = true;
				}
			}
		}

		var postdata={
			items:items
		}

		

		axios.post(dbendpoint+"/basictable/bulkedititems", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status===200){
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status==='login'){
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				if (res.data.Status==='Success'){
					//console.log(res.data);
					if (res.data['SuccessCount']>0){
						dispatch(newErrorMessage(
							{
								errmsg:"Items Changed: "+res.data['SuccessCount']+", Failures: "+res.data['FailCount'],
								errshow:true,
								errtimeout: 5,
								errtype:'ok'
							}
						));
						dispatch(setErrorTimeout(30));
					}
				}
				if (res.data.Status==='Failure'){
					//Failure error
					dispatch(newErrorMessage({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:'neutral'}));
					dispatch(setErrorTimeout(5));
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({errmsg:'Non-Error OK',errshow:true,errtimeout:300,errtype:'ok'}));
				dispatch(setErrorTimeout(5));
			}
		});
	}



	//Delete Confirmation and Deletion
	const [showdeleteconfirmation, setShowDeleteConfirmation] = useState(false);
	const [deleteitems, setDeleteItems] = useState([]);
	const DeleteSelectedInit = () =>{
		var deleteitemsarray =[];
		//Reflect items to user for confimation.
		for (var i=0; i<localstate.selectedindexes.length; i++){
			deleteitemsarray.push(localstate.griditems[localstate.selectedindexes[i]]);
		}
		console.log("DeleteSelected()");
		console.log("Array:"+deleteitemsarray);
		//Commit localstate to state before message renders!
		UpdateState(localstate);
		setDeleteItems(deleteitemsarray);
		setShowDeleteConfirmation(true);
	}

	const DeleteSelected = () => {
		var finishedrequests = 0;
		for (var i=0; i<localstate.selectedindexes.length; i++){
			if (localstate.griditems[localstate.selectedindexes[i]].hasOwnProperty("PendingItem")){
				//Pending items are simply removed from the view and forgotten.
				localstate.griditems.splice(localstate.selectedindexes[i], 1);
				//Count as finished request
				finishedrequests++;
			} else {
				//Make Delete request to DB
				const postdata = {				
					item:localstate.griditems[localstate.selectedindexes[i]]
				};
				axios.post(dbendpoint+"/basictable/delete", postdata, defaultpostoptions).then(res => {
					//No matter the response, we consider the result as a 'finished request'. We can then properly do clean-up.
					finishedrequests++;	
					//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
					if (res.status===200){
						//If ValidateUser() fails to verify user, it sends back "login" error. 
						if (res.data.Status==="login"){
							//Not logged in. Reload page causes redirect to /login
							window.location.reload(false);
						}
						if (res.data.Status==="Success"){
							//Success response also includes the item!
							//If we're pulling the item out of grid items, we'll use the ID of the item for reference.
							if (res.data.OldID){
								//Since griditems state can be reloaded anytime, we look for the indexOf the ID
								var itemindex = localstate.griditems.map(function(o) { return o.ID; }).indexOf(res.data.OldID);
								localstate.griditems.splice(itemindex, 1);
								//Remove selected index as well?
							} else {
								dispatch(newErrorMessage({errmsg:"Could not delete one or more items.", errshow:true, errtimeout: 8, errtype:"warning"}));
								dispatch(setErrorTimeout(5));
							}
						}
						if (res.data.Status==="Failure"){
							//Failure error
							dispatch(newErrorMessage({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"}));
							dispatch(setErrorTimeout(5));
							//Need to clear selections as any removed grid items will mess with selectedindexes.
							//Setting selected indexes to a blank array will stall the upper for-loop
							localstate.selectedindexes = [];
							localstate.selectedcount = 0;
							for (var j=0; j<localstate.griditems.length; j++){
								localstate.griditems[j].isSelected = false;
								localstate.griditems[j].GridKey++;
							}
							UpdateState(localstate);
						}
					} else {
						//Non-200 message from server.
						dispatch(newErrorMessage({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"}));
						dispatch(setErrorTimeout(5));
					}
					//After result from last request, do cleanup.
					if (finishedrequests === localstate.selectedindexes.length) {
						//Clear out all selections! Since checkboxes are controlled only by grid items index, we don't have good
						//tracking on which items are which.
						localstate.selectedindexes = [];
						localstate.selectedcount = 0;
						UpdateState(localstate);
						setShowDeleteConfirmation(false);
						CloseViewEditMenu();
					}
				});
			}
		}
	}
	const CancelDelete = () => {
		setShowDeleteConfirmation(false);
	}

	const GoToItem = (id) => {
		history.push('/boilerplatekeyvalues/'+id);
	}


	/* ##########################  Cell Interaction  ########################## */

	const onChangeName = (event, index) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+"Name"]){
				rowRefs.current[(index+1)+"Name"].focus();
			} else {
				rowRefs.current[("0Name")].focus();
			}
		} else {
			//Update ref
			rowRefs.current[index+"Name"].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index].Name = event.target.value;
			localstate.griditems[index].unsaved = true;
			localstate.pendingsaves = true;
			//Update Save button to show pending saves
			//btnSave.current.classList.add(["MuiButton-containedPrimary"]);
		}
	}

	//Tab Order: Used for horizontal tabbing below.
	//We need an order for horizontal tabbing - attempting to tab to an unavailable column (like a disabled column via colstate) will result in an error
	const taborder = ["Name", "Cost", "Price"];


	//Excel-like functionality for grid
	const HandleKeyDown = (event, index, column) => {
		//Deny Decimals for Integer inputs!
		console.log("Key: "+event.key);
		if (event.key === "." && column==="SomePositiveInteger"){
			console.log("Prevent decimal in integer input");
			event.preventDefault();
		}
		//Handle Tabs!
		if (event.key === "Tab") {
			event.preventDefault();
			//Vertical VS Horizontal Tabbing

			//Vertical Tabbing - Checkboxes, Gross Income, Rates
			//Vertical Tabbing is never subject to the next column not being shown (we always to back to record #1 instead!)
			if (column === "Checkbox") {  //Insert each type of column you want vertically tabbed here: if (column==="Checkbox" || column==="Margin"){ etc
				//If the next row ref exists....
				if (rowRefs.current[(index + 1) + column]) {
					rowRefs.current[(index + 1) + column].focus();
				} else {
					//Go to first element
					rowRefs.current[("0" + column)].focus();
				}
			} else {
				//Horizontal Tabbing - Row Data
				//Horizontal Tabbing is subject to certain columns not being available for selection. (colstate)

				//Get index within tab order:
				var tabindex = taborder.indexOf(column);
				//Increase index until we find the next tab order column
				for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
					//If we're at the last column element, go to the next row's first available column element
					if (i === taborder.length) {
						//Start at beginning of row tab order and reitterate
						i = -1;
						//If next row exists:
						if (rowRefs.current[(index + 1) + column]) {
							index = index + 1;
						} else {
							//Go to first row.
							index = 0;
						}
					} else {
						//If there is another elemet in the taborder.... Continue. Else, go to first column.
						if (taborder[i]) {
							//If that next element is available
							if (colstate[taborder[i]]) {
								rowRefs.current[index + taborder[i]].select();
								break;
							}
						} else {
							//Start at beginning of row tab order and reitterate
							i = -1;
						}
					}
				}
			}
		}
		//Handle Down Arrow
		if (event.key === "ArrowDown"){
			event.preventDefault();
			if (rowRefs.current[(index + 1) + column]) {
				rowRefs.current[(index + 1) + column].focus();
			} else {
				//Go to first element
				rowRefs.current[("0" + column)].focus();
			}
		}
		//Handle Up Arrow
		if (event.key === "ArrowUp"){
			event.preventDefault();
			if (rowRefs.current[(index - 1) + column]) {
				rowRefs.current[(index - 1) + column].focus();
			} else {
				//Go to last element
				var lastelement = localstate.griditems.length-1;
				rowRefs.current[(lastelement + column)].focus();
			}
		}
		//Handle Right Arrow - Special cases, normally ignored to be used for cursor position:
		/*
		if (event.key === "ArrowRight"){
			event.preventDefault();
			//Same Process as Tab
			var tabindex = taborder.indexOf(column);
			for (var i = (tabindex + 1); i < taborder.length + 1; i++) {
				if (i === taborder.length) {
					i = -1;
					if (rowRefs.current[(index + 1) + column]) {
						index = index + 1;
					} else {
						index = 0;
					}
				} else {
					if (taborder[i]) {
						if (colstate[taborder[i]]) {
							rowRefs.current[index + taborder[i]].select();
							break;
						}
					} else {
						i = -1;
					}
				}
			}
		}
		//Handle Left Arrow
		if (event.key === "ArrowLeft"){
			event.preventDefault();
			var tabindex = taborder.indexOf(column);
			var i;
			var rowincrement=0;
			for (i=(tabindex-1); i>-2; i--){
				if (i===-1){
					rowincrement=-1;
					i=taborder.length; //Go to end of tab order
				} else {
					if (colstate[taborder[i]]) {
						break;
					}
				}
			}
			//If tabindex first and we're in the first row, go to the last element on last row.
			if (tabindex===0 && index===0){
				var lastindex = localstate.griditems.length-1;
				rowRefs.current[lastindex + taborder[i]].select();
			} else {
				rowRefs.current[(index+rowincrement) + taborder[i]].select();
			}
		}
		*/
	}


	const DetectBlankNumber = (event, index, column) => {
		if (event.target.value===""){
			rowRefs.current[index+column].value="0.00";
			localstate.griditems[index][column] = "0.00";
		}
	}

	//Cell Value Change
	const onChangeValue = (event, index, column) => {
		var oldvalue = localstate.griditems[index][column];
		var newvalue = event.target.value;
		console.log("Event value:"+event.target.value);
		console.log("Raw:"+newvalue);
		if (event.key !== "Tab" && 
			event.key!=="ArrowDown" && 
			event.key!=="ArrowUp" && 
			event.key!=="ArrowLeft" && 
			event.key!=="ArrowRight" && 
			event.key!=="ShiftLeft" && 
			event.key!=="ShiftRight"
			){
			//Conditionals for Types:
			if (column==="Cost"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				//If new value...
				if (newvalue){
					//If Cost changes, so does Margin
					rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
					localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
					//Backspace on a decimal should yield same value for old and new
					//console.log("Oldvalue has decimal:"+oldvalue.indexOf(".")+"   Newvalue has decimal:"+newvalue.indexOf(".")+"   Event key:"+event.key);
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1 && event.key==="Backspace"){
						//console.log("Scenario1");
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}

			//Positive integers don't need decimals! Try to preventDefault on input on keydown.
			if (column==="SomePositiveInteger"){
				//TO DO - UNFINISHED UNTESTED CODE
				//Some forms don't have any input to begin with.
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "positiveinteger");
				//If new value...
				if (newvalue){
					//If Cost changes, so does Margin
					//rowRefs.current[index+"Margin"].textContent = (rowRefs.current[index+"Price"].value - newvalue).toFixed(2);
					//localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;

					//Backspace on a decimal should yield same value for old and new
					if ([oldvalue].indexOf(".")>-1 && newvalue.indexOf(".")===-1){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)) {
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					//Handle possible backspace.
					//We need to update the localstate, but NOT the ref. The input ref can't take values like "2." and messes up the cursor position if you try.
					// if (event.key === "Backspace") {
					// 	if ((oldvalue.indexOf(".") > -1)) {
					// 		localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
					// 	}
					// }
				}
			}

			if (column==="Price"){
				if (!oldvalue){
					oldvalue="0";
				}
				newvalue = RestrictInputNumber(newvalue, oldvalue, event, "float");
				if (newvalue){
					//If Price changes, so does Margin
					rowRefs.current[index+"Margin"].textContent = (newvalue - rowRefs.current[index+"Cost"].value).toFixed(2);
					localstate.griditems[index].Margin = rowRefs.current[index+"Margin"].textContent;
					//Backspace on a decimal should yield same value for old and new
					if (oldvalue.indexOf(".")>-1 && newvalue.indexOf(".")===-1 && event.key==="Backspace"){
						//No need to change front end, simply update griditem
						localstate.griditems[index][column] = newvalue;
					} else if (parseFloat(event.target.value) !== parseFloat(oldvalue)){
						rowRefs.current[index+column].value=newvalue;
						localstate.griditems[index][column] = newvalue;
					}
				} else {
					if (event.key === "Backspace") {
						if ((oldvalue.indexOf(".") > -1)) {
							localstate.griditems[index][column] = parseFloat(event.target.value).toFixed(2);
						}
					}
				}
			}
			//Provision for Booleans that require a re-render. Expensive!
			if (column==="SomeBoolean"){
				if (event.target.checked){
					console.log("Checked = true");
					localstate.griditems[index][column] = 1;
					UpdateState(localstate);
				} else {
					console.log("Checked = false");
					localstate.griditems[index][column] = 0;
					UpdateState(localstate);
				}
				
			} else {
				//Selects already render the correct and unsaved result of selecting. (but right now they cause rerender via state.... possible change here.)
				if (column==="NewCheckbox") { //MaterialUI Checkbox
					if (event.target.checked){
						localstate.griditems[index][column] = 1;
					} else {
						localstate.griditems[index][column] = 0;
					}
				} else if (column==="SomeSelectable" || column==="Cost" || column==="Price" || column==="SomePositiveInteger") { 
					//Avoids Refs! Required for numeric input and seletables. Component takes care of view while localstate has yet to update the DB
					localstate.griditems[index][column] = newvalue;
				} else {
					//Update Refs like usual.
					rowRefs.current[index+column].value=newvalue;
					localstate.griditems[index][column] = newvalue;
				}
			}
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			btnSave.current.style.display="none";
			btnPendingSave.current.style.display="";
			localstate.griditems[index].unsaved = true;
		}
	}

	//Custom Function - Optional
	const onChangeCost = (event, index, column) => {
		if (event.key === "Tab"){
			event.preventDefault();
			//If end of table, go to first
			if (rowRefs.current[(index+1)+column]){
				rowRefs.current[(index+1)+column].focus();
			} else {
				rowRefs.current[("0"+column)].focus();
			}
		} else {
			//If Cost changes, so does Margin
			rowRefs.current[index+"Margin"].value = (rowRefs.current[index+"Price"].value - event.target.value).toFixed(2);
			//Update ref
			rowRefs.current[index+column].value=event.target.value;
			rowRefs.current[index+"SaveStatus"].classList.add(classes.unsavedhighlight);
			//Update localstate
			localstate.griditems[index][column] = event.target.value;
			console.log(localstate.griditems[index].Cost);
		}
	}


	/* ##########################  Button Functions  ########################################################################################################################################################### */
	/* ######################################################################################################################################################################################################### */ 

	const ExpandRowToggle = (index) => {
		localstate.griditems[index].ExpandRow = !localstate.griditems[index].ExpandRow;
		UpdateState(localstate);
	}

	const ExpandAll = () => {
		for (var i=0; i<localstate.griditems.length; i++){
			localstate.griditems[i].ExpandRow = true;
		}
		CloseViewOptionsMenu();
		UpdateState(localstate);
	}

	
	function getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	//Export Confirmation and Exports
	const [showexportconfirmation, setShowExportConfirmation] = useState(false);
	//Chooses between "selected" or "searchresults"
	const [exporttype, setExportType] = useState("");
	//Export Message
	const [exportmessage, setExportMessage] = useState("");
	var exportmode = "";
	var exportlimit = 2000;
	var exportfilename = "PurchasesCSV";
	
	const InitExport = (exporttype) => {
		if (exporttype==="selected" && localstate.selectedindexes.length===0){
			//Do nothing
		} else {
			//Show export type selection
			setShowExportConfirmation(true);
			//Set export by "selected" or "searchresults"
			setExportType(exporttype);
			if (exporttype==="selected"){
				setExportMessage("");
			}
			if (exporttype==="searchresults"){
				setExportMessage("Exports of search results will be limited to "+exportlimit+" rows.");
			}
		}
	}

	const PrepareExport = (mode) =>{
		//Set global
		exportmode = mode;
		var exportdata = [];
		var clonedata=[];
		if (exporttype==="selected"){
			//Need to DEEP clone:
			var clonedata = JSON.parse(JSON.stringify(localstate.griditems))
			for (var i=0; i<localstate.selectedindexes.length; i++){
				exportdata.push(clonedata[localstate.selectedindexes[i]]);
			}
			Export(exportdata);
		}
		if (exporttype==="searchresults"){
			const postdata = {
				searchoptions:{
					//Set Max Limit Here
					limit:exportlimit,
					currentsort: localstate.orderby,
					currentsortdir: localstate.order,
					searchpairs:localstate.searchoptions.searchpairs
				}
			};
			axios.post(dbendpoint+"/basictable?page="+(localstate.page+1), postdata, defaultpostoptions).then(res => {
				if (res.status===200){
					if (res.data.Status==="login"){
						window.location.reload(false);
					}
					if (res.data.Status==="Success"){
						exportdata = res.data.pagedata.data;
						Export(exportdata);
					}
					if (res.data.Status==="Failure"){
						dispatch(newErrorMessage({errmsg:res.data.message, errshow:true, errtimeout: 5, errtype:"neutral"}));
						dispatch(setErrorTimeout(5));
					}
				} else {
					dispatch(newErrorMessage({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"}));
					dispatch(setErrorTimeout(5));
				}
			});
		}
	}

	const Export = (exportdata) => {
		//Remove Metas
		for (var i=0; i<exportdata.length; i++){
			removeProp(exportdata[i], "isSelected");
			removeProp(exportdata[i], "unsaved");
			removeProp(exportdata[i], "ExpandRow");
			removeProp(exportdata[i], "GridKey");
		}

		//Use Export Mode to pare data
		if (exportmode==="simple"){
			for (var i=0; i<exportdata.length; i++){
				removeProp(exportdata[i], "Date");
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode==="expanded"){
			for (var i=0; i<exportdata.length; i++){
				removeProp(exportdata[i], "created_at");
				removeProp(exportdata[i], "updated_at");
			}
		}

		if (exportmode==="exhaustive"){
			//Remove nothing - or possibly break down nested relationships
		}
		ExportCSV(exportdata, exportfilename);
		setShowExportConfirmation(false);
	}


	/* ########################## Lower Screen Demo Data/Functions ###### */


	//Basic Select
	const [basicselectvalue, setBasicSelectValue] = React.useState(10);

	const handleChange = (event) => {
		setBasicSelectValue(event.target.value);
	};


	//Autocomplete Simple
	//Example: Products
	const [openproductoptions, openProductOptions] = React.useState(false);
	const [productoptions, setProductOptions] = React.useState([""]);
	const [loadingproductoptions, setLoadingProductOptions] = useState(false);
	const [productsearchterm, setProductSearchTerm] = useState("");
	//For loading single items that do have a value, use LoadItem function in conjunction with: setProductSearchTerm(res.data.item.product.Name);
	const InitProductOptions = () => {
		if (productoptions.length===0){
			ProductSearch("");
		}
		openProductOptions(true);
	}

	const onChangeProductOption = (event, newvalue) =>{
		//Set value for current search
			// Example:
			// localstate.searchoptions.searchpairs.searchpairX.type=autocompletesearchtypeX;
			// localstate.searchoptions.searchpairs.searchpairX.value=newvalue[autocompletesearchtypeX];
			// localstate.dbreload = true;
			// UpdateState(localstate);

		//Or set value for your item:
			//localstate.itemdata['ProductID'] = newvalue['ProductID'];
			// localstate.pendingsaves = true;
			// btnSave.current.disabled = false;
			// btnSave.current.style.color="white";
			// btnSave.current.style.backgroundColor="#01579B";

		//Or just yell at the user, the ProductID they just set
			//alert(newvalue['ProductID']);

		
			
	}

	const ProductSearch = debounce(function(searchvalue){
		setProductSearchTerm(searchvalue);
		setLoadingProductOptions(true);
		const postdata = {					
			search:searchvalue,
			limit:20
		};
		axios.post(dbendpoint+"/items/searchproducts", postdata, defaultpostoptions).then(res => {
			if (res.status===200){
				if (res.data.Status==="login"){
					window.location.reload(false);
				}
				if (res.data.Status==="Success"){
					setProductOptions(res.data.items);
				}
			} else {
				//Non-200 message from server.
				dispatch(newErrorMessage({errmsg:"Bad response from server.", errshow:true, errtimeout: 5, errtype:"warning"}));
				dispatch(setErrorTimeout(5));
			}
			setLoadingProductOptions(false);
		});
	},600);

	const [basicselectdbvalue, setBasicSelectDBValue] = useState("");



	//Let's try to set a default value for our AutoComplete
	const [testDBAC, setDBAC] = useState('Some UseState Default');

	const onDBAutoCompleteChange = (newvalue, searchkey) =>{
		//We can parse results by using keys - this makes the function reusable so we can insert many DBAutoComplete Components
		if (searchkey==="Vendor"){
			console.log("Set new value: "+newvalue.Vendor);
			setDBAC(newvalue.Vendor);
		}
		
	}

	
	/* ##########################  Column Configuration  ####################################################################################################################################################### */
	/* ######################################################################################################################################################################################################### */ 
	const headCells = [
		//Be sure to adjust widths for cells as well.
		{ id: "Status", numeric: false, label: "Status", align:"left", allowsort:true, style:{}},
		{ id: "LotName", numeric: true, label: "Lot Name", align:"left", allowsort:true, style:{}},
		{ id: "Complete", numeric: true, label: "Complete", align:"center", allowsort:true, style:{}},
		{ id: "Description", numeric: true, label: "Description", align:"left", allowsort:true, style:{}},
		{ id: "Skids", numeric: true, label: "Skids", align:"center", allowsort:true, style:{}},
		{ id: "Vendor", numeric: false, label: "Vendor", align:"left", allowsort:true, style:{}},
		{ id: "created_at", numeric: false, label: "Created", align:"left", allowsort:true, style:{}},
		{ id: "DateTimeReceived", numeric: false, label: "Received", align:"left", allowsort:true, style:{}},
		{ id: "InvoiceNumber", numeric: false, label: "Invoice #", align:"left", allowsort:true, style:{}},
		{ id: "InvoiceTotal", numeric: false, label: "Invoice $", align:"center", allowsort:true, style:{}},
		{ id: "PaymentStatus", numeric: false, label: "Payment Status", align:"center", allowsort:true, style:{}},
		{ id: "DatePaymentPosted", numeric: false, label: "Payment Posted",  align:"left", allowsort:true, style:{}},
		{ id: "PaymentMethod", numeric: false, label: "Payment Method",  align:"left", allowsort:true, style:{}},
		{ id: "DamageTotal", numeric: false, label: "Damage Total",  align:"center", allowsort:true, style:{}},
		{ id: "CreditRequestTotal", numeric: false, label: "Credit Requested",  align:"center", allowsort:true, style:{}},
		{ id: "CreditReceivedTotal", numeric: false, label: "Credit Received",  align:"center", allowsort:true, style:{}},
	];

	function EnhancedTableHead(props) {
		const { classes, onSelectAllClick, order, onRequestSort } = props;
		const createSortHandler = (property) => (event) => {
			onRequestSort(event, property);
		};
	
		return (
			<thead style={{display:"table-header-group"}}>
				<tr style={{border:"1px solid #CCC",
							backgroundColor:"#DDD"}}>
					<td style={{width: "14px", padding:"none", display:"table-cell", padding:"2px 4px 2px 5px"}}>

						<Checkbox
							className={classes.gridcheckbox}
							disableRipple
							color="default"
							defaultChecked={localstate.griditems.length === localstate.selectedindexes.length}
							checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
							icon={<span className={classes.icon} />}
							onChange={onSelectAllClick}
						/>

					</td>
					{/* Map remaining table headers */}
					{headCells.map((headCell) =>
						colstate[headCell.id] && (
							<td
								key={headCell.id}
								align={headCell.align}
								style={headCell.style}
							>
								{(headCell.allowsort) &&
									<TableSortLabel
										active={localstate.orderby === headCell.id}
										direction={localstate.orderby === headCell.id ? order : "asc"}
										onClick={createSortHandler(headCell.id, headCell.allowsort)}
										hideSortIcon
									>
										{/* This is a conditional for headCell where the id is "Name". This allows us to put a spacer in here for the expand icon button */}
										{/* The expand button is optional, this can be removed if needed! */}
										{(headCell.id === "Name") &&
											<div style={{ width: "30px", display: "inline-block" }}></div>
										}
										{/* If current sort, show bold label */}
										{(localstate.orderby === headCell.id)
											? <span style={{ fontWeight: "bold" }}>{headCell.label}</span>
											: <span>{headCell.label}</span>
										}
									</TableSortLabel>
								}
								{(!headCell.allowsort) &&
									<span>{headCell.label}</span>
								}
							</td>
						)
					)}
				</tr>
			</thead>
		);
	}

	EnhancedTableHead.propTypes = {
		classes: PropTypes.object.isRequired,
		numSelected: PropTypes.number.isRequired,
		onRequestSort: PropTypes.func.isRequired,
		onSelectAllClick: PropTypes.func.isRequired,
		order: PropTypes.oneOf(["asc", "desc"]).isRequired,
		orderBy: PropTypes.string.isRequired,
		rowCount: PropTypes.number.isRequired,
	};

	{/* Utility Drawer state and functions */}
	const [showutilitydrawer, setShowUtilityDrawer] = useState(false);

	const CloseUtilityDrawer = () =>{
		setShowUtilityDrawer(false);
		//Reload DB?
		//localstate.dbreload = true;
		//Update State?
		//UpdateState(localstate);
	}

	const OpenUtilityDrawer = () =>{
		CloseViewOptionsMenu();
		CloseViewEditMenu();
		setShowUtilityDrawer(true);
	}

	{/* Item List - General purpose boilerplate */}
	const [showitemlist, setShowItemList] = useState(false);
	//We keep our original item list intact, use itemlistsearch for new DB reload
	const [itemlistsearch, setItemListSearch] = useState("");
	const [dupeitems, setDupeItems] = useState('');
	const CloseItemList = () =>{
		//Get unique items
		const result = localstate.searchoptions.itemlist.split(/\r?\n/).filter(element => element);
		const newitemlist = GetUniqueArray(result);
		setItemListSearch(newitemlist);
		setShowItemList(false);

		//Reload DB
		localstate.dbreload = true;
		//Update State
		UpdateState(localstate);
	}
	const onChangeItemList = (event) => {
		//Optional check for dupes:
		//Bust apart by line breaks!
		//Let's start sending arrays to PHP Laravel instead where possible since we're already
		//going to do work here before the API call.

		//If there is a line break at the end of the list (blank new line), you may end up with a falsy array element at the end.
		//Filter creates a new array based on passing a test function (true or false).
		//By passing a falsy array element (undefined), it won't return that element in the result array.
		//Split by Line Breaks, then filter.
		const result = event.target.value.split(/\r?\n/).filter(element => element);
		//Find Dupes, let the user know there are dupes.
		const dupes = GetDupeArray(result);
		if (dupes.length>0){
			setDupeItems(dupes);
			console.log(dupes);
		} else {
			setDupeItems('');
		}
		localstate.searchoptions.itemlist = event.target.value;
		//setItemList(event.target.value);
	}

	{/* Print Labels */}
	const PrintLabels = () =>{
		var itemids = [];
		//Grab all IDs
		for (var i=0; i<localstate.selectedindexes.length; i++){
			itemids.push(localstate.griditems[localstate.selectedindexes[i]]['ID']);
		}

		var params = {
			itemids:JSON.stringify(itemids),
			labeltype:'basiclabel',
			autoclose:false,
			seriallist:'',
			orderby:localstate.orderby,
			order:localstate.order
		};
	
		// Creating a form
		var form = document.createElement("form");
		form.setAttribute("method","post");
		form.setAttribute("action","../v3/labels");
		form.setAttribute("target", "NewLabel");
		for (var i in params) {
			if (params.hasOwnProperty(i)){
				// Creating the input
				var input = document.createElement('input');
				input.type='hidden';
				input.name=i;
				input.value=params[i];

				// Attach input to form
				form.appendChild(input);
			}
		}
		document.body.appendChild(form);
		form.submit();
	}


	//Some requests might take longer
	const longpostoptions = {
		withCredentials:true,
		withXSRFToken: true,
		crossDomain:true,
		mode:"no-cors",
		timeout:20000,
	};



	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/

	
	/* ##########################  Render Function  ########################## */
	/* General rules: The /App allows us to use 100% of the width here. We can also allow each controller to set their own min width, ie: minWidth: "350px" below */
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div style={{padding:"8px", overflow:"auto", minWidth:"350px"}}>
				{/* Utility Drawer, good for search options */}
				<Drawer open={showutilitydrawer} style={{ width: "600px" }}>
					<div style={{height:"47px"}}></div>
					<Typography variant="h4" gutterBottom align="center">
						Utility Drawer
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							You may want to add some instructional text here.<br></br>
						</Typography>

						{/* You may want access to errors here */}
						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}
						<NewErrorMessage />

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseUtilityDrawer()}>
							Close
						</Button>
						
					</div>
				</Drawer>


				{/* Item List - used to load only items placed in text area. */}
				<Drawer open={showitemlist} style={{ width: "600px" }}>
					<div style={{height:"47px"}}></div>
					<Typography variant="h4" gutterBottom align="center">
						Item List - Example: Names
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							Add names here:<br></br>
						</Typography>

						{/* You may want access to errors here */}
						{(errors.currenterror.errshow) &&
							<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
								<ErrorMessage />
							</div>
						}

						<TextareaAutosize style={{ width: "100%" }} minRows={10} placeholder="Paste names from spreadsheet here."
							onChange={(event) => onChangeItemList(event)}
							defaultValue={localstate.searchoptions.itemlist}
						/>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseItemList()}>
							Close
						</Button>

						{(dupeitems.length>0) &&
							<div>
								Duplicates found: 
									{dupeitems.map(function (dupe, i) {
										console.log('test');
										return <li key={i}>{dupe}</li>
									})}
							</div>
						}
						
					</div>
				</Drawer>



				{/* Bulk Edit Drawer */}
				<Drawer open={showbulkeditdrawer} style={{ width: "600px" }}>
					<div style={{height:"47px"}}></div>
					<Typography variant="h4" gutterBottom align="center">
						Bulk Edit Selected Items
					</Typography>
					<div style={{ width: "400px", padding: "10px" }}>
						<Typography variant="subtitle1" gutterBottom>
							Changes apply to all checkbox selected items in the list view. You may perform multiple changes before returning to the list. Select change type to get started.<br></br>
						</Typography>

						{/* You may want access to errors here - create parent container to hold position for you. */}
						<div style={{height:"35px", textAlign:"center"}}>
							{(errors.currenterror.errshow) &&
								<div style={{ textAlign: "center", height: "25px", fontSize: "12px" }}>
									<ErrorMessage />
								</div>
							}	
							<NewErrorMessage />
						</div>
						

						<FormControl className={classes.formControl} style={{ width: "300px" }} variant="standard">
							<InputLabel id="Change type">Change Type</InputLabel>
							<Select
								labelId="change-type"
								id="change-type"
								value={changetype}
								onChange={handleChangeEditType}
							>
								<MenuItem value={'Name'}>Name</MenuItem>
								<MenuItem value={'Cost'}>Cost</MenuItem>
								<MenuItem value={'Price'}>Price</MenuItem>
								<MenuItem value={'SomeSelectable'}>Some Selectable</MenuItem>
								<MenuItem value={'DBItem'}>DB Item (Demo of Name lookup)</MenuItem>		
								<MenuItem value={'NewCheckbox'}>New Checkbox</MenuItem>		
								<MenuItem value={'Date'}>YYYY-MM-DD Date</MenuItem>						
							</Select>
						</FormControl>

						{/* To do: Set change values. Most are just text, so we'll start with that: */}
						{(changeistext) &&
							<div>
							<TextField variant="standard" style={{ width: "300px" }} required id="standard-required" defaultValue="" onChange={handleChangeBulkValue} />
							</div>
						}

						{(changeisstatus) &&
							<FormControl className={classes.formControl} style={{width:"300px"}} variant="standard">
							<InputLabel id="demo-simple-select-label">Select Type</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								onChange={handleChangeBulkValue}
							>
								<MenuItem value={"Checked In"}>Checked In</MenuItem>
								<MenuItem value={"Sold"}>Sold</MenuItem>
								<MenuItem value={"Scrap"}>Scrap</MenuItem>
								<MenuItem value={"Returned to Vendor"}>Returned to Vendor</MenuItem>
								<MenuItem value={"Undetermined or Missing"}>Undetermined or Missing</MenuItem>
							</Select>
							</FormControl>
						}

						{/* Float: Cost, Price */}
						{(changeisfloat) &&
							<div>
								<TextField variant="standard" type="number" step="0.01"  
									inputRef={el => changevaluefloat.current = el} style={{ width: "300px" }} 
									required id="standard-required" defaultValue="Value" 
									onKeyUp={(event)=>{handleChangeBulkValue(event)}} 
								/>
							</div>
						}

						{/* EXAMPLE: Integer: LotID or SkidNumber */}
						{(changeisint) &&
							<div>
								<TextField variant="standard" type="number" step="1"  
								inputRef={el => changevalue.current = el}
								style={{ width: "300px" }}
								required 
								id="standard-required"
								defaultValue=""
								onKeyUp={(event)=>{handleChangeBulkValue(event)}} />
							</div>
						}


						{/* Change is Some Selectable SomeSelectable */}
						{(changeissomeselectable) &&
							<div>
								<FormControl variant="standard" className={classes.formControl}>
									{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
									{/* Need: Set default value if row.SomeSelectable is null. */}
									{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

									{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
									{/* Override select prop class with new padding specs */}

									<Select
										defaultValue={""}
										onChange={(event) => handleChangeSomeSelectable(event)}
										classes={{
											//icon: classes.nodisplay,
											//select: classes.selectpadding
										}}
										style={{ width: "300px" }}
									>
										<MenuItem value="">
											<em>None (blank string)</em>
										</MenuItem>
										<MenuItem value={10}>Ten (integer)</MenuItem>
										<MenuItem value={"A"}>
											<Chip size="small" label="A" clickable className={classes.gradea} />
										</MenuItem>
										<MenuItem value={"B"}>
											<Chip size="small" label="B" clickable className={classes.gradeb} />
										</MenuItem>
										<MenuItem value={"C"}>
											<Chip size="small" label="C" clickable className={classes.gradec} />
										</MenuItem>
										<MenuItem value={"Bad"}>
											<Chip size="small" label="Bad" clickable className={classes.gradebad} />
										</MenuItem>
										<MenuItem value={"Repair"}>
											<Chip size="small" label="Repair" clickable className={classes.graderepair} />
										</MenuItem>
										<MenuItem value={"Scrap"}>
											<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						}


						{/* DB Item (Autocomplete) */}
						{(changeisdbitem) &&
							<div>
								<Autocomplete freeSolo forcePopupIcon={false} disableClearable style={{ width: "300px", padding:"0px" }}
									open={opendbitemoptions} onOpen={() => { InitDBItemOptions (); }} onClose={() => { openDBItemOptions(false); }}
									//Not sure how to avoid passing event, then newvalue... seems to break when removing event.
									//* onChange={(event, newValue) => onChangeOption1(event, newValue)} */}
									onChange={(event, newValue) => handleChangeDBItem (event, newValue)}
									onInputChange={(event) => DBItemSearch(event.target.value)}
									isOptionEqualToValue={(option, value) => option[dbitemkey] === value}
									getOptionLabel={(option) => option[dbitemkey]}
									/* Work-around for when there are no options and you get errors for getOptionLabel:
										getOptionLabel={
											(option) => {
												if (option){
													return option["Name"];
												} else {
													return "";
												}
											}
										}
									*/
									options={dbitemoptions}
									loading={loadingdbitemoptions}
									renderInput={(params) => (
										<TextField variant="standard"
											className={classes.autocompleteinput}
											style={{padding:"0px"}}
											{...params}
											InputProps={{
												disableUnderline: false,
												...params.InputProps,
												autoComplete: "new-password",
												endAdornment: (
													<React.Fragment>
														{loadingdbitemoptions ? <CircularProgress color="inherit" size={20} /> : null}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							</div>
						}

						{/* Date */}
						{(changeisdate) &&
							<FormControl className={classes.formControl} style={{width:"300px"}}>
							<TextField variant="standard" 
								style={{ width: "300px" }} 
								required id="standard-required" 
								placeholder="Format: YYYY-MM-DD" 
								onChange={handleChangeBulkValue} />
							</FormControl>
						}


						{/* Change Is Bool */}
						{(changeisbool) &&
							<div style={{marginTop:"20px"}}>
								<FormControl>
									<FormLabel id="demo-radio-buttons-group-label">Set Boolean Status (customizable)</FormLabel>
									<RadioGroup
										aria-labelledby="demo-radio-buttons-group-label"
										defaultValue="0"
										name="radio-buttons-group"
										onChange={handleChangeBulkValue}
									>
										<FormControlLabel value="0" control={<Radio />} label="Disable" />
										<FormControlLabel value="1" control={<Radio />} label="Enable" />
									</RadioGroup>
								</FormControl>
							</div>
						}

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => ApplyBulkEdit()}
							ref={el => btnApplyBulkEdit.current = el}>
							Apply Change
						</Button>

						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => CloseBulkEditDrawer()}>
							Close
						</Button>
						
					</div>
				</Drawer>




				{/* Standard Page Header with right floated error message space */}
				<div style={{ minHeight: "50px", paddingTop:"5px" }}>
					<Grid container justifyContent="space-between">
						<Grid item xs={12} sm={6} md={3} order={{ xs: 3, sm: 3, md: 1 }} style={{padding:"5px", minWidth:"270px"}}>{(errors.currenterror.errshow) &&
							<ErrorMessage />
						}</Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 1, sm: 1, md: 2 }} style={{textAlign:"center", margin:"auto", padding:"5px"}}><h2>Purchasing Manager</h2></Grid>
						<Grid item xs={12} sm={6} md={3} order={{ xs: 2, sm: 2, md: 3 }} style={{padding:"5px", minWidth:"270px"}}>
							<div style={{float:"right"}}>
								<ProgressBar />
								<NewErrorMessage />
							</div>
						</Grid>
					</Grid>
				</div>


				{/* /* ##########################  Search Inputs  ########################################################################################################################################################## */
				/* ######################################################################################################################################################################################################### */ }
				{/* Inputs can be Grouped inside of new flex containers: */}

				{(!isPrintView) &&
					<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>
						{/* Group 1 */}
						<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>							
							<SearchInput
								searchinput={1}
								key={key1}
								localstate={state}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								onChangeAutoCompleteInput={onChangeAutoCompleteInput}
								selectinputs={defaultselectinputs}
							/>
							<SearchInput
									searchinput={2}
									key={key2}
									localstate={state}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
							/>
							<SearchInput
									searchinput={3}
									key={key3}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
							<SearchInput
								searchinput={4}
								key={key4}
								localstate={localstate}
								inputdefaults={inputdefaults}
								onChangeSearchType={onChangeSearchType}
								onChangeSearchValue={onChangeSearchValue}
								onChangeAutoCompleteInput={onChangeAutoCompleteInput}
								selectinputs={defaultselectinputs}
							/>
						</div>

						{/* Group 2 */}
						{(localstate.expandsearch) &&
							<div style={{ display: "flex", flexGrow: "1", flexDirection: "row", flexWrap: "wrap", justifyContent:"space-evenly" }}>
								<SearchInput
									searchinput={5}
									key={key5}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
							
								<SearchInput
									searchinput={6}
									key={key6}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
								<SearchInput
									searchinput={7}
									key={key7}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
								<SearchInput
									searchinput={8}
									key={key8}
									localstate={localstate}
									inputdefaults={inputdefaults}
									onChangeSearchType={onChangeSearchType}
									onChangeSearchValue={onChangeSearchValue}
									onChangeAutoCompleteInput={onChangeAutoCompleteInput}
									selectinputs={defaultselectinputs}
								/>
							</div>
						}
					</div>
				}
				{/* End of Search Inputs */}


				{/* Top Buttons & Pagination */}
				{(!isPrintView) &&
					<React.Fragment>
						<div style={{ height: "5px" }}>&nbsp;</div>

						<div style={{ display: "flex", flexGrow: 0, flexDirection: "row", flexWrap: "wrap" }}>
							{/* Buttons - Wrap inside container to prevent them from growing and walking away. */}
							<div>
							{(!showdeleteconfirmation && !showexportconfirmation) &&
								<React.Fragment>

									{/* If there is in-line Add-Row in your controller,
										you must have create OR update perms set for Save Button.
										Otherwise, just set it for update alone */}


									{/* <Button
										className={(userPerms.updatePurchase === 1 || userPerms.createPurchase === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => SaveChanges()}
										ref={el => btnSave.current = el}>
										<SaveIcon sx={{color:"lightgray"}}></SaveIcon>&nbsp;Save Changes
									</Button>

								
									<Button
										className={(userPerms.updatePurchase === 1 || userPerms.createPurchase === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										style={{display:"none"}}
										onClick={() => SaveChanges()}
										ref={el => btnPendingSave.current = el}>
										<PendingIcon sx={{color:"orange"}}></PendingIcon>&nbsp;Save Changes
									</Button> */}

<Button
										className={(userPerms.createPurchase === 1) ? classes.bluebtn : classes.hidden}
										color="primary" variant="contained"
										onClick={() => AddItem()}
										ref={el => btnAddItem.current = el}>
										<AddIcon />&nbsp;Add Item
									</Button>


{/* 
									<Button
										className={(userPerms.createPurchase === 1) ? classes.bluebtn : classes.hidden }
										color="primary" variant="contained"
										onClick={() => AddRow()}
										ref={el => btnAddRow.current = el}>
										<AddIcon />&nbsp;Add Row
									</Button> */}


									{/* New View Options Menu */}
									{/* Compact a lot of table view options into this one menu to save space! */}
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowViewOptionsMenu}>
										View Options
									</Button>

									<Menu
										className={classes.bluebtn}
										sx={{ zIndex: 3000 }} // Adjust the z-index here
										color="primary"
										id="view-options-menu"
										anchorEl={showViewOptionsMenu}
										keepMounted
										open={Boolean(showViewOptionsMenu)}
										onClose={CloseViewOptionsMenu}
									>
										<div style={{ display: "inline-block", overflow: "auto", verticalAlign: "top" }}>
											<div style={{ fontWeight: "bold", textAlign: "center" }}>Columns</div>
											<MenuItem disableRipple className={classes.columnmenu}>
												<div style={{verticalAlign:"top"}}> {/* Optional container for 2 column menu! */}
													<div style={{display:"inline-block", maxHeight:"600px", overflow:"auto", verticalAlign:"top"}}>
														<FormGroup>
															{/* ##########################  Column Toggles  ########################## */}
															<FlexColumnOption value="Status" label="Status"/>
															<FlexColumnOption value="LotName" label="Lot Name"/>
															<FlexColumnOption value="Complete" label="Complete"/>
															<FlexColumnOption value="Description" label="Description"/>
															<FlexColumnOption value="TotalSkids" label="Total Skids"/>
															<FlexColumnOption value="Vendor" label="Vendor"/>
															<FlexColumnOption value="created_at" label="Date Created"/>
															<FlexColumnOption value="DateTimeReceived" label="Date Received"/>
															<FlexColumnOption value="InvoiceNumber" label="Invoice #"/>
															<FlexColumnOption value="InvoiceTotal" label="Invoice $"/>
															<FlexColumnOption value="PaymentStatus" label="Payment Status"/>
															<FlexColumnOption value="DatePaymentPosted" label="Date Payment Posted"/>
															<FlexColumnOption value="PaymentMethod" label="Payment Method" />
															<FlexColumnOption value="DamageTotal" label="Damage Total" />
															<FlexColumnOption value="CreditRequestTotal" label="Credit Requested" />
															<FlexColumnOption value="CreditReceivedTotal" label="Credit Received"/>
														</FormGroup>
													</div>
												</div>
											</MenuItem>
										</div>
											<div style={{ display: "inline-block", maxHeight: "600px", overflow: "auto", verticalAlign: "top", maxWidth: "200px", padding: "0px 8px" }}>
												<div style={{ fontWeight: "bold", textAlign: "center" }}>Search Options</div>
												<Button
													className={classes.bluebtn}
													sx={{ width: "100%" }}
													color="primary" variant="contained"
													onClick={() => ToggleExpandSearch()}
												>
													{(!localstate.expandsearch) &&
														<>Expand Search</>
													}
													{(localstate.expandsearch) &&
														<>Collapse Search</>
													}
												</Button>
												<br></br><br></br>
												<div style={{ fontWeight: "bold", textAlign: "center" }}>Table Options</div>
												<Button
													className={classes.bluebtn}
													sx={{ width: "100%" }}
													color="primary" variant="contained"
													onClick={() => ExpandAll()}
													ref={el => btnExpandAll.current = el}
												>
													Expand All
												</Button>
												<Button
													className={(userPerms.updatePurchase === 1) ? classes.bluebtn : classes.hidden}
													sx={{ width: "100%" }}
													color="primary" variant="contained"
													{...(editmode ? {
														style: {
															textDecoration: "underline"
														}
													} :
														{
															style: {
																textDecoration: "none"
															},
															disabled: (localstate.rowsperpage > localstate.maxrowseditmode ? true : false)
														})}
													onClick={() => EditMode()}
													ref={el => btnEditMode.current = el}
												>
													Edit Mode
												</Button>
										</div>
									</Menu>
									{/* END OF VIEW OPTIONS MENU */}




									{/* NEW EDIT MENU */}
									{/* Compact a lot of table view options into this one menu to save space! */}
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowViewEditMenu}>
										Edit Items
									</Button>

									<Menu
										className={classes.bluebtn}
										color="primary"
										id="edit-menu"
										anchorEl={showViewEditMenu}
										keepMounted
										open={Boolean(showViewEditMenu)}
										onClose={CloseViewEditMenu}
									>
										 <MenuItem 
										 	onClick={() => RejectIfInvalidSelected(true, setShowBulkEditDrawer)}
											 ref={el => btnEditSelected.current = el}
											 >
												Bulk Edit Selected Items
										</MenuItem>
										<MenuItem 
										 	onClick={() => RejectIfInvalidSelected("", DeleteSelectedInit)}
											 ref={el => btnDeleteSelected.current = el}
											 sx={{
												'&:hover': {
													backgroundColor: 'lightred', // Background color on hover
												},
												
											}}
											 >
												Delete Selected Items
										</MenuItem>
									</Menu>
									{/* END OF EDIT MENU */}


						
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										aria-haspopup="true"
										onClick={ShowExportMenu}
										ref={el => btnExport.current = el}>
										Export
									</Button>

									<Menu id="export-menu"
										anchorEl={showexportmenu}
										keepMounted
										open={Boolean(showexportmenu)}
										onClose={CloseExportMenu}>
										<MenuItem onClick={() => RejectIfInvalidSelected("selected", InitExport)}>Export Selected</MenuItem>
										<MenuItem onClick={() => InitExport("searchresults")}>Export All (limit 2000)</MenuItem>
									</Menu>

									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => setShowUtilityDrawer(true)}
										ref={el => btnUtilityDrawer.current = el}>
										Utility Drawer
									</Button>

									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => setShowItemList(true)}
										{...(localstate.searchoptions.itemlist!=='' ? {
											style: {
												textDecoration: "underline"
											}
										} :
											{
												style: {
													textDecoration: "none"
												}
											})}
										ref={el => btnItemList.current = el}>
										Item List
									</Button>

									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => PrintLabels()}
										ref={el => btnPrintLabels.current = el}>
										Print Labels
									</Button>	
									<Button
										className={classes.bluebtn}
										color="primary" variant="contained"
										onClick={() => ResetSearches()}
										ref={el => btnResetSearches.current = el}>
										<RestartAltIcon />&nbsp;Reset
									</Button>		
								</React.Fragment>
							}

							{/* Delete Items Confirmation */}
							{(showdeleteconfirmation) &&
								<div>
									<b>Are you sure you want to delete these items?</b>
									<div style={{ padding: "10px 0px" }}>
										{deleteitems.map((row, index) => {
											if (deleteitems.length === index + 1) {
												return (<span key={index}>{row.Name}</span>)
											} else {
												return (<span key={index}>{row.Name}, </span>)
											}
										})}
									</div>
									<Button className={classes.bluebtn}
										color="primary" variant="contained" onClick={() => DeleteSelected()}>Yes, Delete Items</Button>&nbsp;&nbsp;
									<Button className={classes.bluebtn}
										color="primary" variant="contained" onClick={() => CancelDelete()}>Cancel</Button>
								</div>
							}

							{/* Export Mode Confirmation */}
							{(showexportconfirmation) &&
								<div>
									<b>Export Mode</b><br></br>
									Boilerplate message that you might want to state to the user. {exportmessage}
									<div style={{ paddingTop: "10px" }}>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("simple")}>
											Simple
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("expanded")}>
											Expanded
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => PrepareExport("exhaustive")}>
											Exhaustive
										</Button>
										<Button
											className={classes.bluebtn}
											color="primary" variant="contained"
											onClick={() => setShowExportConfirmation(false)}>
											Cancel
										</Button>
									</div>
								</div>
							}
							</div>
							<div style={{flexGrow: 1}}>
								{/* Spacer that grows between buttons and pagination! */}
							</div>
							

							<div style={{flexGrow: 1, textAlign:"right"}}>
								{(localstate.totalitems > 0) &&
									<FlexTablePagination 
										localstate={localstate}
										classes={classes} 
										editmode={editmode}
										handleChangePage={handleChangePage}
										handleChangeRowsPerPage={handleChangeRowsPerPage}
									/>
								}
							</div>
						</div>
					</React.Fragment>
				}
				{/* End of Top Buttons & Pagination */}


				{/* Add container for overflow scroll bars - Allows us to set a min width for the page (better looking in many cases), and freeze controls at the top and bottom for ease of use. */}
				<div className={classes.flexgridcontainerA}>
					{/* ##########################  Start of Table  ########################## */}
					<table id="resizeMe" aria-label="caption table" size="small" className={classes.flexgrid} style={{minWidth:"100%", borderCollapse:"collapse", borderColor:"grey"}}>
						<EnhancedTableHead
							numSelected={localstate.selectedcount} 
							classes={classes}
							order={localstate.order}
							orderBy={localstate.orderby}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={state.griditems.length}
						/>
						{/* /* ##########################  Row Design  ########################## */	 }
						{/* If DB reload, don't allow view of table. */}
						{(!localstate.dbreload) &&
							<tbody style={{ display: "table-row-group" }}>
								{(localstate.griditems.length > 0) &&
									localstate.griditems.map((row, index) => {
										//Create all-new refs on each render. Helps avoid issues with grid states.
										rowRefs.current[index + "Checkbox"] = React.createRef();
										rowRefs.current[index + "SaveStatus"] = React.createRef();
										// rowRefs.current[index + "Name"] = React.createRef();
										// rowRefs.current[index + "Cost"] = React.createRef();
										// rowRefs.current[index + "Price"] = React.createRef();
										// rowRefs.current[index + "Margin"] = React.createRef();
										// rowRefs.current[index + "SomePositiveInteger"] = React.createRef();
										// rowRefs.current[index + "SomeBoolean"] = React.createRef();
										return (
											<React.Fragment key={row.LotID}>
												<tr	className={classes.flexgridrow}>
													{/* Checkbox - Requires inner div to change background color with SaveStatus */}
													<td style={{ verticalAlign: "top" }} ref={el => rowRefs.current[index + "SaveStatus"] = el}>
														<div style={{ padding: "3px 4px 1px 4px" }}>
															{/*	MaterialUI Checkbox will pass a shallow comparison between UpdateState(s). Be sure GridKey changes if it needs to be rerendered such as selectall.	*/}
															{/* Don't show checkbox if this is a PendingItem */}
															{(!row.PendingItem) &&
																<Checkbox
																	key={row.GridKey}
																	inputRef={el=>rowRefs.current[index+"Checkbox"]=el} 
																	className={classes.gridcheckbox}
																	color="default"
																	defaultChecked={localstate.griditems[index].isSelected ? true : false}
																	checkedIcon={<span className={classes.icon+" "+classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onKeyDown={(event) => HandleKeyDown(event, index, "Checkbox")}
																	onChange={() => SelectRow(index)}
																/>
															}
														</div>
													</td>


													{/* Status */}
													{(colstate.Status) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Status}
														</td>
													}

													{/* UserPerms Note: If the user can't use the EditMode button nor API calls to edit items, we can simply count on editmode not being accessible while ignoring the given updateX userPerm */}

													{/* LotName - Allow users with createPurchase perm to edit this row if it's pending! */}
													{((editmode && colstate.LotName) || (colstate.LotName && row.PendingItem===1 && userPerms.createPurchase)) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																ref={el => rowRefs.current[index + 'Name'] = el}
																className={classes.flexgridinput30}
																style={{ minWidth: '50px', textAlign: "left" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Name')}
																onKeyUp={(event) => onChangeValue(event, index, 'Name')}
																defaultValue={localstate.defaultvalues[index].LotName} />
														</td>
													}

													{(!editmode && colstate.LotName && row.PendingItem===0) &&
														<td className={classes.flexgridstaticcontainer}>
																<a href={hostbase + "/purchasing/" + row.LotID} target="_blank">{row.LotName}</a> 
														</td>
													}

													{/* Complete */}
													{(colstate.Complete) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"center"}}>
															{(row.Complete===1 ? "Yes" : "No")}
														</td>
													}

													{/* Description */}
													{(colstate.Description) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Description}
														</td>
													}

													{/* Vendor */}
													{(colstate.Vendor) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.vendor.VendorName}
														</td>
													}



													{/* created_at */}
													{(colstate.created_at) &&
														<td className={classes.flexgridstaticcontainer}>
															{dayjs(row.created_at).format("MMMM D, YYYY [at] h:mma")}
														</td>
													}

													{/* DateTimeReceived */}
													{(colstate.DateTimeReceived) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.DateTimeReceived) &&
																<>
																{dayjs(row.DateTimeReceived).format("MMMM D, YYYY [at] h:mma")}
																</>
															}
														</td>
													}

													{/* Invoice # */}
													{(colstate.InvoiceNumber) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.InvoiceNumber}
														</td>
													}

													{/* Invoice $ */}
													{(colstate.InvoiceTotal) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.InvoiceTotal}
														</td>
													}

													{/* Payment Status */}
													{(colstate.PaymentStatus) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"center"}}>
															{(row.PaymentStatus === 1 ? "Yes":"No")}
														</td>
													}

													{/* DatePaymentPosted */}
													{(colstate.DatePaymentPosted) &&
														<td className={classes.flexgridstaticcontainer}>
															{(row.DatePaymentPosted) &&
																<>
																	{dayjs(row.DatePaymentPosted).format("MMMM D, YYYY [at] h:mma")}
																</>
															}
														</td>
													}

													{/* Payment Method */}
													{(colstate.PaymentMethod) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.PaymentMethod}
														</td>
													}

													{/* Damage Total */}
													{(colstate.DamageTotal) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.DamageTotal}
														</td>
													}

													{/* Credit Request Total */}
													{(colstate.CreditRequestTotal) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.CreditRequestTotal}
														</td>
													}

													{/* Credit Received Total */}
													{(colstate.CreditReceivedTotal) &&
														<td className={classes.flexgridstaticcontainer} style={{textAlign:"right"}}>
															{row.CreditReceivedTotal}
														</td>
													}



													


													{/* Alernate EXPAND ROW version: 
													{(colstate.Name) &&
														<React.Fragment>
															{(editmode && colstate.Name) &&
																<td className={classes.flexgridinputcontainer}>
																	{(!row.ExpandRow) &&
																		<div className={classes.flexgridexpand}>
																			<ExpandMoreIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => ExpandRowToggle(index)}></ExpandMoreIcon>
																		</div>
																	}
																	{(row.ExpandRow) &&
																		<div className={classes.flexgridexpand}>
																			<ExpandLessIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => ExpandRowToggle(index)}></ExpandLessIcon>
																		</div>
																	}
																	<input
																		ref={el => rowRefs.current[index + 'Name'] = el}
																		className={classes.flexgridinput30}
																		style={{ minWidth: '50px', textAlign: "left" }}
																		onKeyDown={(event) => HandleKeyDown(event, index, 'Name')}
																		onKeyUp={(event) => onChangeValue(event, index, 'Name')}
																		defaultValue={localstate.defaultvalues[index].Name} />
																</td>
															}

															{(!editmode && colstate.Name) &&
																<td className={classes.flexgridstaticcontainer}>
																	{(!row.ExpandRow) &&
																		<div className={classes.flexgridexpand}>
																			<ExpandMoreIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => ExpandRowToggle(index)}></ExpandMoreIcon>
																		</div>
																	}
																	{(row.ExpandRow) &&
																		<div className={classes.flexgridexpand}>
																			<ExpandLessIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={() => ExpandRowToggle(index)}></ExpandLessIcon>
																		</div>
																	}
																	<div className={classes.flexgridexpand}>
																		<PreviewIcon className={classes.transparenticon} color="primary" fontSize="inherit" onClick={()=>{GoToItem(row.ID)}}></PreviewIcon>
																	</div>
																	{row.Name}
																</td>
															}
														</React.Fragment>
													}          /*}


													{/* Cost - Can also be used for Integers! Change onChangeValue method to use RestrictNumberInteger */}
													{((editmode && colstate.Cost) || (colstate.Cost && row.PendingItem && userPerms.createPurchase)) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'Cost'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Cost')}
																onKeyUp={(event) => onChangeValue(event, index, 'Cost')}
																onBlur={(event) => DetectBlankNumber(event, index, "Cost")}
																defaultValue={localstate.defaultvalues[index].Cost} />
														</td>
													}

													{(!editmode && colstate.Cost && !row.PendingItem) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Cost}
														</td>
													}


													{/* Price */}
													{((editmode && colstate.Price) || (colstate.Price && row.PendingItem && userPerms.createPurchase)) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="0.01"
																ref={el => rowRefs.current[index + 'Price'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'Price')}
																onKeyUp={(event) => onChangeValue(event, index, 'Price')}
																onBlur={(event) => DetectBlankNumber(event, index, "Price")}
																defaultValue={localstate.defaultvalues[index].Price} />
														</td>
													}

													{(!editmode && colstate.Price && !row.PendingItem) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.Price}
														</td>
													}

													{/* Margin */}
													{(colstate.Margin) &&
														<td className={classes.flexgridstaticcontainer}>
															<span ref={el => rowRefs.current[index + "Margin"] = el}>
																{row.Margin}
															</span>
														</td>
													}

													{/* SomePositiveInteger */}
													{((editmode && colstate.SomePositiveInteger) || (colstate.SomePositiveInteger && row.PendingItem && userPerms.createPurchase)) &&
														<td className={classes.flexgridinputcontainer}>
															<input
																type="number" step="1"
																ref={el => rowRefs.current[index + 'SomePositiveInteger'] = el}
																className={classes.flexgridinput}
																style={{ minWidth: '50px', textAlign: "right" }}
																onKeyDown={(event) => HandleKeyDown(event, index, 'SomePositiveInteger')}
																onKeyUp={(event) => onChangeValue(event, index, 'SomePositiveInteger')}
																onBlur={(event) => DetectBlankNumber(event, index, "SomePositiveInteger")}
																defaultValue={localstate.defaultvalues[index].SomePositiveInteger} />
														</td>
													}
													{(!editmode && colstate.SomePositiveInteger && !row.PendingItem) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.SomePositiveInteger}
														</td>
													}

													{/* SomeBoolean */}
													{(colstate.SomeBoolean) &&
														<td>
															{/* Try not to use this version of a boolean checkbox. It incurs expensive rerenders.*/}
															<div style={{ padding: "3px 4px 1px 4px" }}>
																	<input type="checkbox"
																		ref={el => rowRefs.current[index + "SomeBoolean"] = el}
																		checked={row.SomeBoolean === 1}
																		onChange={(event) => onChangeValue(event, index, "SomeBoolean")}
																	/>
																	{row.SomeBoolean}
																</div>
														</td>
													}

													{/* SomeSelectable */}
													{((editmode && colstate.SomeSelectable) || (colstate.SomeSelectable && row.PendingItem && userPerms.createPurchase)) &&
														<td>
															<FormControl className={classes.formControl} variant="standard">
																{/* <InputLabel id="demo-controlled-open-select-label">Optional Label</InputLabel> */}
																{/* Need: Set default value if row.SomeSelectable is null. */}
																{/* Why: Some of the values may be momentarily null for some reason - DB fetch, rerender? */}

																{/* Override icon prop class to our custom 'nodisplay' to remove arrowdown icon for this select */}
																{/* Override select prop class with new padding specs */}

																<Select
																	defaultValue={row.SomeSelectableDefault ? row.SomeSelectableDefault : "--"} disableUnderline
																	onChange={(event) => onChangeValue(event, index, "SomeSelectable")}
																	classes={{
																		icon: classes.nodisplay,
																		select: classes.selectpadding
																	}}
																>
																	<MenuItem value="--">
																		<em>None</em>
																	</MenuItem>
																	<MenuItem value={10}>Ten</MenuItem>
																	<MenuItem value={"A"}>
																		<Chip size="small" label="A" clickable className={classes.gradea} />
																	</MenuItem>
																	<MenuItem value={"B"}>
																		<Chip size="small" label="B" clickable className={classes.gradeb} />
																	</MenuItem>
																	<MenuItem value={"C"}>
																		<Chip size="small" label="C" clickable className={classes.gradec} />
																	</MenuItem>
																	<MenuItem value={"Bad"}>
																		<Chip size="small" label="Bad" clickable className={classes.gradebad} />
																	</MenuItem>
																	<MenuItem value={"Repair"}>
																		<Chip size="small" label="Repair" clickable className={classes.graderepair} />
																	</MenuItem>
																	<MenuItem value={"Scrap"}>
																		<Chip size="small" label="Scrap" clickable className={classes.gradescrap} />
																	</MenuItem>
																</Select>
															</FormControl>
														</td>
													}
													{(!editmode && colstate.SomeSelectable && !row.PendingItem) &&
														<td className={classes.flexgridstaticcontainer}>
															{row.SomeSelectable}
														</td>
													}

													


													{/* NewCheckbox */}
													{((editmode && colstate.NewCheckbox) || (colstate.NewCheckbox && row.PendingItem && userPerms.createPurchase)) &&
														<td>
															<div style={{ padding: "4px 4px 1px 4px", textAlign: "center" }}>
																<Checkbox
																	className={classes.gridcheckbox}
																	disableRipple
																	color="default"
																	defaultChecked={row.NewCheckbox === 1 ? true : false}
																	checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																	icon={<span className={classes.icon} />}
																	onChange={(event) => onChangeValue(event, index, "NewCheckbox")}
																/>
															</div>
														</td>
													}
													{(!editmode && colstate.NewCheckbox && !row.PendingItem) &&
														<td className={classes.flexgridstaticcontainer}>
															<Checkbox
																className={classes.gridcheckbox}
																disabled //!!!!!
																disableRipple
																color="default"
																defaultChecked={row.NewCheckbox === 1 ? true : false}
																checkedIcon={<span className={classes.icon + " " + classes.checkedIcon} />}
																icon={<span className={classes.icon} />}
																onChange={(event) => onChangeValue(event, index, "NewCheckbox")}
															/>
														</td>
													}



													{/* Date */}
													{(colstate.Date) &&
														<td className={classes.flexgridstaticcontainer}>
															UTC: {row.Date}<br></br>
															No tz given: <Moment element='span' format="MMMM D, YYYY [at] h:mma">{row.Date}</Moment><br></br>
															Localized: <Moment tz="America/New_York" element='span' format="MMMM D, YYYY [at] h:mma">{row.Date}</Moment><br></br>
															Bermuda: <Moment tz="Atlantic/Bermuda" element='span' format="MMMM D, YYYY [at] h:mma">{row.Date}</Moment><br></br>
															Los Angeles: <Moment tz="America/Los_Angeles" element='span' format="MMMM D, YYYY [at] h:mma">{row.Date}</Moment><br></br>
														</td>
													}

												</tr>
												{/* Try: conditional for any render whatsoever! */}
												{(row.ExpandRow === true) &&
													<tr>
														<td colSpan="100%">
															<div style={{ margin: "25px" }}>
																Expanded column container!
														</div>
														</td>
													</tr>
												}
											</React.Fragment>

										)
									}
									)
								}
								{(localstate.griditems.length === 0) &&
									<tr className="flexgridrow"><td colSpan="100%"
										style={{ padding: "12px", fontSize: "18px" }}>No Results</td></tr>
								}
							</tbody>
						}
						{(localstate.dbreload) &&
							<tbody>
								<tr>
									<td colSpan="100%">
										<div style={{padding:"20px", textAlign:"center", margin:"auto"}}>
											<CircularProgress />
										</div>
									</td>
								</tr>
							</tbody>
						}
					</table> 
				</div>
				
				
				{(localstate.totalitems>localstate.rowsperpage) &&
						<FlexTablePagination 
							localstate={localstate}
							classes={classes} 
							editmode={editmode}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
						/>
				}
				
				
			</div>
		</LocalizationProvider>
    );
}

export default PurchasingTable;