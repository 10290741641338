//LabelMaker

//New Concept:
//When React goes to rerender an input, it must have a key. Keys need to all be the same as the previous render, container and child container!
//Otherwise inputs WILL lose focus!

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";
import cloneDeep from 'lodash/cloneDeep';

//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext";


import { useDispatch } from 'react-redux';
import {
	setCurrentMenuSection,
	setCurrentMenuItem
} from '../../features/mainmenu/mainmenuSlice';

//Datetime formatting
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; //Possibly our new adapter for mui x 7 datetime
import dayjs from 'dayjs'; //Used with new datetimepickers
//Restrict Numbers both float and integer types
import RestrictInputNumber from "../common/RestrictInputNumber";

import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

//Icons
import SaveIcon from '@mui/icons-material/Save';
import PendingIcon from '@mui/icons-material/Pending';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatSizeIcon from '@mui/icons-material/FormatSize';
import AbcIcon from '@mui/icons-material/Abc';


//Buttons
import Button from '@mui/material/Button';

//Datetime Pickers
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

//DBAutoComplete
import DBAutoComplete from '../common/DBAutoComplete';

//Printing
import { useReactToPrint } from 'react-to-print';

//Record History
import RecordHistory from '../common/RecordHistory';


/* ##########################  Configuration  ########################## */

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Axios Long Post
const longpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 20000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}



const LabelMaker = (props) => {
	document.title="Label Maker";
	const dispatch = useDispatch(); 
	dispatch(setCurrentMenuSection("Label Maker"));
	dispatch(setCurrentMenuItem("/labelmaker"));
	//URL Param for ID
	let { id } = useParams();
	console.log(id);

	let history = useHistory();

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();
	const btnPendingSave = useRef();

	

	//Ref used for printing
	const printRef = useRef();

	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	


	// useEffect(() => {
	// 	//To Do: On load of item, set title to the serial number.
	// 	document.title = "Label Maker";
	// 	if (state.dbreload) {
	// 		//Avoid duplicate loads.
	// 		localstate.dbreload = false;
	// 		//Diagnostic:
	// 		console.log("Localstate ID: " + localstate.itemdata.ID);
	// 		LoadItem();
	// 	}
	// }, [state]);




	// //Try new method to target what is sent from a datetime picker:
	// const onChangeDatetimeValue = (newvalue, itemkey) => {
	// 	localstate.itemdata[itemkey] = newvalue;
	// 	localstate.pendingsaves = true;
	// 	//Old
	// 	// btnSave.current.style.color = "white";
	// 	// btnSave.current.style.backgroundColor = "#01579B";
	// 	btnSave.current.style.display = "none";
	// 	btnPendingSave.current.style.display = "";
	// 	UpdateState(localstate);
	// }

	const [label, setLabel] = useState({
		width:"270px",
		height:"54px"		
	});

	const [labelalignment, setLabelAlignment] = useState("center");
	
	const [fontsize, setNewFontSize] = useState(12);
	const increaseFontSize = () =>{
		console.log(fontsize);
		//Move on up if not already max
		if (fontsize===40){
			setNewFontSize(12);
		} else {
			setNewFontSize(fontsize+1);
		}
	}

	const decreaseFontSize = () =>{
		console.log(fontsize);
		//Move on up if not already max
		if (fontsize===12){
			setNewFontSize(40);
		} else {
			setNewFontSize(fontsize-1);
		}
	}

	const [bold, setBold] = useState(false);


	/* 
	                                                                                         
         _/_/_/        _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/_/_/    
        _/    _/      _/             _/_/    _/       _/    _/      _/             _/    _/   
       _/_/_/        _/_/_/         _/  _/  _/       _/    _/      _/_/_/         _/_/_/      
      _/    _/      _/             _/    _/_/       _/    _/      _/             _/    _/     
     _/    _/      _/_/_/_/       _/      _/       _/_/_/        _/_/_/_/       _/    _/      
                                                                                         
                                                                                         
 	*/
	/* ##########################  Render Function  ########################## */
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
	
			<div style={{ padding: "8px", textAlign: "center", margin: "auto", marginBottom: "100px", overflow:"auto", maxWidth:"1200px" }}>

				{/* Standard Page Header with right floated error message space */}
				<div style={{ position: "relative", paddingTop: "5px", minWidth: "750px" }}> 
					<div style={{ textAlign: "center" }}>
						<h2>Label Maker</h2>
					</div>

					{/* Top Menu */}
					<Box sx={{ '& button': { m: 1 }, displayPrint: 'none' }}>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => handlePrint()}>
							Print 
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => setLabel({
								width: "375px",
								height: "75px",
							})}>
								{/* Assume 72 pixels per inch.
								Assume 1/8" margins on each side.
								4 - 1/4 = 3.75.   3.75x72=270
								1 - 1/4 = 0.75.   0.75x72=54 */}
							4 x 1 Label
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => setLabel({
								width: "375px",
								height: "275px",
							})}>
								{/* Assume 72 pixels per inch.
								Assume 1/8" margins on each side.
								4 - 1/4 = 3.75.   3.75x72=270      //365 is used in inventory... 365/3.75=97.333. We might be able to use 100ppi
								3.75*100 = 375
								3 - 1/4 = 2.75.   2.75x72=198
								2.75*100 = 275
								*/}
							4 x 3 Label
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => setLabelAlignment("left")}>
							<FormatAlignLeftIcon></FormatAlignLeftIcon>
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => setLabelAlignment("center")}>
							<FormatAlignCenterIcon></FormatAlignCenterIcon>
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => setLabelAlignment("right")}>
							<FormatAlignRightIcon></FormatAlignRightIcon>
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => decreaseFontSize()}>
							<FormatSizeIcon sx={{width:"15px"}}></FormatSizeIcon>
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => increaseFontSize()}>
							<FormatSizeIcon></FormatSizeIcon>
						</Button>
						<Button
							className={classes.bluebtn}
							color="primary" variant="contained"
							onClick={() => setBold(!bold)}> 
							<FormatBoldIcon></FormatBoldIcon>
						</Button>


					</Box>

				</div>
				<div style={{ textAlign: "center", margin: "auto",border: "1px solid #BBB", display:"inline-block"   }}>

					
						<TextField
							ref={printRef}
							sx={{
								width: label.width,
								height: label.height,
							
							}}
							margin="none"
							InputProps={{
								disableUnderline: true, // Optional: Hides the underline in 'standard' variant
								style: {
									fontWeight: bold ? "bold" : "normal",  // Bold text
									fontSize: `${fontsize || 16}px`,       // Adjust font size
									padding: "0px",
									fontFamily:"Verdana, Geneva, sans-serif",
									"-webkit-print-color-adjust": "exact"
								},
							
							}}

							inputProps={{
								style: {
								  textAlign: labelalignment || "left",  // Align text
								},
							  }}

							placeholder="New Label"
						multiline
						variant="standard"
						/>
				</div>
				
			</div>
		</LocalizationProvider>
	)
}

export default LabelMaker;
