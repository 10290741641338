//Product View


//CSS Styles
import flexstyles from '../../css/FlexCss';
import useClasses from '../../ui/useClasses';

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";

//Contexts
import { AppContext, AppProvider } from "../Auth/contexts/AppContext"
//Error Context
//*Can be used for success as well!
//Types: ok, warning, danger, neutral
import ErrorMessage from "../common/ErrorMessage";
import { ErrorContext } from '../common/ErrorContext';


//RECORD HISTORY DROP IN
import RecordHistory from '../common/RecordHistory';

//Charts
import FlexChart from '../Charts/FlexChart';


/* ##########################  Configuration  ########################## */

//DB
var dbendpoint = process.env.REACT_APP_DB_API4;

//Default Axios Post Options
const defaultpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 11800,
};

//Axios Long Post
const longpostoptions = {
	withCredentials: true,
	withXSRFToken: true,
	crossDomain: true,
	mode: "no-cors",
	timeout: 20000,
};

//Helper Functions
//Have not used sleep just yet - is currently on auto-complete sample
function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}

//Remove - Useful for completely removing object properties by key. May be used for exports.
function removeProp(obj, key) {
	for (var k in obj) {
		if (k === key) {
			delete obj[key];
			return true;
		} else if (typeof obj[k] === "object") {
			if (removeProp(obj[k], key)) return true;
		}
	}
	return false;
}

//Find Duplicate Example:
//This will short-circuit once some() finds a truthy value.
var values = [
	{ name: 'someName1' },
	{ name: 'someName2' },
	{ name: 'someName4' },
	{ name: 'someName1' }
];

var valueArr = values.map(function (item) { return item.name });
var isDuplicate = valueArr.some(function (item, idx) {
	return valueArr.indexOf(item) !== idx
});
//console.log(isDuplicate);

//Simple Find Duplicates (simple array of values).
const input = [1, 1, 2, 3, 3];
const GetDupeArray = (inputarray) => {
	var results = inputarray.reduce(function (acc, el, i, arr) {
		if (arr.indexOf(el) !== i && acc.indexOf(el) < 0) acc.push(el); return acc;
	}, []);
	return results;
}
const dupearray = GetDupeArray(input);
//console.log("Duplicates2: "+dupearray); // = 1,3 (actual array == [1, 3])

//Find and return all unique values:
const GetUniqueArray = (inputarray) => {
	return inputarray.filter((x, i, a) => a.indexOf(x) === i);
}


//Remove all instances of string from string:
String.prototype.replaceAll = function (find, replace) {
	var str = this;
	return str.replace(new RegExp(find.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'), replace);
};



const ProductHistory = (props) => {
	let history = useHistory();
	//URL Param for ID
	let { id } = useParams();
	console.log(id);

	/* App Context */
	/* Allows userperms to be used */
	const appContext = useContext(AppContext);
	const { userPerms, userRole } = appContext;

	/* CSS and Media Queries */
	const classes = useClasses(flexstyles);

	const btnSave = useRef();
	const btnPendingSave = useRef();

	/* ##########################  UseState Variables  ########################## */
	const [state, setState] = useState({
		//Need to initialize a blank item
		dbreload: true, 		//Use in useEffect to check if we should reload the griditems data. Set to false when we're just updating current view items.
		itemloaded: false, //POSSIBLY MOST IMPORTANT! Using defaultValue on an input causes issues if you try to render before item is loaded.
		pendingupdate: false,
		itemdata: {
			ProductID: id
		},
		SomeBooleanDefault: true,
		RunDailyStatsDefault:false
	});
	
	//Clone State! We'll get the view from localstate!
	let localstate = Object.assign({}, state);

	function UpdateState(stateobject) {
		setState(stateobject);
	}

	//Error Context
	const errors = useContext(ErrorContext);

	const itemRefs = useRef({
		CostEl: "",
		ShippingCostEl: ""
	})

	//Load Item
	function LoadItem() {
		const postdata = {
			ProductID: localstate.itemdata.ProductID
		};
		axios.post(dbendpoint + "/products/getproduct", postdata, defaultpostoptions).then(res => {
			//Rule #1: API should be setup to send 200 response with status. Merge paginated requests.
			if (res.status === 200) {
				//If ValidateUser() fails to verify user, it sends back 'login' error. 
				if (res.data.Status === "login") {
					//Not logged in. Reload page causes redirect to /login
					window.location.reload(false);
				}
				//All new API calls should return a status.
				if (res.data.Status === "Success") {
					localstate.itemdata = res.data.item;

					//RECORD HISTORY DROP IN
					localstate.itemdata.RecordHistory = JSON.parse(localstate.itemdata.RecordHistory);

					//PieChat Helper
					//Create data for piechart!
					localstate.itemdata.piechartdata = [];
					if (localstate.itemdata.GradeACount>0){
						localstate.itemdata.piechartdata.push({
							id:"A",
							label: "Grade A",
							value:localstate.itemdata.GradeACount
						})
					}

					if (localstate.itemdata.GradeBCount>0){
						localstate.itemdata.piechartdata.push({
							id:"B",
							label: "Grade B",
							value:localstate.itemdata.GradeBCount
						})
					}

					if (localstate.itemdata.GradeCCount>0){
						localstate.itemdata.piechartdata.push({
							id:"C",
							label: "Grade C",
							value:localstate.itemdata.GradeCCount
						})
					}

					if (localstate.itemdata.GradeRepairCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Repair",
							label: "Grade Repair",
							value:localstate.itemdata.GradeRepairCount
						})
					}

					if (localstate.itemdata.GradeScrapCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Scrap",
							label: "Grade Scrap",
							value:localstate.itemdata.GradeScrapCount
						})
					}

					if (localstate.itemdata.GradeUnknownCount>0){
						localstate.itemdata.piechartdata.push({
							id:"Unknown",
							label: "Grade Unknown",
							value:localstate.itemdata.GradeUnknownCount
						})
					}

					//Cost is always nice to have pre-formatted
					localstate.itemdata.Cost = parseFloat(res.data.item.Cost).toFixed(2);
					localstate.dbreload = false;
					//Set Defaults on first load:
					if (!localstate.itemloaded){
						localstate.itemdatadefaults = localstate.itemdata;
						localstate.itemloaded = true; //Now allow render
					}
					UpdateState(localstate);
				}
				if (res.data.Status === "Failure") {
					//Failure error
					localstate.dbreload = false;
					UpdateState(localstate);
					errors.NewError({ errmsg: res.data.message, errshow: true, errtimeout: 5, errtype: "neutral" })
				}
			} else {
				//Non-200 message from server.
				errors.NewError({ errmsg: "Bad response from server.", errshow: true, errtimeout: 5, errtype: "warning" })
			}
		});
	}



	useEffect(() => {
		//To Do: On load of item, set title to the serial number.
		document.title = "Product";
		if (state.dbreload) {
			//Avoid duplicate loads.
			localstate.dbreload = false;
			//Diagnostic:
			console.log("Localstate ID: " + localstate.itemdata.ID);
			LoadItem();
		}
	}, [state]);




	/* ##########################  Render Function  ########################## */
	return (
		<RecordHistory 
			recordhistory={localstate.itemdata.RecordHistory}
			columns={[
				{columnname:"Date", dbkey:"updated_at", type:"friendlydate", style:{ paddingLeft: "16px" }},
				{columnname:"Name", dbkey:"Name", type:"text", },
				{columnname:"Cost", dbkey:"Cost", type:"text", style:{ textAlign: "center" }},
				{columnname:"Shipping", dbkey:"ShippingCost", type:"text", style:{ textAlign: "center" }},
				{columnname:"User", dbkey:"updated_by", type:"text", },
			]}
			historytitle="Product History"
		/>
	)
}

export default ProductHistory;
